/*
 * 업무구분: 사고보험금 신청
 * 화 면 명: MSPBU002D
 * 화면설명: 보험금 신청정보 입력
 * 접근권한: 모든사용자
 * 작 성 일: 2023.02.17
 * 작 성 자: 김진원
 */
 <template>
    <!-- 컨텐츠 영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-pm-wrap">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="con-area pt30"><!-- v-if="currentStep === 1" -->
        <span class="contain-title must">보험금 신청대상 선택</span>
        <!-- 탭메뉴 --> 
          <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="ns-segment">
          <mo-segment-wrapper solid primary v-model="custType">
            <mo-segment-button value='SELF'>본인보험금</mo-segment-button>
            <mo-segment-button value='OTHER'>수익자상이</mo-segment-button>
            <mo-segment-button value='CHILD'>자녀보험금</mo-segment-button>
          </mo-segment-wrapper>
        </ur-box-container>
        <!-- 탭메뉴 -->
      </ur-box-container>
      <!-- 보험금신청 정보 from -->
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column"  class="con-area pt45 pb200 mb100"><!--키패드 높이값 조정 mb100 ex : 85-->
        <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column"  class="ns-add-wrap">

          <!-- type1 S === ☞ 본인보험금 | 수익자 상이 | 자녀보험금 탭에서 공통으로 사용 ☜ === -->
          <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column"  class="ns-add-area pal0">
            <span class="contain-title must mb15">{{custType === 'CHILD' ? '계약자(부모)' : '피보험자(본인)'}}</span>

            <mo-validate-watcher ref="type1Watcher">
              <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="add-box pb20">
                <mo-text-field ref="contractor" class="form-input-name" v-model="custInfo.type1.custNm" :rules="validateRuleName" underline placeholder="고객명을 입력해주세요" maxlength="30"/>
                <div class="verify-birth mt20">
                  <mo-text-field type="number" underline class="birth-input" mask="######" v-model="custInfo.type1.knbFrno" :rules="validateRuleKnbFrno" placeholder="주민등록번호" @keyup="fn_KnbFrnoFocusType1($event)"/>
                  <span class="hyphen ml10 mr10"></span>
                  <m-trans-key-input v-if="$commonUtil.isMobile()" v-model="custInfo.type1.knbBknoCipher" ref="backField1" :rules="validateRuleKnbBkno" :type="'numberMax7'" :start="'1'" :end="'-1'" @mask-type="fn_getMaskType1" class="birth-input3"/>
                  <mo-text-field v-else type="number" password underline mask="#######" v-model="custInfo.type1.knbBkno" :rules="validateRuleKnbBkno" placeholder="" class="birth-input no-ic"/>
                </div>

                <div v-if="custType === 'CHILD'">
                  <span class="contain-title must mt30 mb15">미성년자와의 관계</span>
                  <mo-text-field class="form-input-name mt10" v-model="custInfo.type3.relationType" :rules="validateRuleName" underline placeholder="미성년자와의 관계를 입력하세요" maxlength="30"/>
                </div>
              </ur-box-container>
            </mo-validate-watcher>

            <!-- type1 조회 버튼 S -->
            <div class="ns-certify-sed" :class="custInfo.type1.message === 'success' ? 'success' : 'error'">
              <ur-box-container alignV="start" componentid="ur_box_container_005" direction="column" class="ns-round-btn ns-style2 mb15">
                <mo-button class="ns-btn-round" @click="fn_SelCustInfo('type1')" :disabled="custInfo.type1.isButtonDisabled">조회</mo-button>
              </ur-box-container>
              <div class="ns-certify-sed-txt">
                <div class="txt-success"><span class="ico"></span>확인되었습니다.</div>
                <div class="txt-error crTy-orange2">{{custInfo.type1.message}}</div>
              </div>
            </div>
            <!-- type1 조회 버튼 E -->
          </ur-box-container>  
          <!-- type1 정보 영역 E -->



          <!-- type2 정보 영역 S === ☞ 수익자 상이 | 자녀보험금 탭에서 만 사용 ☜ === -->
          <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column"  class="ns-add-area pal0" v-if="custType != 'SELF'">
            <span class="contain-title must mt30 mb15">{{custType === 'CHILD' ? '피보험자(자녀)' : '수익자'}}</span>

            <mo-validate-watcher ref="type2Watcher">
              <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="add-box pb20">
                <mo-text-field class="form-input-name" v-model="custInfo.type2.custNm" :rules="validateRuleName" underline :placeholder="(custType === 'CHILD' ? '피보험자(자녀)' : '수익자').concat('명을 입력해주세요')" maxlength="30"/>
                <div class="verify-birth mt20">
                  <mo-text-field  type="number" underline class="birth-input" v-model="custInfo.type2.knbFrno" mask="######" :rules="validateRuleKnbFrno" placeholder="주민등록번호" @keyup="fn_KnbFrnoFocusType2($event)"/>
                  <span class="hyphen ml10 mr10"></span>
                  <m-trans-key-input v-if="$commonUtil.isMobile()" v-model="custInfo.type2.knbBknoCipher" ref="backField2" :rules="validateRuleKnbBkno" :type="'numberMax7'" :start="'1'" :end="'-1'" @mask-type="fn_getMaskType2" class="birth-input3"/>
                  <mo-text-field v-else type="number" password underline mask="#######" v-model="custInfo.type2.knbBkno" :rules="validateRuleKnbBkno" placeholder="" class="birth-input no-ic"/>
                </div>
              </ur-box-container>
            </mo-validate-watcher>

            <!-- type2 조회 버튼 S -->
            <div class="ns-certify-sed" :class="custInfo.type2.message === 'success' ? 'success' : 'error'">
              <ur-box-container alignV="start" componentid="ur_box_container_005" direction="column" class="ns-round-btn ns-style2 mb15">
                <mo-button class="ns-btn-round" @click="fn_SelCustInfo('type2')" ref="type2SearchButton" :disabled="custInfo.type2.isButtonDisabled">조회</mo-button>
              </ur-box-container>
              <div class="ns-certify-sed-txt">
                <div class="txt-success"><span class="ico"></span>확인되었습니다.</div>
                <div class="txt-error crTy-orange2">{{custInfo.type2.message}}</div>
              </div>
            </div>
            <!-- type2 조회 버튼 E -->
          </ur-box-container>
          <!-- type2 정보 영역 E -->


          <!-- 자녀보험금 탭 > 친권확인서 S === ☞ 자녀보험금 탭에서 만 사용 ☜ === -->
          <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column"  class="ns-add-area pal0" v-if="custType == 'CHILD'">
            <span class="contain-title must mt30 mb15">친권확인서</span>
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-radio-list">
              <!-- 2023.02.20 추가 : 안내문구표출 -->
              <mo-radio value="1" v-model="custInfo.type3.selectedRadioValue" :class="{mb22: custInfo.type3.selectedRadioValue !== '1'}">다른친권자의 위임/동의획득</mo-radio>
              <template v-if="custInfo.type3.selectedRadioValue == '1'">
                <!-- 다른친권자의 위임/동의획득 체크시 => 안내문구 표출 -->
                <div class="ns-certify-sed crTy-blue3 mt10 mb10" :class="{error: custInfo.type3.message !== ''}">
                  계약자의 정보가 {{step1Data.beneficiary.sex==='male'?'부(父)':'모(母)'}}로 입력되있습니다.<br>
                  계약자가 아닌 {{step1Data.beneficiary.sex==='male'?'모(母)':'부(父)'}}의 정보를 선택해 주세요.
                  <div class="ns-certify-sed-txt">
                    <div class="txt-error crTy-orange2">{{custInfo.type3.message}}</div>
                  </div>
                </div>
                <!-- 다른친권자의 위임/동의획득 체크시 => 안내문구 표출 //-->
            
                <!-- 2023.02.20 추가 : 안내문구표출 //-->
                <mo-validate-watcher ref="type3Watcher">
                  <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="add-box text-gray-box ty2 mt20 mb20">
                    <span class="label-title must">다른친권자</span>
                    <mo-text-field class="form-input-name mt10" v-model="custInfo.type3.custNm" :rules="validateRuleName" underline placeholder="수익자명을 입력해주세요" maxlength="30"/>
                    <div class="verify-birth mt20">
                      <mo-text-field  type="number" underline class="birth-input" v-model="custInfo.type3.knbFrno" mask="######" :rules="validateRuleKnbFrno" placeholder="주민등록번호" @keyup="fn_KnbFrnoFocusType3($event)"/>
                      <span class="hyphen ml10 mr10"></span>
                      <m-trans-key-input v-if="$commonUtil.isMobile()" v-model="custInfo.type3.knbBknoCipher" ref="backField3" :rules="validateRuleKnbBkno" :type="'numberMax7'" :start="'1'" :end="'-1'" @mask-type="fn_getMaskType3" class="birth-input3"/>
                      <mo-text-field v-else type="number" password underline v-model="custInfo.type3.knbBkno" placeholder="" class="birth-input no-ic" maxlength="7"/>
                    </div>
                    <div class="verify-phone msp-verify-phone mt20">
                      <msp-bottom-select ref="bottomSelect" :items="phoneItems" underline v-model="custInfo.type3.celnoFrno" :itemValue="'key'" :itemText="'label'" class="w140px mr10" placeholder="선택" bottom-title="연락처" closeBtn scrolling/>
                      <!--msp-bottom-select ref="bottomSelect1" :items="items1" underline class="w140px mr10" placeholder="010" bottom-title="휴대폰 번호" closeBtn scrolling/ -->
                      <mo-decimal-field numeric underline placeholder="1234 - 1234" :rules="validateRuleCelnoBNo" :mask="celnoMasking" v-model="custInfo.type3.celnoBkno" class="mb0" />
                    </div>
                  </ur-box-container>
                </mo-validate-watcher>
              </template>
              <mo-radio value="2" v-model="custInfo.type3.selectedRadioValue" class="mb20">친권자1인 지정(이혼)</mo-radio>
              <mo-radio value="3" v-model="custInfo.type3.selectedRadioValue" class="mb20">다른친권자 사망</mo-radio>
            </ur-box-container>
          </ur-box-container>
          <!-- 자녀보험금 탭 > 친권확인서 E -->

        </ur-box-container>
      </ur-box-container>
    </ur-box-container>
</template>
<script>
/***********************************************************************************
* 공통 라이브러리 INCLUDE 영역	                                                   *
***********************************************************************************/

export default {
  /***********************************************************************************
  * Vue 파일 속성 정의 영역	                                                       *
  ***********************************************************************************/
  name: "MSPBU002D",
  screenId: "MSPBU002D",
  components: {},
  props: {
    rpc: {type: String}, //CustInfoBottomSheet.vue 고객정보화면에서 사고보험금신청을 호출 한 경우에 고객 이름이 route param 으로 넘어 온다.
    customerType: {type: String},
  },
  /***********************************************************************************
    * Life Cycle 함수 정의 영역	                                                     *
    ***********************************************************************************/
  created(){    
    let lv_Vm = this

    this.fn_SetDefaultValue() //화면 입력 고객 정보 초기화

    //자녀보험금 탭 > 친권확인서 > 다른친권자의 위임/동의획득 > 휴대폰 앞 번호 공통 코드
    this.$commonUtil.selListSliCommCd('CELPH_SRVC_NO_SC_CD').then(function (response) {
      lv_Vm.phoneItems = lv_Vm.getStore('code').getters.getState.get('CELPH_SRVC_NO_SC_CD')
    })    
  },
  mounted() {    
    this.$bcUtil.getDataBySrnIdInLocalStorage(this)
    //focus
    this.$refs.contractor.focus()
  },
  destroyed() {
    this.$bcUtil.setDataBySrnIdInLocalStorage(this)
  },
  /***********************************************************************************
  * 화면변수 선언 영역	                                                             *
  ***********************************************************************************/
  data() {
    return {      
      custType : this.$props.customerType,

      nodeEnv: process.env.NODE_ENV,

      //이름
      validateRuleName: [
        (v) => !!v || '필수입력항목입니다.'
      ],
      //주민번호 앞
      validateRuleKnbFrno: [
        (v) => !!v || '필수입력항목입니다.',
        (v) => v.length === 6 || ' 앞자리를 정확히 입력해 주세요',
      ],
      //주민번호 뒤
      validateRuleKnbBkno: [
        (v) => !!v || '필수입력항목입니다.',
        (v) => v.length === 7 || ' 뒷자리를 정확히 입력해 주세요'
      ],
      //전화번호
      validateRuleCelnoBNo: [
        (v) => !!v || '필수입력항목입니다.',
        /* 010으로 시작하면 전체 11 자리이고, 010으로 시작 하지 않으면 전체 10~12자 */
        (v) => /^010\d{8}$|^(?!010)\d{10,12}$/.test(this.custInfo.type3.celnoFrno.concat(v)) || this.$t('bu')['EBUC017']
      ],

      phoneItems:[], //자녀보험금 탭 > 친권확인서 > 다른친권자의 위임/동의획득 > 휴대폰 앞 번호 공통 코드
      celnoMasking: '### - #####', //전화번호 마스킹

      custInfo: {
        type1: {
          custId: '', //조회 된 고객의 ID (BLOCKING 조회 시 사용)
          custNm: '',//this.$props.rpc, //고객명
          knbFrno: '', //주민번호 앞
          knbBkno: '', //주민번호 뒤
          knbBknoCipher: '', //휴대폰에서 보안키패드로 입력 한 경우 주민번호 뒷값을 암호화 해서 셋팅 한다.
          osType: '', //app 에서 진행 되는 경우, 주민번호 뒷자리 입력시 보안키패드가 열리는데, android 와 ios 의 암복호화 값이 다르다. 그것에 대한 ios 구분 여부이다.
          message: '', //조회결과 message, 성공인 경우 success, 실패인 경우 실패 메시지
          isButtonDisabled: false, //조회버튼 비활성화여부
        },
        //수익자 정보
        type2: {
          custId: '', //조회 된 고객의 ID (BLOCKING 조회 시 사용)
          custNm: '', //고객명
          knbFrno: '', //주민번호 앞
          knbBkno: '', //주민번호 뒤
          knbBknoCipher: '',
          osType: '',
          message: '', //조회결과 message, 성공인 경우 success, 실패인 경우 실패 메시지
          isButtonDisabled: false, //조회버튼 비활성화여부
        },
        //다른친권자 정보
        type3: {
          custNm: '', //고객명
          knbFrno: '', //주민번호 앞
          knbBkno: '', //주민번호 뒤
          knbBknoCipher: '',
          osType: '',
          celnoFrno: '', //연락처 앞
          celnoBkno: '', //연락처 뒤
          selectedRadioValue: '', //1:다른친권자, 2:친권자 1인지정, 3: 다른 친권자 사망
          message: '',
          sex: '',
          relationType: '', //미성년자와의 관계
        },
      },

      /**
       * 조회된 고객정보 셋팅
       * 
       *              | SELF  | OTHER | CHILD
       * --------------------------------------
       * insured      | type1 | type1 | type2
       * beneficiary  | type1 | type2 | type1
       * other        |   X   |   X   | type3
       */
      step1Data: {
        insured:{}, //피보험자
        beneficiary: {}, //수익자
        other: {}, //다른친권자
      }
    }
  },


  /***********************************************************************************
  * watch 함수 정의 영역                                                         *
  ***********************************************************************************/
  watch: {

    /**
     * @description type1 고객이 변경된 경우 type1 초기화
     * @param {Object} type 변경이 감지된 object 여기에서는 type1
     */
    'custInfo.type1.custNm': function(){
      this.fn_ChangeWatch(this.custInfo.type1)
    },

    /**
     * @description type1 고객이 변경된 경우 type1 초기화
     * @param {Object} type 변경이 감지된 object 여기에서는 type1
     */
    'custInfo.type1.knbFrno': function(){
      this.fn_ChangeWatch(this.custInfo.type1)
    },

    /**
     * @description type1 고객이 변경된 경우 type1 초기화
     * @param {Object} type 변경이 감지된 object 여기에서는 type1
     */
    'custInfo.type1.knbBkno': function(){
      this.fn_ChangeWatch(this.custInfo.type1)
    },

    /**
     * @description type1 고객이 변경된 경우 type1 초기화
     * @param {Object} type 변경이 감지된 object 여기에서는 type1
     */
    'custInfo.type1.knbBknoCipher': function(){
      this.fn_ChangeWatch(this.custInfo.type1)
    },

    /**
     * @description type2 고객이 변경된 경우 type1 초기화
     * @param {Object} type 변경이 감지된 object 여기에서는 type1
     */
    'custInfo.type2.custNm': function(){
      this.fn_ChangeWatch(this.custInfo.type2)
    },

    /**
     * @description type2 고객이 변경된 경우 type1 초기화
     * @param {Object} type 변경이 감지된 object 여기에서는 type1
     */
    'custInfo.type2.knbFrno': function(){
      this.fn_ChangeWatch(this.custInfo.type2)
    },

    /**
     * @description type2 고객이 변경된 경우 type1 초기화
     * @param {Object} type 변경이 감지된 object 여기에서는 type1
     */
    'custInfo.type2.knbBkno': function(){
      this.fn_ChangeWatch(this.custInfo.type2)
    },

    /**
     * @description type2 고객이 변경된 경우 type1 초기화
     * @param {Object} type 변경이 감지된 object 여기에서는 type1
     */
    'custInfo.type2.knbBknoCipher': function(){
      this.fn_ChangeWatch(this.custInfo.type2)
    },

    /**
     * @description 자녀보험금 탭 인경우 친권확인서 선택
     * @param {String} value 1:다른친권자의 위임/동의획득, 2:친권자1인 지정(이혼), 3:다른친권자 사망
     */
    'custInfo.type3.selectedRadioValue': function(value){
      if( this.isNotEmpty(value) ){

        //type1, type2 고객 모두 조회 완료시 true
        let isSearchSuccess = this.custInfo.type1.message === 'success' && this.custInfo.type2.message === 'success'

        if( !isSearchSuccess ){
          this.getStore('confirm').dispatch('ADD', '계약자(부모)와 피보험자(자녀)를 먼저 조회 바랍니다.')
          this.custInfo.type3.selectedRadioValue = ''

        } else if( value !== '1' ){ //친권자1인 지정(이혼) 또는 다른친권자 사망을 선택 하면 초기화 후, 다음 버튼 활성화
          this.custInfo.type3.custNm = ''
          this.custInfo.type3.knbFrno = '' //주민번호 앞
          this.custInfo.type3.knbBkno = '' //주민번호 뒤
          this.custInfo.type3.knbBknoCipher = ''
          this.custInfo.type3.celnoFrno = '' //연락처 앞
          this.custInfo.type3.celnoBkno = '' //연락처 뒤
          this.custInfo.type3.message = ''
          this.$emit('onNextButton', false)

        }
      }
    },

    /**
     * @description 휴대폰 뒷자리 마스킹
     * @param {String} value new value 변경된 값
     */
    'custInfo.type3.celnoBkno': function(value){
      if( value.length === 8 ){
        this.celnoMasking = '#### - ####'
      } else {
        this.celnoMasking = '### - #####'
      }
    },

    custInfo: {
      handler(after, before) {
        console.log('watch - custInfo', after)
        this.$bcUtil.setDataBySrnIdInLocalStorage(this)
      },
      deep: true
    },

    /**
     * @description custType 이 변경되는 경우 기존 입력 정보 초기화
     */
    custType(type){
      this.fn_SetDefaultValue()

      if( this.$refs.type1Watcher ){
        this.$refs.type1Watcher.clearValidation()
      }

      if( this.$refs.type2Watcher ){
        this.$refs.type2Watcher.clearValidation()
      }

      if( this.$refs.type3Watcher ){
        this.$refs.type3Watcher.clearValidation()
      }

      this.celnoMasking = '### - #####'
      this.$emit('setCustomerType', type) //신청자 셋팅.

      this.$bcUtil.setDataBySrnIdInLocalStorage(this)
    }
  },

  
  /***********************************************************************************
  * Computed 함수 정의 영역                                                         *
  ***********************************************************************************/
  computed: {},

  updated(){
    /*
    var inputTagList = document.querySelectorAll('.birth-input3 > input')

    if (inputTagList.length > 0) {
      for (var i = 0; i < inputTagList.length; i++) {
        inputTagList[i].value = ''
      }
    }*/
  },


  /***********************************************************************************
  * 사용자 함수 정의 영역                                                           *
  ***********************************************************************************/
  methods: {
    isNotEmpty(value){
      return !this.$bizUtil.isEmpty(value)
    },

    /**
     * @description 값이 변경된 경우, 조회버튼 및 다음 버튼 상태 변경
     */
    fn_ChangeWatch(type){
      type.isButtonDisabled = false //조회 버튼 활성화
      type.message = ''
      this.$emit('onNextButton', true) // 다음버튼 비활성화
    },

    /**
     * @description OS가 IOS 인 경우에는 value 가 IOS 라는 string 값이 넘어옴. android 에서는 empty 값이 넘어옴.
     *              서비스에서 IOS 인 경우에는 복호화 모듈이 달라짐. 해당 값으로 판단하여 분기 처리함.
     * @param {String} value ios : IOS, android : empty string
     */
    fn_getMaskType1(value) {
      this.custInfo.type1.osType = value
    },
    fn_getMaskType2(value) {
      this.custInfo.type2.osType = value
    },
    fn_getMaskType3(value) {
      this.custInfo.type3.osType = value
    },

    /**
     * @description 화면 상태를 초기화
     * type1 : UI의 첫번째 고객정보 > 본인(피보험자), 수익자상이(피보험자본인), 자녀(계약자=부모)
     * type2 : UI의 두번째 고객정보 > 본인(undefined), 수익자상이(수익자), 자녀(피보험자=자녀)
     * type3 : UI의 자녀보험금 -> 다른친권자 정보
     */
    fn_SetDefaultValue(){
      //현재 화면 정보 초기화
      this.custInfo.type1.custId = '' //조회 된 고객의 ID (BLOCKING 조회 시 사용)
      this.custInfo.type1.custNm = '' //this.$props.rpc, //고객명
      this.custInfo.type1.knbFrno = '' //주민번호 앞
      this.custInfo.type1.knbBkno = '' //주민번호 뒤
      this.custInfo.type1.knbBknoCipher = '' //휴대폰에서 보안키패드로 입력 한 경우 주민번호 뒷값을 암호화 해서 셋팅 한다.
      this.custInfo.type1.osType = '' //app 에서 진행 되는 경우, 주민번호 뒷자리 입력시 보안키패드가 열리는데, android 와 ios 의 암복호화 값이 다르다. 그것에 대한 ios 구분 여부이다.
      this.custInfo.type1.message = '' //조회결과 message, 성공인 경우 success, 실패인 경우 실패 메시지
      this.custInfo.type1.isButtonDisabled = false //조회버튼 비활성화여부

        //수익자 정보
      this.custInfo.type2.custId = '' //조회 된 고객의 ID (BLOCKING 조회 시 사용)
      this.custInfo.type2.custNm = '' //고객명
      this.custInfo.type2.knbFrno = '' //주민번호 앞
      this.custInfo.type2.knbBkno = '' //주민번호 뒤
      this.custInfo.type2.knbBknoCipher = ''
      this.custInfo.type2.osType = ''
      this.custInfo.type2.message = '' //조회결과 message, 성공인 경우 success, 실패인 경우 실패 메시지
      this.custInfo.type2.isButtonDisabled = false //조회버튼 비활성화여부

      //다른친권자 정보
      this.custInfo.type3.custNm = '' //고객명
      this.custInfo.type3.knbFrno = '' //주민번호 앞
      this.custInfo.type3.knbBkno = '' //주민번호 뒤
      this.custInfo.type3.knbBknoCipher = ''
      this.custInfo.type3.osType = ''
      this.custInfo.type3.celnoFrno = '' //연락처 앞
      this.custInfo.type3.celnoBkno = '' //연락처 뒤
      this.custInfo.type3.selectedRadioValue = '' //1:다른친권자, 2:친권자 1인지정, 3: 다른 친권자 사망
      this.custInfo.type3.message = ''
      this.custInfo.type3.sex = ''
      this.custInfo.type3.relationType = '' //미성년자와의 관계

      this.step1Data = {
        insured:{},
        beneficiary: {},
        other: {},
      }

      if( this.$refs.backField1 ){
        this.$refs.backField1.$data.txtField = ''
      }

      if( this.$refs.backField2 ){
        this.$refs.backField2.$data.txtField = ''
      }

      if( this.$refs.backField3 ){
        this.$refs.backField3.$data.txtField = ''
      }

      this.$emit('onNextButton', true) // 다음버튼 비활성화
    },


    /**
     * @description 고객정보조회 (융자 직장정보 포함)
     * @param {String} type type1:UI의 첫번째 고객정보, type2:UI의 두번째 고객정보
     */
    fn_SelCustInfo(type) {
      try {

        if( !this.$refs[type.concat('Watcher')].isValid() ){
          return
        }

        const customer = this.custInfo[type]

        let params = {
                        custNm: customer.custNm, // 고객명 ex) this.custInfo.type1.custNm or this.custInfo.type2.custNm
                        customerType: this.custType, //고객 유형
                        knbFrno: customer.knbFrno, // 주민번호 앞번호
                        knbBkno: customer.knbBkno, // 주민번호 뒷번호 평문 (데스크탑에서 테스트 하는 경우에 평문이 셋팅 됨.)
                        knbBknoCipher: customer.knbBknoCipher, // 휴대폰에서 하는 경우, 여기에 암호화 셋팅 되어 서비스 조회 하게 됨.
                        osType: customer.osType,
                        miniYn: 'Y', // txTSSBU02D1 서비스가 mini 일 때만, 주민번호 뒷자리에 대해 ios 분기 처리 해야 해서 추가 함.(태블릿은 IOS 가 없기 때문.)
                    }
        
        // 수익자상이인 경우에는 신청자와 수익자가 동일 인물인지 확인 하기 위해 추가 셋팅을 해줌.
        if( this.custType === 'OTHER' ){
          let otherType = type==='type1'?'type2':'type1' //신청자인 경우 수익자 정보를, 수익자인 경우 신청자 정보를 셋팅

          params.knbFrno1 = this.custInfo[otherType].knbFrno
          params.knbBknoCipher1 = this.custInfo[otherType].knbBknoCipher
          params.knbBkno1 = this.custInfo[otherType].knbBkno
        }

        let lv_Vm = this

        this.post(this, params, 'txTSSBU02D1', 'S')
          .then(function (response) {

            // 서버 데이터 가져오기
            if( response.body !== null ){
              if( lv_Vm.$bizUtil.isEmpty(response.body.custId) ){
                // 이름과 식별번호가 일치하지 않는 케이스 처리
                lv_Vm.getStore('confirm').dispatch('ADD', lv_Vm.$t('bu')['EBUC012'] )

              } else {
                // 필요없는 고객정보 제거
                if( response.body.ztmdb13140 && response.body.ztmdb13140.length > 0 ){
                  response.body.ztmdb13140 = null // memory clear
                  delete response.body.ztmdb13140 // object key delete
                }

                customer.custId = response.body.custId

                //공통으로 사용될 성별, 나이를 추가로 셋팅.
                if( response.body.rrn ){
                  response.body.sex = lv_Vm.$bizUtil.getGenderFromKnb(response.body.rrn)
                  response.body.age = lv_Vm.$bizUtil.getAgeFromKnb(response.body.rrn)
                }

                response.body.knbBknoCipher = customer.knbBknoCipher //폰에서 하는 경우 보안키패드에서 암호화된 값 셋팅됨.

                if( lv_Vm.custType === 'SELF'){
                  lv_Vm.fn_ResultForSelf(type, customer, response.body)
                } else if( lv_Vm.custType === 'OTHER' ){
                  lv_Vm.fn_ResultForOther(type, customer, response.body)
                } else if( lv_Vm.custType === 'CHILD' ){
                  lv_Vm.fn_ResultForChild(type, customer, response.body)
                }
              }
            } else {
              // 에러 메시지 발생
              lv_Vm.getStore('confirm').dispatch('ADD', '에러가 발생했습니다.')
            }
          })
          .catch(function (error) {
            window.vue.error(error)
          })
      } catch (e) {
        console.log('app_crash :', e.name , e.message)
        let isAppCrashAlert = this.getStore('bsStore').getters.getState.isAppCrashAlert // appCrash alert 여부        
        if (isAppCrashAlert) alert(`앱이 비정상 종료하였습니다. : ${e.name} : ${e.message}`)
      }          
    },



    /**
     * @description 본인 조회결과 처리
     * @param {String} type type1: 피보험자 이면서 계약자
     * @param {Object} customer 조회한 고객 type1 이면 신청자, type2 이면 수익자
     * @param {Object} body response.body 객체
     */
    fn_ResultForSelf(type, customer, body){

      try {

        // 고객 정보 상태 체크
        const custStatCd = body.custStatCd.trim()

        if( custStatCd === '10' && this.$bizUtil.compareCurrentAge(customer.knbFrno) === false ){
          customer.message = this.$t('bu')['EBUC013'] //접수불가 고객입니다(미성년자)

        } else if( custStatCd === '11' || custStatCd === '12' || custStatCd === '13' ){
          customer.message = this.$t('bu')['EBUC014'] + '(' + body.custStatNm + ')' //접수불가 고객입니다

        } else if( custStatCd === '21' ){
          customer.message = this.$t('bu')['EBUC014']

        } else if( custStatCd !== '10' && custStatCd !== '' ){
          customer.message = this.$t('bu')['EBUC014'] + '(고객상태 정보 확인 불가)'

        } else {
          customer.message = 'success' // 확인 메시지 출력
          customer.isButtonDisabled = true // 조회 버튼 비활성화

          //본인인 경우 피보험자&수익자 정보가 같음.
          this.step1Data.insured = this.$bizUtil.cloneDeep(body)
          this.step1Data.insured.custType = '피보험자'

          this.step1Data.beneficiary = body
          this.step1Data.beneficiary.custType = '수익자'

          this.$emit('onNextButton', false)

        }
      } catch (e) {
        console.log('app_crash :', e.name , e.message)
        let isAppCrashAlert = this.getStore('bsStore').getters.getState.isAppCrashAlert // appCrash alert 여부        
        if (isAppCrashAlert) alert(`앱이 비정상 종료하였습니다. : ${e.name} : ${e.message}`)
      }
    },



    /**
     * @description 수익자상이 조회결과 처리
     * @param {String} type fn_SetDefaultValue 함수 desc 참조
     * @param {Object} customer 조회한 고객
     * @param {Object} body 조회된 고객정보
     */
    fn_ResultForOther(type, customer, body){
      try {
        // 고객 정보 상태 체크
        const custStatCd = body.custStatCd.trim()
        const errorCd = body.error

        if( custStatCd === '10' ){
          if( this.$bizUtil.compareCurrentAge(customer.knbFrno) === false ){
            customer.message = this.$t('bu')['EBUC013'] //접수불가 고객입니다(미성년자)
          }
        } else if( custStatCd === '11' || custStatCd === '12' || custStatCd === '13' ){
          customer.message = this.$t('bu')['EBUC014'] + '(' + body.custStatNm + ')' //접수불가 고객입니다

        } else if( custStatCd === '21' ){
          customer.message = this.$t('bu')['EBUC014']

        } else if( custStatCd !== '' ){
          customer.message = this.$t('bu')['EBUC014'] + '(고객상태 정보 확인 불가)'

        }

        //접수불가
        if( this.isNotEmpty(customer.message) ){
          return
        }

        if( errorCd === '10' && type === 'type1' ){
          customer.message += this.$t('bu')['EBUC020'] // 입력한 정보가 피보험자 정보와 같습니다

        } else if( errorCd === '10' && type === 'type2' ) {
          customer.message += this.$t('bu')['EBUC021'] // 입력한 정보가 수익자 정보와 같습니다

        } else {
          customer.message = 'success' // 확인 되었습니다. 메시지 출력
          customer.isButtonDisabled = true // 버튼 비활성화

          if( type === 'type1' ){
            this.step1Data.insured = body
            this.step1Data.insured.custType = '피보험자'

          } else {
            this.step1Data.beneficiary = body
            this.step1Data.beneficiary.custType = '수익자'

          }

          // 신청자 & 수익자 정보 조회 완료 시 
          if( this.custInfo.type1.message === 'success' && this.custInfo.type2.message === 'success' ){
            this.$emit('onNextButton', false)
          }
        }
      } catch (e) {
        console.log('app_crash :', e.name , e.message)
        let isAppCrashAlert = this.getStore('bsStore').getters.getState.isAppCrashAlert // appCrash alert 여부        
        if (isAppCrashAlert) alert(`앱이 비정상 종료하였습니다. : ${e.name} : ${e.message}`)
      }
    },


    /**
     * @description 자녀 조회결과 처리
     * @param {String} type type1: 계약자(부모), type2: 피보험자(자녀)
     * @param {Object} customer 조회한 고객
     * @param {Object} body response.body 객체
     */
    fn_ResultForChild(type, customer, body){
      try {
        // 고객 정보 상태 체크
        const custStatCd = body.custStatCd.trim()
        if( custStatCd === '10' ){
          const checkAge = this.$bizUtil.compareCurrentAge(customer.knbFrno)
          if( type === 'type1' ){
            if( checkAge === false  ){
              customer.message = this.$t('bu')['EBUC013'] //접수불가 고객입니다(미성년자)
            }
          } else if( type === 'type2' ){
            if( checkAge === true ){
              customer.message = this.$t('bu')['EBUC022'] //자녀 피보험자는 미성년자만 조회 가능 합니다
            }
          }
        } else if( custStatCd === '11' || custStatCd === '12' || custStatCd === '13' ){
          customer.message = this.$t('bu')['EBUC014'] + '(' + body.custStatNm + ')' //접수불가 고객입니다

        } else if( custStatCd === '21' ){
          customer.message = this.$t('bu')['EBUC014']

        } else if( custStatCd !== '' ){
          customer.message = this.$t('bu')['EBUC014'] + '(고객상태 정보 확인 불가)'

        }
        
        if( this.isNotEmpty(customer.message) ){
          return //진행불가
          
        } else {
          customer.message = 'success' // 확인 되었습니다.
          customer.isButtonDisabled = true // 버튼 비활성화

          if( type === 'type2' ){
            this.step1Data.insured = body
            this.step1Data.insured.custType = '피보험자'

          } else {
            this.step1Data.beneficiary = body
            this.step1Data.beneficiary.custType = '계약자'
            
          }

          // 둘다 조회 완료 시
          if( this.custInfo.type1.message === 'success' && this.custInfo.type2.message === 'success' ){
            this.$emit('onNextButton', false)
          }
        }
      } catch (e) {
        console.log('app_crash :', e.name , e.message)
        let isAppCrashAlert = this.getStore('bsStore').getters.getState.isAppCrashAlert // appCrash alert 여부        
        if (isAppCrashAlert) alert(`앱이 비정상 종료하였습니다. : ${e.name} : ${e.message}`)
      }
    },


    /**
     * @description 자녀탭 인 경우 필드 체크 및 다른 친권자 정보 셋팅, MSPBU001M 에서 다음 버튼 클릭 시 호출됨.
     * @return {Boolean} true : 다음 진행 불가, false 다음 진행 가능
     */
    fn_afterProcessForOther(){
      try {
        //reference object type
        const otherCustInfo = this.custInfo.type3

        //친권확인서 선택 안된 경우
        if( this.$bizUtil.isEmpty(otherCustInfo.selectedRadioValue) ){
          this.getStore('confirm').dispatch('ADD', '친권확인서를 선택해주세요.')
          return true
        }
        
        //다른친권자의 위임/동의획득 선택 한 경우.
        if( otherCustInfo.selectedRadioValue === '1' ){

          const isValid = this.$refs.type3Watcher.isValid() //true 이면 입력값 정상, false 이면 입력값 오류있음.

          //입력 값 오류 인 경우 진행불가
          if( !isValid ){
            return true
          }

          const isCheckAge = this.$bizUtil.compareCurrentAge(otherCustInfo.knbFrno)

          //다른 친권자가 미성년자인 경우 진행불가
          if( !isCheckAge ){
            otherCustInfo.message = this.$t('bu')['EBUC016'] //다른 친권자가 미성년자입니다
            return true
          }

          console.log('>>> otherCustInfo rrn : ', otherCustInfo.rrn)

          //남성:"male", 여성:"female"
          const otherSex = this.$bizUtil.getGenderFromKnb(otherCustInfo.rrn)

          //신청자와 다른친권자의 성별이 같으면 진행불가
          if( this.step1Data.beneficiary.sex === otherSex ){
            otherCustInfo.message = '계약자 부모 성별과 다른 친권자의 성별이 같습니다'
            return true
          }

          otherCustInfo.sex = otherSex

        }

        //const isRelationOfChildValid = this.$refs.type3RelationOfChildWatcher.isValid() //true 이면 입력값 정상, false 이면 입력값 오류있음.

        //입력 값 오류 인 경우 진행불가
        //if( !isRelationOfChildValid ){
        //  return true
        //}

        //입력 값에 오류가 없으면... 다른친권자 셋팅
        this.step1Data.other = otherCustInfo

        return false
      } catch (e) {
        console.log('app_crash :', e.name , e.message)
        let isAppCrashAlert = this.getStore('bsStore').getters.getState.isAppCrashAlert // appCrash alert 여부        
        if (isAppCrashAlert) alert(`앱이 비정상 종료하였습니다. : ${e.name} : ${e.message}`)
      }
    },
    /**
     * @description 본인보험금 주민번호 오토포커스
     * @return 
     */
    fn_KnbFrnoFocusType1(event, opt) {
        console.log('fn_KnbFrnoFocusType1.....')                        
        if(this.$commonUtil.isMobile()) {
          if( this.custInfo.type1.knbFrno.length == 6 ) {
            if( event.key == 'v' || this.$commonUtil.isMobile() ) {
              this.$refs.backField1.onClick()
            }
          } 
        }
    },
    /**
     * @description 수익자상이 주민번호 오토포커스
     * @return 
     */
    fn_KnbFrnoFocusType2(event, opt) {
        console.log('fn_KnbFrnoFocusType2.....')                        
        if(this.$commonUtil.isMobile()) {
          if( this.custInfo.type2.knbFrno.length == 6 ) {
            if( event.key == 'v' || this.$commonUtil.isMobile() ) {
              this.$refs.backField2.onClick()
            }
          }
        } 
    },  
    /**
     * @description 다른친권자 주민번호 오토포커스
     * @return 
     */
    fn_KnbFrnoFocusType3(event, opt) {
        console.log('fn_KnbFrnoFocusType3.....')                        
        if(this.$commonUtil.isMobile()) {
          if( this.custInfo.type3.knbFrno.length == 6 ) {
            if( event.key == 'v' || this.$commonUtil.isMobile() ) {
              this.$refs.backField3.onClick()
            }
          }
        } 
    },
  }
};
</script>
<style scoped>
</style>