/*
 * 업무구분: 사고보험금 신청
 * 화 면 명: MSPBU005D
 * 화면설명: 사고보험금 신청 본인인증
 * 접근권한: 모든사용자
 * 작 성 일: 2023.02.17
 * 작 성 자: 김진원
 */
<template>
  <!-- <mo-validate-watcher ref="vWatcher"> -->
    <!-- 컨텐츠 영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-pm-wrap">
      <!-- 피보험자, 수익자정보 리스트 S -->
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area msp-list-area msp-list-bottom pb100">
        <span ref="topContainer"></span>
        <mo-list :list-data="custList">
          <template #list-item="{item, index}">
            <!-- 리스트 아이템: 피보험자 or 수익자 -->
            <mo-list-item class="agree-list accordion-type2 accordion-type3">
              <msp-expand btn-area-first expanded title-first expand-only-btn class="mo-list-expand" btn-area-class="fexTy3-1 mb20" :ref="'custExpand'+index">
                <template #title class="w100">
                  <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-contract-list">
                    <div class="list-item__contents pt5 w100">
                      <div class="list-item__contents__title"> 
                        <span class="name txtSkip maxW100 fexInt pr10">{{item.custNm}}</span>
                        <mo-badge class="badge-sample-badge sm"
                                  :class="item.custType==='피보험자'?'lightyellow2':'lightgreen'"
                                  :text="item.custType" shape="status">{{item.custType}}</mo-badge>
                        <mo-badge v-if="$props.customerType === 'SELF'" class="badge-sample-badge sm lightgreen" text="수익자" shape="status">수익자</mo-badge>
                      </div>
                      <div class="list-item__contents__info">
                        <div class="txt_box fex-rw">
                          <div class="dsInline">
                            <span class="crTy-bk1">{{item.age}}세</span><em class="ml10 mr10">|</em><span class="crTy-bk1">{{item.rrn.replace(/(\d{6})(.*)/, '$1-$2')}}</span>
                          </div>
                          <div class="ns-check certify-next fex-clumn fs17rem txt-right wauto ml15 dsInFlax" :class="'check-later'+index" v-if="$props.customerType === 'OTHER'">
                            <mo-checkbox class="fexJsCtTy3" v-model="step2Data[index].isToLater" @input="fn_ToLater(index)" :disabled="isPreInfo || step2Data[index].authSuccessTime !== ''">다음에하기</mo-checkbox>  
                          </div>
                        </div>
                      </div>
                      <!-- 다음에하기 누른 경우에도 다음 버튼이 활성화 되야 하기 때문에, isToLater 도 확인 해야 함. -->
                      <div class="ns-certify-sed certify-complete mt15 pal0" :class="step2Data[index].isFinish?'success':'error'">
                        <div class="ns-certify-sed-txt">
                          <div class="txt-success"><span class="ico"></span>{{step2Data[index].isToLater?'다음에하기':'동의완료'}}</div>
                          <div class="txt-error">인증 또는 약관동의가 필요합니다.</div>
                        </div>
                      </div>


                      <!-- 다음에 하기 클릭시 고객의 인증 추후 진행 가능 -->
                      <!--div class="ns-check certify-next fs17rem txt-right mt5" :class="'check-later'+index" v-if="$props.customerType === 'OTHER'">
                        <mo-checkbox v-model="step2Data[index].isToLater" @input="fn_ToLater(index)" :disabled="step2Data[index].authSuccessTime !== ''">다음에하기</mo-checkbox>
                      </div-->
                      <!-- 다음에 하기 클릭시 고객의 인증 추후 진행 가능 //-->
                    </div> 
                  </ur-box-container>
                </template>
                <template #btn>
                  <mo-button class="link-arrow down"></mo-button>
                </template>
                <template #content>
                  <!-- 본인인증방식 S-->
                  <ur-box-container alignV="start" componentid="" direction="column" class="ns-add-area"> 
                    <span class="contain-title crTy-bk1">본인인증</span>
                    <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
                      <mo-segment-wrapper solid primary v-model="step2Data[index].authType" class="chip-type-wrap chip-ty1" :disabled="step2Data[index].authSuccessTime !== '' || step2Data[index].isToLater">
                        <mo-segment-button value="1">휴대폰인증</mo-segment-button>
                        <mo-segment-button value="2">카카오인증</mo-segment-button>
                      </mo-segment-wrapper>
                    </ur-box-container>

                    <!-- 휴대폰 인증 -->
                    <MSPBU006D      v-if="step2Data[index].authType === '1'"
                                    :custInfo="item" :index="index" :data="step2Data[index]" @setAuthSuccessTime="fn_SetAuthSuccessTime"/>
                    <!-- 카카오 인증 -->
                    <MSPBU007D v-else-if="step2Data[index].authType === '2'"
                                    :custInfo="item" :index="index" :data="step2Data[index]" @setAuthSuccessTime="fn_SetAuthSuccessTime"/>
                    
                  </ur-box-container>
                  <!-- 본인인증방식 E //-->

                  <!-- 본인인증동의 S--> <!-- 2023.02.20 본인인증약관동의 내용 수정 -->
                  <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-agreeArea2 bd-T-Ty2" v-if="step2Data[index].isShowAgreeDoc">
                    <div class="info-title fexTy6-1 pa2024">
                      <strong class="con-title1">본인인증 동의</strong>
                      <span class="con-title6 pl5">{{step2Data[index].agreeDocType === '1' ? '[CISO 심의필 2023-050]' : '[CISO 심의필 2023-050]'}}</span>
                    </div>
                    <ur-box-container alignV="start" componentid="" direction="column" class="mt10 mb30">
                      <div class="info-title pl24 pr24">
                        <span class="contain-title must crTy-bk1">동의서 안내 형식</span>
                      </div>
                      <mo-tab-box class="ns-move-tab-box msp-tab-button tab-ty1" :class="{disabled: $props.customerType === 'OTHER' || !$bizUtil.isEmpty(step2Data[index].authSuccessTime)}"
                        :default-idx="step2Data[index].agreeDocType" :ref="'agreeTab'+index" @tab-change="(value) => fn_TabChange(value, index)"> <!-- 동의서 안내 형식 변경 시 MSPBU008D, MSPBU009D 에서 watch 되고 있음. -->

                        <mo-tab-label :disable="$props.customerType === 'OTHER' || step2Data[index].allAgreeDoc === 1 || (!$bizUtil.isEmpty(step2Data[index].authSuccessTime) && step2Data[index].agreeDocType === '2')" idx="1">요약형</mo-tab-label>
                        <mo-tab-label :disable="$props.customerType !== 'OTHER' && !$bizUtil.isEmpty(step2Data[index].authSuccessTime) && step2Data[index].agreeDocType === '1'" idx="2" :ref="'agreeTabDetailTypeBtn'+index">상세형</mo-tab-label>

                        <mo-tab-content idx="1">
                          <MSPBU008D :index="index" :data="step2Data[index]" @setAgreementAll="fn_SetAgreementAll" @setAllAgreeDoc="fn_SetAllAgreeDoc"></MSPBU008D><!--요약형-->
                        </mo-tab-content>
                        <mo-tab-content idx="2">
                          <MSPBU009D :index="index" :data="step2Data[index]" @setAgreementAll="fn_SetAgreementAll"></MSPBU009D><!--상세형-->
                        </mo-tab-content>
                      </mo-tab-box>
                    </ur-box-container>
                  </ur-box-container>
                  <!-- 본인인증동의 E //--> <!-- 2023.02.20 본인인증약관동의 내용 수정 //-->
                </template>
              </msp-expand>
            </mo-list-item> 
            <!-- 리스트 아이템: 피보험자 or 수익자 //-->
          </template>
        
        </mo-list>
        <!-- 접수방법/심사절차 안내장 확인 --> <!-- 2024.03.19 안내장 확인 로직 추가 -->
        <ur-box-container v-if="isShowConfmGudnl" alignV="start" componentid="ur_box_container_001" direction="column" class="ns-round-btn ns-no-line">
          <span class="contain-title must">보험 지급절차 안내 확인</span>
          <mo-button class="ns-btn-round" @click="fn_AgreePopContent">보험금 지급절차 및 손해사정사 선임권</mo-button>
        </ur-box-container>
      </ur-box-container>
      <!-- 피보험자, 수익자정보 리스트 E -->

      <!-- 바텀시트1 : 다음에 하기 안내 팝업 표출 -->
      <mo-bottom-sheet ref="bottomSheet"  class="ns-bottom-sheet closebtn">
        <template v-slot:title>다음에 하기 안내</template>
        <div class="ns-btn-close" @click="close"></div>  
        <div class="content-area txt-center mh50px">
          <p class="ment">
            '다음에 하기'를 클릭 시 해당 고객의 전자서명은 알림톡을 통해 진행됩니다.
          </p>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs noshadow">
            <div class="relative-div">
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" name="확인" @click="close">확인</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>
      <!-- 바텀시트1 : 다음에 하기 안내 팝업 표출 //-->
    </ur-box-container>
  <!-- </mo-validate-watcher> -->
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import MSPBU006D from '@/ui/bu/MSPBU006D' //STEP2 > 본인인증 > 휴대폰인증
import MSPBU007D from '@/ui/bu/MSPBU007D' //STEP2 > 본인인증 > 카카오인증
import MSPBU008D from '@/ui/bu/MSPBU008D' //STEP2 > 본인인증 > 요약 동의서
import MSPBU009D from '@/ui/bu/MSPBU009D' //STEP2 > 본인인증 > 상세 동의서
import MSPBU010P from '@/ui/bu/MSPBU010P' //구비서류 추가 팝업
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPBU005D",
  screenId: "MSPBU005D",
  components: {
    MSPBU006D, //휴대폰인증
    MSPBU007D, //카카오인증
    MSPBU008D, //본인인증 요약 동의서
    MSPBU009D, //본인인증 상세 동의서
  },
  props: {
    customerType: {type: String},
    step1Data: {type: Object}, //조회된 고객정보
    preInfo: {type: Object}, //접수중단건에서 진입한 경우 이전에 신청했던 정보를 가지고 들어온다.
  },
  watch: {
    isAuthFinish(value){      

      //접수중단건에서 진입한 경우, 다음 스텝이 전자서명이기 때문에, 이 경우 버튼이 전자서명으로 보이고, 서류추가여부를 확인한다.
      if( !this.$bizUtil.isEmpty(this.$props.preInfo.insrdYn) ){
        /**
         * 원본 서류가 필요한 경우 (또는 사진촬영이 불가 한 경우)
         * 1. 사망 Y or 장해 Y
         * 2. 청구구분코드가 실손(2)이 아닐 때,청구금액분류코드가 1,000만원 이상(03)
         */
        const preInfo = this.$props.preInfo
        // 24.07.09 청구사유 진단(dignYn) 체크로직 삭제 (SR: ASR240600534)
        if(!(preInfo.dthYn === 'Y' || preInfo.dsabYn === 'Y' || (preInfo.clamTypScCd !== '2' && preInfo.clamAmtClcd === '03'))){
          this.fn_attachDocConfirm() //서류추가 여부
          this.$emit('onPreButton', !value) //서류추가 버튼 활성화
        }
      }

      // 보험 지급절차 안내 확인 버튼 Show
      this.isShowConfmGudnl = true
    },
    custInfo: {
      handler(after, before) {
        console.log('watch - custInfo', after)
        // this.$bcUtil.setDataBySrnIdInLocalStorage(this)
      },
      deep: true
    },
    // 직업구분 선택
    step2Data: {
      handler(after, before) {
        if ( after && after.length > 0 ) {
          after.forEach( item => {
              if (item.authType === '2') {
                this.fn_AlertPopup()
                item.authType = '1'
              }
            }
          )
        }        
      },
      deep: true
    },
    
  },

  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {    
    try {      
      //출력 할 고객 순서 및 본인인증 대상 셋팅
      if( this.$props.customerType == 'SELF' ){
        this.step2Data.push(this.fn_SetStep2Data())
        this.custList.push(this.$props.step1Data.insured) //피보험자(본인)

      } else if( this.$props.customerType == 'OTHER' ){
        this.step2Data.push(this.fn_SetStep2Data())
        this.step2Data.push(this.fn_SetStep2Data())
        this.custList.push(this.$props.step1Data.insured) //피보험자
        this.custList.push(this.$props.step1Data.beneficiary) //수익자 또는 계약자

      } else if( this.$props.customerType == 'CHILD' ){
        //TODO : 피보험자(자녀) 정보를 상단에 고정 시키는 작업을 해 줘야 함.
        this.step2Data.push(this.fn_SetStep2Data())
        this.custList.push(this.$props.step1Data.beneficiary) //계약자(본인)
      }

      //이어하기로 들어온 경우
      if( this.$props.preInfo && this.$props.preInfo.insrdYn ){        
        this.isPreInfo = true
      }
    } catch (e) {
      console.log('app_crash :', e.name , e.message)
      let isAppCrashAlert = this.getStore('bsStore').getters.getState.isAppCrashAlert // appCrash alert 여부        
      if (isAppCrashAlert) alert(`앱이 비정상 종료하였습니다. : ${e.name} : ${e.message}`)
    }
  },
  mounted() {    
    try {
      if( !this.$bizUtil.isEmpty(this.$props.preInfo.insrdYn) ){
        this.$emit('onPreButton', true) //접수중단건에서 진입시 서류추가 버튼 비 활성화
      }
      
      this.$emit('onNextButton', true)

      if( this.$commonUtil.getIsIOS() ){
        this.$refs.topContainer.scrollIntoView()
      }

      // this.$bcUtil.getDataBySrnIdInLocalStorage(this)
    } catch (e) {
      console.log('app_crash :', e.name , e.message)
      let isAppCrashAlert = this.getStore('bsStore').getters.getState.isAppCrashAlert // appCrash alert 여부        
      if (isAppCrashAlert) alert(`앱이 비정상 종료하였습니다. : ${e.name} : ${e.message}`)
    }
  },
  destroyed() {
    // this.$bcUtil.setDataBySrnIdInLocalStorage(this)
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      isPreInfo: false,
      isShowConfmGudnl: false, // 버튼 Show Flag
      custList: [],
      step2Data: [], //본인인증 완료 시간 및 본인인증 동의 여부에 대한 정보 저장
      photoList: [], //접수중단건 > 이어하기 로 진입하는 경우 구비서류 추가
    };
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    /**
     * @description 인증완료 여부
     *              인증완료 여부는 다음에하기를 했거나, 본인인증과 본인인증동의를 했을 때 완료임.
     *              즉, 인증완료는 본인인증 성공을 의미 하는 것이 아닌, 다음 스텝으로의 진행 가능 여부 정도로 이해 하면 됨.
     */
    isAuthFinish(){
      try {
        if( this.step2Data.length > 0 ){

          //OTHER(수익자상이) 인 경우에는 본인인증을 2명 받아야 하니까....
          if( this.$props.customerType === 'OTHER' ){
            //접수중단건의 이어하기로 들어온 경우, 피보험자 다음에하기 셋팅(수익자상이의 경우 step2Data 의 0 번째가 피보험자, 1번째가 수익자. life cycle created 에서 정해짐.)
            if( this.$props.preInfo && this.$props.preInfo.insrdYn === 'Y' ){
              this.step2Data[0].isFinish = this.fn_CheckAuthState(this.step2Data[0]) // 본인인증 해야 됨.
              this.step2Data[0].isShowAgreeDoc = false //본인인증 동의 숨김, 이어하기로 들어온 경우, 본인인증 동의를 모청에서 받게 됨.
              this.step2Data[1].isFinish = true // 본인인증 이미 했음으로, 안해도 됨.
              this.$refs['custExpand1'].isExpand = false // 본인인증 했음으로, 접은 상태
              this.$refs['custExpand1'].$el.querySelector('button').style.display = 'none' // 다시 펼치지 못하도록 버튼 숨김
              this.$refs['custExpand0'].$el.querySelector('.check-later0').style.display = 'none' // 다음에하기 체크박스 숨김

              if( this.step2Data[0].isFinish ){
                this.$refs['custExpand0'].isExpand = false
              }

            } else if( this.$props.preInfo && this.$props.preInfo.bnfrYn === 'Y' ){
              // 수익자 다음에 하기 셋팅
              this.step2Data[0].isFinish = true
              this.step2Data[1].isFinish = this.fn_CheckAuthState(this.step2Data[1])
              this.step2Data[1].isShowAgreeDoc = false //본인인증 동의 숨김
              this.$refs['custExpand0'].isExpand = false
              this.$refs['custExpand0'].$el.querySelector('button').style.display = 'none'
              this.$refs['custExpand1'].$el.querySelector('.check-later1').style.display = 'none'

              if( this.step2Data[1].isFinish ){
                this.$refs['custExpand1'].isExpand = false
              }

            } else {
              this.step2Data[0].isFinish = this.fn_CheckAuthState(this.step2Data[0])
              this.step2Data[1].isFinish = this.fn_CheckAuthState(this.step2Data[1])

              if( this.step2Data[0].isFinish ){
                this.$refs.custExpand0.isExpand = false
                this.$refs.topContainer.scrollIntoView()
              }

              if( this.step2Data[1].isFinish ){
                this.$refs.custExpand1.isExpand = false
                this.$refs.topContainer.scrollIntoView()
              }

            }

            //수익자상이 인 경우 고객0, 고객1 모두 인증완료 여야 다음 버튼 활성화
            return this.step2Data[0].isFinish && this.step2Data[1].isFinish

          } else {

            //SELF(본인), CHILD(자녀)
            this.step2Data[0].isFinish = this.fn_CheckAuthState(this.step2Data[0])
            if( this.step2Data[0].isFinish ){
              this.$refs['custExpand0'].isExpand = false
            }

            //수익자상이가 아닌 경우 고객0 만 완료되면 다음 버튼 활성화
            return this.step2Data[0].isFinish

          }

        }
        return false
      } catch (e) {
        console.log('app_crash :', e.name , e.message)
        let isAppCrashAlert = this.getStore('bsStore').getters.getState.isAppCrashAlert // appCrash alert 여부        
        if (isAppCrashAlert) alert(`앱이 비정상 종료하였습니다. : ${e.name} : ${e.message}`)
      }
    },
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    test(){
      debugger
    },        
    fn_TabChange(value, index){
      this.step2Data[index].isAgreementAll = false //탭변경시 false 를 셋팅 해 줘야 fn_CheckAuthState > isAuthFinish 이벤트 처리시 접히지 않음.
      this.step2Data[index].agreeDocType = value //요약형 : '1', 상세형 셋팅 : '2'

    },
    /**
     * @description 카카오인증 안내문구 팝업
     */
    fn_AlertPopup() {
      let alertPopup = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            single: true,
            title: "서비스일시 중단 안내",
            content: '카카오페이에서 카카오인증으로 변경 되어 서비스 점검 후 재개될 예정입니다. 휴대폰인증으로 접수 진행 부탁드리며, 더 편리하고 안정적인 서비스 제공을 위해 노력하겠습니다.감사합니다.',
            title_pos_btn: "확인"
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(alertPopup)              
            },
            onPopupCancel: () => {
              this.$bottomModal.close(alertPopup)              
            },
            onPopupClose: () => {
              this.$bottomModal.close(alertPopup)              
            }
          }
        })
    },
    /**
     * @description 본인인증 및 본인인증 동의 완료 상태 체크 (완료의 기준 : 다음에하기를 눌렀거나, 인증과동의를 했거나.)
     * @param {Object} data step2Data 배열요소
     * @returns {Boolean} true : 본인인증 및 동의 완료, false : 미완료
     */
    fn_CheckAuthState(data){
      try {
        //다음에하기 인 경우, 다른 상태값 확인 할 필요 없이 그냥 다음 버튼 활성화
        //접수중단건에서 진입하면 isToLater은 항상 false로 체크 하지 않게됨.(다음에하기 버튼이 화면에 표시되지 않음.)
        if( data.isToLater ){
          return data.isToLater
        }

        //인증안된 경우 진행불가
        if( this.$bizUtil.isEmpty(data.authSuccessTime) ){
          return false
        }
        
        //접수중단건에서 진입한 경우 preInfo 가 값이 있는데, 이런 경우 다른 값 체크 할 필요 없이, 인증만 하면 다음으로 진행 가능.
        if( !this.$bizUtil.isEmpty(this.$props.preInfo.insrdYn) ){
          return true
        }

        //요약형 인데, 전체 동의서 안내 요청 여부 선택 안한 경우 진행불가
        if( data.agreeDocType === '1' && data.allAgreeDoc === 3 ){
          return false
        }

        //최종 전체동의 여부
        return data.isAgreementAll
      } catch (e) {
        console.log('app_crash :', e.name , e.message)
        let isAppCrashAlert = this.getStore('bsStore').getters.getState.isAppCrashAlert // appCrash alert 여부        
        if (isAppCrashAlert) alert(`앱이 비정상 종료하였습니다. : ${e.name} : ${e.message}`)
      }

    },

    
    /**
     * @description 인증스텝에서 필요한 필드셋을 리턴한다.
     * @returns {Object} auth step 에서 사용 될 필드셋
     */
    fn_SetStep2Data(){
      try {
        return {
          authType: '1', //인증타입 1:휴대폰, 2:카카오
          agreeDocType: this.$props.customerType === 'OTHER'?'2':'1', //동의서 안내형식 1:요약형, 2:상세형 (수익자상이인 경우 상세형 고정. 변경불가)
          allAgreeDoc: 3, //요약형 전체 동의서 안내 요청 여부 1:예, 2:아니오, 3:선택안함
          authSuccessTime: '', // 인증성공시간
          isFinish: false, //인증완료 여부(인증을 성공했거나, 다음하기를 하면 true가 됨) : 어자피 실패를 하면, 다음으로 진행을 못함. MSPBU055P 에서 인증성공한 고객으로만 새로운계좌추가가 가능 함.
          isToLater: false, //다음하기
          isShowAgreeDoc: true, // 본인인증동의 내용 숨김 여부, 접수중단건 > 이어하기로 진입 하는 경우 본인인증동의는 모청에서 받는 걸로 진행 됨으로, 여기에서는 숨김 처리 함.
          isAgreementAll: false, // 본인인증동의 전체동의여부
        }
      } catch (e) {
        console.log('app_crash :', e.name , e.message)
        let isAppCrashAlert = this.getStore('bsStore').getters.getState.isAppCrashAlert // appCrash alert 여부        
        if (isAppCrashAlert) alert(`앱이 비정상 종료하였습니다. : ${e.name} : ${e.message}`)
      }
    },


    /**
     * @description 인증성공시간을 셋팅 한다.
     * @param {String} index custList 의 index
     * @param {String} authTime 인증성공시간
     */
    fn_SetAuthSuccessTime(index, authSuccessTime){
      this.step2Data[index].authSuccessTime = authSuccessTime
    },

    
    /**
     * @description 요약형 전체 동의서 안내 요청 여부
     * @param {String} index custList 의 index
     * @param {String} allAgreeDoc
     */
    fn_SetAllAgreeDoc(index, allAgreeDoc){
      try {
        this.step2Data[index].allAgreeDoc = allAgreeDoc

        //예 선택 한 경우, 요약형 disabled 하고, 상세형으로 변경
        if( allAgreeDoc === 1 ){
          this.$refs['agreeTab'+index].setIdx("2") //setIdx 가 호출 되면 emit > tab-change 를 통해 step2Data[index].agreeDocType 이 2로 변경 된다.
          this.$refs['agreeTabDetailTypeBtn'+index].$el.className = 'mo-tab__label mo-tab__label--active' //그런데 step2Data[index].agreeDocType 값이 변경 되는 것에 대한 class 가 적용되지 않는다. 그래서 추가 함.
        }
      } catch (e) {
        console.log('app_crash :', e.name , e.message)
        let isAppCrashAlert = this.getStore('bsStore').getters.getState.isAppCrashAlert // appCrash alert 여부        
        if (isAppCrashAlert) alert(`앱이 비정상 종료하였습니다. : ${e.name} : ${e.message}`)
      }
    },


    /**
     * @description 전체동의 여부
     * @param {String} index custList 의 index
     * @param {String} isAgreementAll
     */
    fn_SetAgreementAll(index, isAgreementAll){
      this.step2Data[index].isAgreementAll = isAgreementAll
    },

    /**
     * @description 다음에하기
     * @param {Number} index
     */
    fn_ToLater(index){
      try {
        //다음에하기 버튼은 수익자상이 일 때만 표시되는 기능 이니까, 0번째 1번째 index 를 하드코딩으로 접근 해도 됨.
        if( this.step2Data[0].isToLater && this.step2Data[1].isToLater ){
          this.step2Data[index].isToLater = false
          this.getStore('confirm').dispatch('ADD', '1명만 다음에하기 할 수 있습니다.')
        } else {
          this.$refs['custExpand'+index].isExpand = !this.step2Data[index].isToLater
        }
      } catch (e) {
        console.log('app_crash :', e.name , e.message)
        let isAppCrashAlert = this.getStore('bsStore').getters.getState.isAppCrashAlert // appCrash alert 여부        
        if (isAppCrashAlert) alert(`앱이 비정상 종료하였습니다. : ${e.name} : ${e.message}`)
      }
    },


    /**
     * @description attach doc confirm popup
     */
    fn_attachDocConfirm() {
      try {
        let lv_Vm = this
        let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
            properties: {
              title: '서류추가',
              content: '본인인증이 완료 되었습니다. 구비서류를 추가 하시겠습니까?',
              title_pos_btn: '예',
              title_neg_btn: '아니오'
            },
            listeners: {
              onPopupConfirm: () => {
                lv_Vm.fn_OpenMSPBU010P()
                lv_Vm.$bottomModal.close(lv_AlertPop)
              },
              onPopupClose: () => {
                lv_Vm.$bottomModal.close(lv_AlertPop)
              }
            }
          })
      } catch (e) {
        console.log('app_crash :', e.name , e.message)
        let isAppCrashAlert = this.getStore('bsStore').getters.getState.isAppCrashAlert // appCrash alert 여부        
        if (isAppCrashAlert) alert(`앱이 비정상 종료하였습니다. : ${e.name} : ${e.message}`)
      }
    },


    /**
     * @name fn_OpenMSPPM814P
     * @description 구비서류 진행
     */
    fn_OpenMSPBU010P() {
      try {
        let lv_Vm = this
        this.popupMSPBU010P = this.$bottomModal.open(MSPBU010P, {
          properties: {
            step1Data: lv_Vm.$props.step1Data,
            preInfo: lv_Vm.$props.preInfo,
            parentPhotoList: lv_Vm.photoList,
          },
          listeners: {
            onPopupClose: (photoList) => {
              // 촬영된 사진은 확인버튼을 누른 경우에만 반영 됨.
              if( photoList ){
                lv_Vm.photoList = photoList
              }

              lv_Vm.$bottomModal.close(lv_Vm.popupMSPBU010P)
            }
          }
        })
      } catch (e) {
        console.log('app_crash :', e.name , e.message)
        let isAppCrashAlert = this.getStore('bsStore').getters.getState.isAppCrashAlert // appCrash alert 여부        
        if (isAppCrashAlert) alert(`앱이 비정상 종료하였습니다. : ${e.name} : ${e.message}`)
      }
    },


    /**
    * @name fn_AgreePopContent
    * @description 보험 지급절차 안내 서비스 확인에 따른 링크 호출 함수
    */
    fn_AgreePopContent () {
      const lv_Vm = this

      const url = 'https://www.samsunglife.com/s/DHc'
      if (this.$commonUtil.isMobile()) {
        window.fdpbridge.exec('intentBrowserPlugin', {uri: url}, () => {
          // 다음 버튼 활성화
          this.$emit('onNextButton', false)
        }, () => {})
      } else { // test PC에서 작동
        window.open(url, '_blank')
        // 다음 버튼 활성화
        this.$emit('onNextButton', false) // false 활성화, true 비활성화
      }
    },

    /**
     * @description 다음에하기 안내 (두명다 다음에하기 버튼 클릭 시 불가 안내 바텀시트)
     */
    open() {this.$refs.bottomSheet.open();},
    close() {this.$refs.bottomSheet.close();},
  }
};
</script>
<style scoped>
</style>