import { render, staticRenderFns } from "./MSPBU010P.vue?vue&type=template&id=448e3094&scoped=true&"
import script from "./MSPBU010P.vue?vue&type=script&lang=js&"
export * from "./MSPBU010P.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "448e3094",
  null
  
)

export default component.exports