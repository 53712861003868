/*
 * 업무구분: 사고보험금 신청
 * 화 면 명: MSPBU006D
 * 화면설명: 본인인증 - 휴대폰
 * 접근권한: 모든사용자
 * 작 성 일: 2023.02.17
 * 작 성 자: 김진원
 */
<template>
  <mo-validate-watcher ref="vWatcher">
    <!-- 휴대폰 인증 -->
    <ur-box-container alignV="start" componentid="" direction="column" class="ns-certify-wrap">
      <div class="ns-check arrow fex-clumn fs17rem mt20 mb10">
        <mo-checkbox v-model="isCheckAgree" @input="fn_AgreeCnfrm('Open')" :disabled="isDisabled">본인확인 서비스 이용동의</mo-checkbox>
          <!-- 본인확인서비스 이용  미동의 메시지 표출 -->
          <div class="ns-certify-sed">
            <div class="ns-certify-sed-txt">
              <div class="txt-error crTy-orange2">본인확인서비스 이용 동의 해주세요.</div>
            </div>
          </div>
          <!-- 본인확인서비스 이용  미동의 메시지 표출 //-->
      </div>
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-certify-area">
        <ur-box-container alignV="start" componentid="" direction="column" class="info-box">
          <!-- 2023.02.20 수정: 휴대폰번호 입력필드변경 //-->
          <div class="verify-phone msp-verify-phone fexTy5">
            <msp-bottom-select ref="bottomSelect" :items="telecomTypes" v-model="telecomType" underline class="w100px mr10" placeholder="선택" bottom-title="통신사" :disabled="isDisabled" closeBtn scrolling/>
            <span class="fs17rem pl10">{{$props.custInfo.celno}}</span>
            <!-- <mo-text-field type="number" underline placeholder="010-1234-1234" class="mb0 txt-center" disabled/> -->
          </div>
        </ur-box-container>
        <div class="ns-certify-sed">
          <ur-box-container alignV="start" componentid="ur_box_container_006"  direction="column" class="ns-btn-relative-area mb0 pl0 pr0">
            <div class="ns-btn-relative-s w100 mb0">
              <div class="relative-div mb5">
                <mo-button color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_ReqAuthNumberPhone" :disabled="isDisabled">{{'인증번호 ' + (isNotEmpty(countDown)?'재':'') + '요청'}}</mo-button>
              </div>
            </div>
          </ur-box-container>
        </div>

        <!-- 인증번호 전송 클릭시 표출됨 => 인증확인 완료시 : 클래스값 "success"표시, 인증 오류시: 클래스값 "error"표시 -->
        <!-- <ur-box-container alignV="start" componentid="" direction="column" class="ns-certify-wrap"> -->
        <div class="ns-certify-sed pt40" :class="authResult" ref="type1" style="display: none;">
          <div class="ns-certify-sed-code mb2sam0">
            <div class="left">
              <mo-text-field  type="number" class="form-input-name" ref="error" :rules="validateRuleAuthNumber" v-model="authNumber" mask="######" underline placeholder="인증번호를 입력해주세요." :disabled="isAuthDisabled"/>
              <span class="time pl10">{{countDown}}</span>
            </div>
          </div>
          <ur-box-container alignV="start" componentid="ur_box_container_006"  direction="column" class="ns-btn-relative-area mb0 pl0 pr0">
            <div class="ns-btn-relative-s w100 mb0">
              <div class="relative-div">
                <mo-button color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_PrcsMoblAthntCnf" :disabled="isAuthDisabled">인증확인</mo-button>
              </div>
            </div>
          </ur-box-container>
          <div class="ns-certify-sed-txt">
            <div class="txt-success"><span class="ico"></span>인증 완료되었습니다.</div>
            <div class="txt-error crTy-orange2">인증번호가 다릅니다.</div>
          </div>
        </div>
        <!-- </ur-box-container> -->
      </ur-box-container>

      <!-- 바텀시트2 : 휴대폰 최대 오류 횟수를 초과안내 팝업 표출 -->
      <mo-bottom-sheet ref="bottomSheet1"  class="ns-bottom-sheet closebtn">
        <template v-slot:title>휴대폰 최대 오류 횟수를 초과안내</template>
        <div class="ns-btn-close" @click="close1"></div>  
        <div class="content-area txt-center mh50px">
          <p class="ment">
            휴대폰 최대 오류 횟수를 초과하였습니다.<br>
            카카오 인증을 통해 본인인증을 하시겠습니까?
          </p>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs noshadow">
            <div class="relative-div">
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white">취소</mo-button>
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" name="확인">확인</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>
      <!-- 바텀시트2 : 휴대폰 최대 오류 횟수를 초과안내 팝업 표출 //-->    

      <!-- 이용동의 안내 bottomSheet -->
      <mo-bottom-sheet ref="agreeCnfrmSheet" :close-btn="false" class="ns-bottom-sheet closebtn" @before-close="fn_SheetClose">
        <template v-slot:title>
          본인확인서비스 이용동의
          <div class="ns-btn-close" @click="fn_AgreeCnfrm('CloseBtn')" name="닫기"></div>
        </template>
        <div class="content-area">
          <ul class="terms-list-area pb36">
            <li>개인정보 수집/이용 동의</li>
            <li>고유식별정보 처리 동의</li>
            <li>통신사 이용약관 동의</li>
            <li>서비스 이용약관 동의</li>
            <li>개인정보 제 3자 제공 동의</li>
          </ul>
        </div>

        <template v-slot:action>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
            <div class="relative-div">
              <mo-button componentid="mo_button_005" color="primary" shape="primary" size="border" class="ns-btn-round white" @click="fn_AgreeCnfrm('Confirm')">약관 확인</mo-button>
              <mo-button componentid="mo_button_006" color="primary" shape="primary" size="border" class="ns-btn-round blue" @click="fn_AgreeCnfrm('AllConfirm')">전체 약관 동의</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>
      <!-- 이용동의 안내 bottomSheet -->

    </ur-box-container>  
    <!-- 휴대폰 인증 //-->
  </mo-validate-watcher>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import moment from 'moment'

//const COUNTER = '00:05'
const COUNTER = '07:00'

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPBU006D",
  screenId: "MSPBU006D",
  components: {},
  props: {
    custInfo: {type: Object},
    index: {type: Number},
    data: {type: Object},
  },

  watch: {
    //다음에하기
    'data.isToLater': function(isChecked){
      this.authTime = '' //인증 시간 초기화
      this.isDisabled = isChecked

    },
    authTime(value){
      this.$emit('setAuthSuccessTime', this.$props.index, value)

    }
  },


  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    //console.log('MSPBU006D scrn create : ', this.$props.custInfo)
  },
  mounted() {
    this.$bcUtil.getDataBySrnIdInLocalStorage(this)
  },
  destroyed() {
    this.$bcUtil.setDataBySrnIdInLocalStorage(this)
  },

  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      validateRuleAuthNumber : [
        (v) => !!v || '인증번호를 입력하세요'
      ],
      isCheckAgree: false, // 휴대폰 이용 동의 체크
      authNumber: '', // 인증번호
      authResult: '', // 인증확인 결과 success, error
      authTime:'', //인증시간
      countDown: '', //counter
      isAuthDisabled: false, //인증확인되면 인증확인버튼, 인증번호 입력란을 비활성 한다.
      isDisabled: false, //isAuthDisabled 의 field 이외의 것들을 disabled 함. 인증 완료(authTime) 되거나, MSPBU005D 에서 다음에하기 체크 하거나.
      interval: null, // 인증번호 타이머 인터벌

      sCertVnoUrl: 'https://cert.vno.co.kr/app/agree/agree_main.jsp', // 이통사 본인확인 서비스 이용에 따른 동의 나이스 링크 URL

      //휴대폰 인증 결과값
      moblAthntCd : '', //모바일인증코드
      moblAthntPswd : '', //모바일인증비밀번호
      respUnqNo : '', //응답고유번호
      reqUnqNo : '', //요청고유번호
      aprvNoErrCnt : 0, //인증번호 인증 실패 횟 수

      telecomType: 'SKT', //통신사 선택값
      telecomTypes: [
                      {value: 'SKT',text: 'SKT'},
                      {value: 'KT',text: 'KT'},
                      {value: 'LGT',text: 'LG U+'},
                      {value: 'SKTM',text: 'SKT(알뜰폰)'},
                      {value: 'KTM',text: 'KT(알뜰폰)'},
                      {value: 'LGTM',text: 'LG U+(알뜰폰)'},
                    ], //통신사 select box 목록
    }
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {},


  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {

    /**
     * @description 개발 편의
     * @param {Object|String} value
     */
    isNotEmpty(value){
      return !this.$bizUtil.isEmpty(value)
    },



    /**
     * @description 휴대폰 인증번호 요청 //fn_BtnP1OnClick
     */
    fn_ReqAuthNumberPhone() {
      try {

        //본인확인 서비스 이용동의 체크 확인
        if(!this.isCheckAgree){
          this.$refs.agreeCnfrmSheet.open()
          return
        }

        this.interval && window.clearInterval(this.interval) // 재요청인 경우 기존 타이머 종료
        this.$refs.type1.style = "display : block" // 인증번호, 타이머, 인증확인 버튼 display
        this.isAuthDisabled = false // 인증번호 입력필드, 인증확인 버튼 비활성화
        this.$refs.vWatcher.clearValidation() // 인증번호 확인 오류 메시지 클리어

        if (process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'development') {
          this.getStore('toast').dispatch('ADD', 'local 또는 development 환경은 서버요청 없이 진행 됩니다.')
          this.fn_StartCounter() //카운터 시작
          return
        }

        const custInfo = this.$props.custInfo //고객정보

        let params = {
                        custId: this.$bizUtil.isEmpty(custInfo.custId) ? '' : custInfo.custId, // 고객ID
                        custNm: this.$bizUtil.isEmpty(custInfo.custNm) ? '' : custInfo.custNm, // 고객명
                        knbFrno: this.$bizUtil.isEmpty(custInfo.knbFrno) ? '' : custInfo.knbFrno, // 주민번호 앞자리
                        knbBknoCipher: this.$bizUtil.isEmpty(custInfo.knbBknoCipher) ? '' : custInfo.knbBknoCipher, // 주민번호 뒷자리 암호화
                        selfAthntUsScCd: '01', // 본인인증용 구분코드 01 : 개인정보활용동의, 02:전자서명 본인인증(전화번호 수정불가), 03: 본인인증 AS_IS(1:01, 2:02)
                        cmmuScCd: this.telecomType, // 통신사
                        celno: this.$props.custInfo.celno, // 핸드폰 번호
                        knb: 'BU', // 업무구분코드 (FN: 융자)
                        rrn: this.$bizUtil.isEmpty(custInfo.knbBkno) ? '' : (custInfo.knbFrno + custInfo.knbBkno)
                      }

        let lv_Vm = this

        this.post(lv_Vm, params, 'txTSSBU02S1', 'S')
          .then(function (response) {

            if (response.body !== null && response.body !== '' && response.body.rsltCd === '0000') {
              
              let lv_TmpResult = response.body

              // 정상요청 된 경우
              if (lv_TmpResult.rsltCd === '0000') {
                lv_Vm.moblAthntCd = lv_TmpResult.moblAthntCd // 모바일인증코드
                lv_Vm.moblAthntPswd = lv_TmpResult.moblAthntPswd // 모바일인증비밀번호
                lv_Vm.respUnqNo = lv_TmpResult.respUnqNo // 응답고유번호
                lv_Vm.reqUnqNo = lv_TmpResult.reqUnqNo // 요청고유번호
                lv_Vm.fn_StartCounter() //카운터 시작
              }

            } else { // 정상처리가 아닌경우
              lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            }

          })
          .catch(function (error) {
            window.vue.error(error)
          })
      } catch (e) {
        console.log('app_crash :', e.name , e.message)
        let isAppCrashAlert = this.getStore('bsStore').getters.getState.isAppCrashAlert // appCrash alert 여부        
        if (isAppCrashAlert) alert(`앱이 비정상 종료하였습니다. : ${e.name} : ${e.message}`)
      }
    },
    


    /******************************************************************************
     * Function명 : fn_PrcsMoblAthntCnf
     * 설명       : 휴대폰 인증번호 확인
     ******************************************************************************/
    fn_PrcsMoblAthntCnf() {
      try {
        if(!this.$refs.vWatcher.isValid()){
          return
        } else {
          this.$refs.vWatcher.clearValidation()
        }

        this.aprvNoErrCnt++ // 인증번호 전송 횟수 체크 (3회까지만 허용됨)

        if (process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'development') {
          this.getStore('toast').dispatch('ADD', 'local 또는 development 환경은 서버요청 없이 승인 됩니다.')
          this.fn_AuthCompleted()
          return
        }

        const custInfo = this.$props.custInfo //고객정보

        let pParams = {
                        custId: this.$bizUtil.isEmpty(custInfo.custId) ? '' : custInfo.custId, // 고객ID
                        custNm: this.$bizUtil.isEmpty(custInfo.custNm) ? '' : custInfo.custNm, // 고객명
                        knbFrno: this.$bizUtil.isEmpty(custInfo.knbFrno) ? '' : custInfo.knbFrno, // 주민번호 앞자리
                        knbBknoCipher: this.$bizUtil.isEmpty(custInfo.knbBknoCipher) ? '' : custInfo.knbBknoCipher, // 주민번호 뒷자리 암호화
                        selfAthntUsScCd: '01', // 본인인증용 구분코드 01 : 개인정보활용동의, 02:전자서명 본인인증(전화번호 수정불가), 03: 본인인증 AS_IS(1:01, 2:02)
                        celno: this.$props.custInfo.celno, // 핸드폰 번호
                        knb: 'BU', // 업무구분코드
                        rrn: this.$bizUtil.isEmpty(custInfo.knbBkno) ? '' : (custInfo.knbFrno + custInfo.knbBkno),
                        moblAthntCd: this.moblAthntCd, // 모바일인증코드
                        moblAthntPswd: this.moblAthntPswd, // 모바일인증비밀번호
                        reqUnqNo: this.reqUnqNo, // 요청고유번호
                        respUnqNo: this.respUnqNo, // 응답고유번호
                        athntNo: this.authNumber, // 인증번호
                        aprvNoErrCnt: this.aprvNoErrCnt // 인증번호 오류 횟수
                      }

        let lv_Vm = this

        this.post(lv_Vm, pParams, 'txTSSFN70S2', 'S')
          .then(function (response) {

            if (response.body !== null && response.body !== '' && response.body.rsltCd === '0000') {

              let lv_TmpResult = response.body

              if (lv_TmpResult) {
                lv_Vm.fn_AuthCompleted()
              }
              
            } else { // 정상처리가 아닌경우
              lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)

              if (response.msgComm.msgCd === 'ECMU001') { // 인증번호 3회 오류 시
                lv_Vm.authResult = 'error'

                // init timer
                lv_Vm.countDown = COUNTER
                lv_Vm.$refs.vWatcher.clearValidation()
                lv_Vm.authNumber = '' // 인증번호 값 초기화
                lv_Vm.isAuthDisabled = true // 인증번호 입력필드, 인증확인버튼 비활성화
                window.clearInterval(lv_Vm.interval) // 타이머 종료

              }
            }
          })
          .catch(function (error) {
            window.vue.error(error)
          })
      } catch (e) {
        console.log('app_crash :', e.name , e.message)
        let isAppCrashAlert = this.getStore('bsStore').getters.getState.isAppCrashAlert // appCrash alert 여부        
        if (isAppCrashAlert) alert(`앱이 비정상 종료하였습니다. : ${e.name} : ${e.message}`)
      }
    },


    /**
     * @description 인증성공
     */
    fn_AuthCompleted(){
      try {
        let lv_Vm = this
        // 인증 성공 시간 조회(서버기준)
        this.$commonUtil.getServerTime().then(function (response) {
          // timer init
          window.clearInterval(lv_Vm.interval) // 타이머 종료
          
          lv_Vm.authNumber = '' // 인증번호 초기화
          lv_Vm.authResult = 'success'
          lv_Vm.isAuthDisabled = true // 인증번호 input 비활성화
          lv_Vm.authTime = moment(response.toString()).format('YYYY-MM-DD HH:mm')
          lv_Vm.isDisabled = true
        })
      } catch (e) {
        console.log('app_crash :', e.name , e.message)
        let isAppCrashAlert = this.getStore('bsStore').getters.getState.isAppCrashAlert // appCrash alert 여부        
        if (isAppCrashAlert) alert(`앱이 비정상 종료하였습니다. : ${e.name} : ${e.message}`)
      }
    },


    /**
     * @description 인증 타이머 카운트다운
     */
    fn_StartCounter(){
      this.countDown = COUNTER

      let min = Number(COUNTER.split(':')[0])
      let sec = Number(COUNTER.split(':')[1])

      let lv_Vm = this

      // 인증 count down 시작
      this.interval = window.setInterval(function(count){
        count.setTime(count-1000) //interval clear 시 count 초기화
        
        let m = count.getMinutes() //type int
        let s = count.getSeconds() //type int

        if( m+s > 0  ){
          let min = String(m).padLeft(2, '0') //type string
          let sec = String(s).padLeft(2, '0') //type string

          lv_Vm.countDown =  min.concat(':', sec) // 07:00

        }
        // 시간 초과
        else {

          // init
          lv_Vm.$refs.vWatcher.clearValidation()
          lv_Vm.countDown = COUNTER
          lv_Vm.authNumber = '' // 인증번호 값 초기화
          lv_Vm.isAuthDisabled = true // 인증번호 입력필드, 인증확인버튼 비활성화

          window.clearInterval(lv_Vm.interval) // 타이머 종료

        }
      }, 1000, new Date(0, 0, 0, 0, min, sec))

    },

    /**
     * @description 개인정보 이용 활용동의 confirm창
     */
    fn_AgreeConfirmOpen() {
      const lv_Vm = this

      let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            title: '※ 알림 : 본인확인 서비스 이용 동의',
            content: '본인확인 서비스 이용 동의시 인증번호가 전송됩니다.<br>이용에 동의하시겠습니까?',
            title_pos_btn: '예',
            title_neg_btn: '아니오'
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(lv_AlertPop)
              lv_Vm.isCheckAgree = true
              lv_Vm.fn_AgreeCnfrm('Open')
            },
            onPopupClose: () => {
              this.$bottomModal.close(lv_AlertPop)
            }
          }
        })
    },

    /******************************************************************************
     * Function명 : fn_PreConfirmOpen
     * 설명       : 이전화면 이동 confirm창
     ******************************************************************************/
    fn_PreConfirmOpen() {
      let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            title: '※ 알림 : 본인 인증 초기화',
            content: '이전 화면으로 이동 시, 본인 인증이 초기화 됩니다.<br>이동 하시겠습니까?',
            title_pos_btn: '예',
            title_neg_btn: '아니오'
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(lv_AlertPop)
              this.$emit('pre')
            },
            onPopupClose: () => {
              this.$bottomModal.close(lv_AlertPop)
            }
          }
        })
    },

    /******************************************************************************
    * Function명 : fn_AgreeCnfrm
    * 설명       : 본인확인서비스 이용동의 안내 
    *           - OPEN(컨펌팝업열기), Cancel(창닫기), Confirm(창닫기) 선택에 대한 화면 이동
    ******************************************************************************/
    fn_AgreeCnfrm(param) {
      switch (param) {
        case 'Open':
          if(this.isCheckAgree){
            this.isCheckAgree = false
            this.$refs.agreeCnfrmSheet.open()
          }
          break
        case 'CloseBtn':
          this.$refs.agreeCnfrmSheet.close()
          break
        case 'Confirm': // 약관 확인
          this.$refs.agreeCnfrmSheet.close('S')
          this.fn_AgreeConfirmYes() // 동의 나이스 링크 호출
          break
        case 'AllConfirm': // 전체 약관 확인
          this.$refs.agreeCnfrmSheet.close('S')
          break
      }
    },
    /******************************************************************************
    * Function명 : fn_AgreeConfirmYes
    * 설명       : 본인확인 서비스 이용 안내 confirm팝업창의 '동의' / '동의안함' 버튼 선택 시
    ******************************************************************************/
    fn_AgreeConfirmYes () {
      this.isCheckAgree = true
      this.fn_AgreePopContent()
    },
    /******************************************************************************
    * Function명 : fn_AgreePopContent
    * 설명       : 이통사 본인확인 서비스 이용에 따른 동의 나이스 링크 호출
    ******************************************************************************/
    fn_AgreePopContent () {
      if (this.$commonUtil.isMobile()) { // 모바일에서 작동
        window.fdpbridge.exec('intentBrowserPlugin', {uri: this.sCertVnoUrl}, () => {}, () => {})
      } else { // PC에서 작동
        window.open(this.sCertVnoUrl, '_blank')
      }
    },
    /******************************************************************************
    * Function명 : fn_SheetClose
    * 설명       : 본인확인서비스 이용동의 안내 Close Fn
    *              - 약관동의로 Close / 강제로 Close 구분 목적
    * @param  fn: BottomSheet Close Function
    * @param  item: BottomSheet Event Name
    * @notice 강제로 / X버튼으로 닫을시 Event Name = 'swipe'
    *         - 약관 동의하게되면 item = 'S' 
    ******************************************************************************/
    fn_SheetClose(fn_CloseSheet, item) {
      this.isCheckAgree = item === 'swipe' ? false : true
      fn_CloseSheet()
    },

    open1() {this.$refs.bottomSheet1.open();},
    close1() {this.$refs.bottomSheet1.close(); },

  } // ::Methods End
};
</script>