/*
 * 업무구분: 사고보험금 신청
 * 화 면 명: MSPBU071D
 * 화면설명: 보험금 신청정보 입력
 * 접근권한: 모든사용자
 * 작 성 일: 2023.02.17
 * 작 성 자: 김진원
 */
<template>
    <ur-box-container direction="column" align-v="start" align-h="center" componentid=""  class="msp-pm-wrap">
      <!-- 컨텐츠 영역 -->
      <ur-box-container align-h="center" direction="row" class="bc-notice pt90"> 
        <!-- 2023.02.15 수정: 완료이미지, 여백 수정 등 -->
        <ur-box-container align-h="center" direction="column" class="notice-cont complete-msg ty1 pt110">
          <span class="title">보험금청구 신청이<br> 완료되었습니다.</span>
          <!--<span class="title">사고보험금 신청서 작성이<br> 완료되었습니다.</span>
          <span class="title">보험금청구서 작성이<br> 완료되었습니다.</span>-->
          <div class="notice-text">
            <ul class="terms-list-area crTy-bk7 fs14rem">
              <li>서류는 본 화면에서 촬영한 파일이 자동으로 전송되어 제출됩니다.</li>
              <li>사진이 육안으로 식별 불가능한 경우이거나, 원본서류가 반드시 필요한 경우에는 재접수를 요청 할 수 있습니다.</li>
            </ul>
            <p class="pt10 pl5" @click="fn_Call">사고보험금 전용 콜센터 <b>1577-4118</b></p>
          </div>
        </ur-box-container>
        <!-- 2023.02.15 수정: 완료이미지, 여백 수정 등 //-->
      </ur-box-container>
      <!-- 컨텐츠 영역 //-->
    </ur-box-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
export default {
  /***********************************************************************************
  * Vue 파일 속성 정의 영역	                                                       *
  ***********************************************************************************/
  name: "MSPBU071D",
  screenId: "MSPBU071D",
  components: {},
  created(){
  },

  /***********************************************************************************
  * 화면변수 선언 영역	                                                             *
  ***********************************************************************************/
  methods: {
    fn_Call(){
      window.fdpbridge.exec('CallPlugin', {number: '1588-4118'}, function (result) {
        // 콜센터 다이얼패드

      }, function (result) {
        // 실패콜백 alert(result.data)
        // window.fdpbridge.exec('ToastPlugin', {message: '통화 시간을 받지 못했습니다..'}, () => {}, () => {})
      })
    }
  } 
};
</script>
<style scoped>
</style>