/*
 * 업무구분: 사고보험금 신청
 * 화 면 명: MSPBU008D
 * 화면설명: 본인인증 요약 동의서
 * 접근권한: 모든사용자
 * 작 성 일: 2023.02.17
 * 작 성 자: 김진원
 */
<template>
  <mo-validate-watcher ref="vWatcher">
    <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-agreeArea2 pal0">
      <div class="chk-box bdTN pb20">
        <div class="chk-title">보험금 청구를 위한 요약 동의서</div>
        <div class="list-txtBox pal0 pb30">
          <p class="pb10">
          귀하는 개인(신용)정보의 수집·이용 및 조회, 제공에 관한 동의를 거부하실 수 있으며, 개인의 신용도 등을 평가하기 위한 목적 이외의 개인(신용)
          정보 제공 동의는 철회할 수 있습니다. 다만, 본 동의는 ‘보험금 청구’를 위해 필수적인 사항이므로 동의를 거부하시는 경우 관련 업무수행이 불가능 합니다.</p>
          <p class="pb10">
          ‘거래종료일’이란 당사와의 모든 거래관계(보험, 융자, 수익증권, 신탁, 퇴직연금 등)에 대한 “①계약 만기, 해지, 취소, 철회일 또는 소멸일, ②보험금 청구권 소멸시효 완성일(상법 제662조), ③채권·채무 관계 소멸일 중 가장 나중에 도래한 사유를 기준으로 판단한 날”을 뜻합니다.
          </p>                             
          <p style="font-size: 1rem; font-weight: bold;">본 동의서는 요약 동의서이며 요청하면 전체 동의서를 안내받으실 수 있습니다. 요청하시겠습니까?</p> 
        </div>
        <div class="ns-check flex">
          <mo-checkbox :disabled="isDisabled" v-model="isCheckedAllAgreeDocY" @input="fn_SetAllAgreeDocY" class="flexGrw0">예</mo-checkbox>
          <mo-checkbox :disabled="isDisabled" v-model="isCheckedAllAgreeDocN" @input="fn_SetAllAgreeDocN" class="flexGrw0 ml30">아니오</mo-checkbox>
        </div>
        <p class="fs14rem pt10 pb20">
          * 피보험자와 수익자가 동일한 경우 피보험자란에만 동의 해 주셔도 됩니다. 미성년자인 경우 친권자(후견인)가 동의하여 주시기 바랍니다.
        </p>
      </div>
      <div class="chk-box bdTN pb20">
        <div class="chk-title">보험금 청구를 위한 요약 동의서</div>
        <div class="ns-check flex">
          <mo-checkbox :disabled="isDisabled" v-model="isAgreementAll">전체동의</mo-checkbox>
        </div>
      </div>
      <mo-list-item :disabled="isDisabled" class="agree-list accordion-type2 maxH200">
        <msp-expand ref="agreementA" btn-area-first expanded title-first class="mo-list-expand" btn-area-class="fexTy3-1 mb20">
          <template #title>
            <div class="list-item__contents">
              <div class="list-item__contents__title">
                <div class="ns-check">
                  <mo-checkbox :disabled="isDisabled" v-model="isAgreementA">1. 수집·이용에 관한 사항</mo-checkbox>
                </div>
              </div>
            </div>
          </template>
          <template #btn>
            <mo-button class="link-arrow down"></mo-button>
          </template>
          <template #content>
            <div class="list-txtBox pt20">
              <strong class="crTy-bk1 fwb">수집 · 이용 목적</strong>
              <ul class="txt-indent">
                <li class="pb3">- 보험금 지급 및 심사(손해사정, 의료자문 포함), 보험사고 조사, 보험사기 조사, 지급 보험금 타당성 검토, 부당지급 보험금 환수, 본인부담상한제 확인, 조사연구(지급 서비스 개선) 등</li>
              </ul>
            </div>
            <div class="list-txtBox pt5">
              <strong class="crTy-bk1 fwb">보유 및 이용기간</strong>
              <ul class="txt-indent">
                <li class="pb3 crTy-blue4 underline">- 동의일로부터 거래종료 후 5년까지</li>
                <li class="pb3">- 단, 다른 관련 법령에 해당하는 경우 해당 법령상의 보존기간을 따릅니다.</li>
                <li class="pb3">- 거래 종료후 5년이 경과된 후에는 보험금 지급, 금융사고조사, 보험사기방지 및 적발, 민원처리, 법령상의무이행을 위한 경우에 한하여 보유ㆍ이용하며, 별도 보관합니다.</li>
              </ul>
            </div>
            <div class="list-txtBox">
              <strong>■  수집 · 이용 항목</strong>
              <strong class="crTy-bk1 fwb pt10">고유식별정보(4개)</strong>
              <p class="crTy-blue4 fs14rem underline">주민등록번호, 외국인등록번호, 여권번호, 운전면허번호</p>
              <p class="pt15 fs14rem crTy-bk7">위 <b class="underline">고유식별정보 수집ᆞ이용</b>에 동의하십니까?</p>
              <div class="chk-box bdnone pt10 pl0">
                <div class="ns-check flex">
                  <mo-checkbox :disabled="isDisabled" v-model="isCheckedA1">동의함</mo-checkbox>
                </div>
              </div>
              <strong class="crTy-bk1 fwb pt10">민감정보(2개)</strong>
              <p><span class="crTy-blue4 fs14rem underline">피보험자의 질병·상해에 관한 정보, 보험사기조사(보험사기 포함) 및 
                손해사정 업무 수행과 관련하여 취득한 정보</span></p>
              <p class="pt15 fs14rem crTy-bk7">위 <b class="underline">민감정보 수집·이용</b>에 동의하십니까?</p>
              <div class="chk-box bdnone pt10 pb20 pl0">
                <div class="ns-check flex">
                  <mo-checkbox :disabled="isDisabled" v-model="isCheckedA2">동의함</mo-checkbox>
                </div>
              </div>
              <strong class="crTy-bk1 fwb pt10 pb15">개인(신용)정보(18개)</strong>
              <strong class="crTy-bk1 pb5">[일반개인정보](10개)</strong>
              <p>성명, 주소, 전화번호 등</p>
              <strong class="crTy-bk1 pt10 pb5">[신용거래정보](6개)</strong>
              <p>보험계약정보 등</p>
              <strong class="crTy-bk1 pt10 pb5">[공공정보](2개)</strong>
              <p>건강보험 가입유형, 건강보험료 (월)납부액</p>
              <p class="pt15 fs14rem crTy-bk7">위 <b class="underline">개인신용정보 수집·이용</b>에 동의하십니까?</p>
              <div class="chk-box bdnone pt25 pb15 pl0">
                <div class="ns-check flex">
                  <mo-checkbox :disabled="isDisabled" v-model="isCheckedA3">동의함</mo-checkbox>
                </div>
              </div>
              <p class="fs14rem crTy-blue4 pt10 pb20">
                * 단, 공공정보의 경우, 실손급부 청구시에만 수집ㆍ이용되며, 그 외 상품 청구시에는 동의하시더라도 수집ㆍ이용되지 않습니다.
              </p>
            </div>
          </template>
        </msp-expand>
      </mo-list-item>
      <mo-list-item class="agree-list accordion-type2 maxH200">
        <msp-expand ref="agreementB" btn-area-first expanded title-first class="mo-list-expand" btn-area-class="fexTy3-1 mb20">
          <template #title>
            <div class="list-item__contents">
              <div class="list-item__contents__title">
                <div class="ns-check">
                  <mo-checkbox :disabled="isDisabled" v-model="isAgreementB">2. 제공에 관한 사항</mo-checkbox>
                </div>
              </div>
            </div>
          </template>
          <template #btn>
            <mo-button class="link-arrow down"></mo-button>
          </template>
          <template #content>
            <div class="list-txtBox pt20">
              <strong class="crTy-bk1 fwb">제공받는자(25개)</strong>
              <p class="crTy-blue4 fs14rem underline">종합신용정보집중기관, 생명보험회사, 손해보험회사, 금융거래기관, 생명보험협회, 손해보험협회 등 </p>
            </div>
            <div class="list-txtBox pt5">
              <strong class="crTy-bk1 fwb">제공받는 자의 이용 목적</strong>
              <p class="crTy-blue4 fs14rem underline">보험업법 등 법령에 따른 업무 수행, 개인(신용)정보 조회, 신용정보의 집중관리 및 활용 등 법령에서 정한 
                종합신용정보집중기관의 업무 수행, 보험금 지급·심사 관련 업무 지원, 중복보험 확인 및 비례보상 업무 수행, 재보험금 청구, 보험금 지급·심사, 
                보험사고 조사 (보험사기조사 포함) 및 손해사정 등 계약 이행에 필요한 업무 수행(위·수탁 사무 수행 포함),  의료심사 및 자문 업무 수행, 소견서 발급, 
                진료기록 열람, 보험사기 조사, 지급 보험금 타당성 검토, 부당지급 보험금 환수, 법률자문 및 소송관련 업무수행, 금융거래업무, 손해사정내용 관련 정보제공 등
              </p>
            </div>
            <div class="list-txtBox pt5">
              <strong class="crTy-bk1 fwb">보유 및 이용기간</strong>
              <p class="crTy-blue4 fs14rem underline">제공받는 자의 이용목적을 달성할 때까지(관련 법령상 보존기간을 따름)</p>
              <p class="fs14rem crTy-blue4 pt10">
                * 외국 재보험사의 국내지점이 재보험금 청구 등 지원 업무를 위탁하기 위한 경우 별도의 동의 없이 외국 소재 본점에 귀하의 정보를 이전할 수 있습니다.
              </p>
            </div>
            <div class="list-txtBox">
              <strong>■  제공항목</strong>
              <strong class="crTy-bk1 fwb pt10">고유식별정보(4개)</strong>
              <p class="crTy-blue4 fs14rem underline">주민등록번호, 외국인등록번호, 여권번호, 운전면허번호</p>
              <p class="pt15 fs14rem crTy-bk7">위 <b class="underline">고유식별정보 제공</b>에 동의하십니까?</p>
              <div class="chk-box bdnone pt10 pl0">
                <div class="ns-check flex">
                  <mo-checkbox :disabled="isDisabled" v-model="isCheckedB1">동의함</mo-checkbox>
                </div>
              </div>
              <strong class="crTy-bk1 fwb pt10">민감정보(2개)</strong>
              <p class="crTy-blue4 fs14rem underline">피보험자의 질병ㆍ상해에 관한 정보, 보험사기조사(보험사기 포함) 및 손해사정 업무수행과 관련하여 취득한 정보</p>
              <p class="pt15 fs14rem crTy-bk7">위 <b class="underline">민감정보 제공</b>에 동의하십니까?</p>
              <div class="chk-box bdnone pt10 pb20 pl0">
                <div class="ns-check flex">
                  <mo-checkbox :disabled="isDisabled" v-model="isCheckedB2">동의함</mo-checkbox>
                </div>
              </div>
              <strong class="crTy-bk1 fwb pt10 pb15">개인(신용)정보(16개)</strong>
              <strong class="crTy-bk1 pb5">[일반개인정보](10개)</strong>
              <p>성명, 주소, 전화번호등</p>
              <strong class="crTy-bk1 pt10 pb5">[신용거래정보](6개)</strong>
              <p>보험계약정보등</p>
              <p class="pt15 fs14rem crTy-bk7">위 <b class="underline">개인신용정보 제공*</b>에 동의하십니까?</p>
              <div class="chk-box bdnone pt25 pb15 pl0">
                <div class="ns-check flex">
                  <mo-checkbox :disabled="isDisabled" v-model="isCheckedB3">동의함</mo-checkbox>
                </div>
              </div>
              <p class="fs14rem crTy-blue4 pt10 pb20">
                * 업무위탁을 목적으로 개인(신용)정보를 처리하는 경우 별도의 동의 없이 업무 수탁자에게 귀하의 정보를 제공할 수 있습니다.
                (홈페이지 [www.samsunglife.com]에서 확인 가능)
              </p>
            </div>
          </template>
        </msp-expand>
      </mo-list-item>
      <mo-list-item class="agree-list accordion-type2 maxH200">
        <msp-expand ref="agreementC" btn-area-first expanded title-first class="mo-list-expand" btn-area-class="fexTy3-1 mb20">
          <template #title>
            <div class="list-item__contents">
              <div class="list-item__contents__title">
                <div class="ns-check">
                  <mo-checkbox :disabled="isDisabled" v-model="isAgreementC">3. 조회에 관한 사항</mo-checkbox>
                </div>
              </div>
            </div>
          </template>
          <template #btn>
            <mo-button class="link-arrow down"></mo-button>
          </template>
          <template #content>
            <div class="list-txtBox pt20">
              <strong class="crTy-bk1 fwb">조회대상기관(3개)</strong>
              <ul class="txt-indent">
                <li>- 종합신용정보집중기관, 생명보험협회, 손해보험협회</li>
              </ul>
              <strong class="crTy-bk1 fwb pt20">조회목적</strong>
              <ul class="txt-indent">
                <li>- 보험사고ㆍ보험사기 조사 및 보험금 지급ㆍ심사(보험금 청구서류 접수대행 서비스 포함) 등</li>
              </ul>
              <strong class="crTy-bk1 fwb pt20">조회 동의의 효력기간</strong>
              <p class="crTy-blue4 fs14rem underline">해당 보험거래 종료일까지 동의의 효력이 지속됩니다.</p>
            </div>
            <div class="list-txtBox last">
              <strong>■  조회항목</strong>
              <strong class="crTy-bk1 fwb pt10">고유식별정보(4개)</strong>
              <p class="crTy-blue4 fs14rem underline">주민등록번호, 외국인등록번호, 여권번호, 운전면허번호</p>
              <p class="pt15 fs14rem crTy-bk7">위 <b class="underline">고유식별정보 조회</b>에 동의하십니까?</p>
              <div class="chk-box bdnone pt10 pl0">
                <div class="ns-check flex">
                  <mo-checkbox :disabled="isDisabled" v-model="isCheckedC1">동의함</mo-checkbox>
                </div>
              </div>
              <strong class="crTy-bk1 fwb pt10">민감정보(1개)</strong>
              <p class="crTy-blue4 fs14rem underline">피보험자의 질병·상해에 관한 정보</p>
                <p class="pt15 fs14rem crTy-bk7">위 <b class="underline">민감정보 조회</b>에 동의하십니까?</p>
              <div class="chk-box bdnone pt10 pl0">
                <div class="ns-check flex">
                  <mo-checkbox :disabled="isDisabled" v-model="isCheckedC2">동의함</mo-checkbox>
                </div>
              </div>
              <strong class="crTy-bk1 fwb pt10 pb15">개인(신용)정보(7개)</strong>
              <strong class="crTy-bk1 pb5">[일반개인정보](2개)</strong>
              <p>성명, 국내거소 신고번호</p>
              <strong class="crTy-bk1 pt10 pb5">[신용거래정보](5개)</strong>
              <p>보험계약정보, 보험금 지급 정보</p>
              <p class="pt15 fs14rem crTy-bk7">위 <b class="underline">개인신용정보 조회</b>에 동의하십니까?</p>
              <div class="chk-box bdnone pt25 pb15 pl0">
                <div class="ns-check flex">
                  <mo-checkbox :disabled="isDisabled" v-model="isCheckedC3">동의함</mo-checkbox>
                </div>
              </div>
            </div>
          </template>
        </msp-expand>
      </mo-list-item>
    </ur-box-container>
  </mo-validate-watcher>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/


export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPBU008D",
  screenId: "MSPBU008D",
  components: {},
  props: {
    index: {type: Number},
    data: {type: Object}, //stepData[index]
  },
  watch: {
    //다음에하기
    'data.isToLater': function(isChecked){
      if( isChecked ){
        this.$emit('setAllAgreeDoc', this.$props.index, 3)
        this.isAgreementAll = false
        this.isCheckedAllAgreeDocY = false
        this.isCheckedAllAgreeDocN = false
      }
    },

    //동의서 안내 형식 변경 시
    'data.agreeDocType': function() {
      this.isAgreementAll = false
      this.isCheckedAllAgreeDocY = false
      this.isCheckedAllAgreeDocN = false
    },

    isAgreementA(value){
      this.$refs.agreementA.isExpand = !value
    },

    isAgreementB(value){
      this.$refs.agreementB.isExpand = !value
    },

    isAgreementC(value){
      this.$refs.agreementC.isExpand = !value
    },
  },


  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    
  },
  mounted() {

  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      isCheckedAllAgreeDocY: false, //전체동의서 안내 예
      isCheckedAllAgreeDocN: false, //전체동의서 안내 아니오

      //1.수집.이용에 관항 사항
      isCheckedA1: false,
      isCheckedA2: false,
      isCheckedA3: false,
      
      //2.제공에 관한 사항
      isCheckedB1: false,
      isCheckedB2: false,
      isCheckedB3: false,
      
      //3. 조회에 관한 사항
      isCheckedC1: false,
      isCheckedC2: false,
      isCheckedC3: false,
      
    }
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    isDisabled(){
      return this.$props.data.isToLater || this.$props.data.authSuccessTime === ''
    },

    isAgreementAll: {
      get: function(){
        let isChecked = this.isAgreementA && this.isAgreementB && this.isAgreementC
        this.$emit('setAgreementAll', this.$props.index, isChecked)
        
        return isChecked
      },
      set: function(isChecked){
        this.isAgreementA = isChecked
        this.isAgreementB = isChecked
        this.isAgreementC = isChecked
        this.$emit('setAgreementAll', this.$props.index, isChecked)
      }

    },

    isAgreementA: {
      get: function(){
        return this.isCheckedA1 && this.isCheckedA2 && this.isCheckedA3

      },
      set: function(isChecked){
        this.isCheckedA1 = isChecked
        this.isCheckedA2 = isChecked
        this.isCheckedA3 = isChecked
        
      }
    },

    isAgreementB: {
      get: function(){
        return this.isCheckedB1 && this.isCheckedB2 && this.isCheckedB3
      },
      set: function(isChecked){
        this.isCheckedB1 = isChecked
        this.isCheckedB2 = isChecked
        this.isCheckedB3 = isChecked
        
      }
    },

    isAgreementC: {
      get: function(){
        return this.isCheckedC1 && this.isCheckedC2 && this.isCheckedC3
      },
      set: function(isChecked){
        this.isCheckedC1 = isChecked
        this.isCheckedC2 = isChecked
        this.isCheckedC3 = isChecked
        
      }
    }
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    fn_SetAllAgreeDocY(isChecked){
      if( isChecked ){
        this.$emit('setAllAgreeDoc', this.$props.index, 1)
      }
    },

    fn_SetAllAgreeDocN(isChecked){
      this.$emit('setAllAgreeDoc', this.$props.index, isChecked?2:3)
    },

  }
};
</script>
<style scoped>
</style>