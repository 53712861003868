/*
 * 업무구분: 사고보험금 신청
 * 화 면 명: MSPBU041D
 * 화면설명: 청구세부내용(청구서작성)
 * 접근권한: 모든사용자
 * 작 성 일: 2023.02.17
 * 작 성 자: 김진원
 */
<template>
  <!-- 컨텐츠 영역 -->
  <ur-box-container direction="column" alignV="start" class="msp-pm-wrap">
    <!-- 계약자 정보 -->
    <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area msp-list-area pb0">  
      <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-check-list">
        <span ref="step4Top"></span>
        <mo-list :list-data="custList">
            <template #list-item="{item}">
              <mo-list-item>
                <div class="list-item__contents pt5 pb10">
                  <div class="list-item__contents__title"> 
                    <span class="name txtSkip maxW250 fexInt pr10">{{item.custNm}}</span>
                    <!-- 본인, 수익자상이, 자녀 인 경우 -->
                    <mo-badge class="badge-sample-badge sm"
                              :class="item.custType ==='피보험자'?'lightyellow2':'lightgreen'"
                              text="" shape="status">{{item.custType}}</mo-badge>
                    <!-- 본인 인 경우 피보험자 & 수익자 badge 를 둘다 표시 -->
                    <mo-badge class="badge-sample-badge sm lightgreen"
                              text="수익자" shape="status"
                              v-if="$props.customerType === 'SELF'">수익자</mo-badge>
                  </div>
                  <div class="list-item__contents__info">
                    <div class="txt_box">
                      <span class="crTy-bk1">{{item.age}}세</span><em>|</em><span class="crTy-bk1">{{item.rrn.replace(/(\d{6})(.*)/, '$1-$2')}}</span>
                    </div>
                  </div>
                </div>
              </mo-list-item>
            </template>
        </mo-list>
      </ur-box-container>
    </ur-box-container>
    <!-- 계약자 정보 //-->

    <!-- 리스트 S -->
    <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area msp-list-area msp-list-bottom bd-T-Ty2 pb100">  
      <!-- 직업 시작  -->
      <!-- <mo-validate-watcher ref="jobWatcher">        
        <mo-list-item class="agree-list accordion-type2 accordion-type3 bd-T-Ty4">
          <msp-expand ref="listItem1" btn-area-first title-first expanded expand-only-btn class="mo-list-expand" btn-area-class="fexTy3-1 mb20">
            <template #title class="w100">
              <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-contract-list">
                <div class="list-item pal0">
                  <div class="list-item__contents">
                    <div class="list-item__contents__title mb0 fexTy3-1">
                      <span class="name txtSkip maxW250 fexInt pr10" ref="jobInfo">피보험자 직업정보</span>
                      <span class="certify-complete"><mo-icon icon="msp-is-checked" class="fs26"/></span>
                    </div>
                  </div>
                </div> 
              </ur-box-container>
            </template>
            <template #btn>
              <mo-button class="link-arrow down"></mo-button>
            </template>
            <template #content>
              <ur-box-container class="ns-add-area pa2024">
                <div class="info-title-wrap">
                  <span class="label-title must">직장명</span>
                </div>
                <mo-text-field class="form-input-name" v-model="jobData.jobpNm" :rules="validRequired" underline placeholder="직장명을 입력해주세요"/>
                <div class="info-title-wrap mt20">
                  <span class="label-title must">하시는일</span>
                </div>
                <mo-text-field class="form-input-name" v-model="jobData.work" :rules="validRequired" underline placeholder="하시는일을 입력해주세요"/>
                <div class="info-title-wrap mt20">
                  <span class="label-title">직업</span>
                </div>
                <mo-text-field v-model="jobData.jobCdNm" @focus="fn_OpenMSPCM013P" @click-icon="fn_OpenMSPCM013P" readonly searchable underline placeholder="선택해주세요." class="full mt10"/>
              </ur-box-container>                    
            </template>
          </msp-expand>
        </mo-list-item>         
      </mo-validate-watcher> -->
      <!-- 직업 끝 -->

      <mo-validate-watcher ref="claimWatcher">
        <!-- 청구세부내용 시작  -->
        <mo-list-item class="agree-list accordion-type2 accordion-type3">
          <msp-expand ref="listItem2" btn-area-first title-first expanded expand-only-btn class="mo-list-expand" btn-area-class="fexTy3-1 mb20">
            <template #title class="w100">
              <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-contract-list">
                <div class="list-item pal0">
                  <div class="list-item__contents">
                    <div class="list-item__contents__title mb0 fexTy3-1"> 
                      <span class="name txtSkip maxW250 fexInt pr10">청구세부내용</span>
                      <span class="certify-complete"><mo-icon icon="msp-is-checked" class="fs26"/></span>
                    </div>
                  </div>
                </div> 
              </ur-box-container>
            </template>
            <template #btn>
              <mo-button class="link-arrow down"></mo-button>
            </template>
            <template #content>
              <ur-box-container class="ns-add-area pa2024" ref="claimContent">
                <div class="info-title-wrap">
                  <span class="label-title must mb0" ref="acdtAcpnObjScCd">청구유형</span>
                </div>
                <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
                  <mo-segment-wrapper @input="fn_acdtAcpnObjScCd" solid primary class="mt10" v-model="claimData.acdtAcpnObjScCd"> <!-- 실손가입 0건 -->
                    <mo-segment-button value="1">정액</mo-segment-button>
                    <mo-segment-button :disabled="$props.step3Data.rldmCnt === 0" value="2">실손</mo-segment-button>
                    <mo-segment-button :disabled="$props.step3Data.rldmCnt === 0" value="3">정액+실손</mo-segment-button>
                  </mo-segment-wrapper>
                  <div class="crTy-orange2" v-show="isAcdtAcpnObjScCd">청구유형을 선택 바랍니다.</div>
                </ur-box-container>
                <ur-box-container alignV="start" componentid="" direction="column" class="ns-add-box">
                  <div class="info-title-wrap mt20">
                    <span class="label-title must" ref="diseDstrScCd">발생원인</span>
                  </div>
                  <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment mt10">
                    <mo-segment-wrapper solid primary v-model="claimData.diseDstrScCd" @input="fn_DiseDstrScCdChange" class="chip-type-wrap chip-ty1">
                      <mo-segment-button value="1">질병</mo-segment-button>
                      <mo-segment-button value="2">재해</mo-segment-button>
                    </mo-segment-wrapper>
                    <div class="crTy-orange2" v-show="isDiseDstrScCd">발생원인을 선택 바랍니다.</div>
                  </ur-box-container>
                </ur-box-container>
                <ur-box-container alignV="start" componentid="" direction="column" class="ns-add-box">
                  <div class="info-title-wrap mt20">
                    <span class="label-title must" ref="amount">청구금액</span>
                  </div>
                  <div class="ns-radio-list">
                    <mo-radio v-model="claimData.amount" value="01" class="mb20">100만원 이하</mo-radio>
                    <mo-radio v-model="claimData.amount" value="02" class="mb20">100~1,000만원 이하</mo-radio>
                    <mo-radio v-model="claimData.amount" value="03" class="mb20">1,000만원 초과</mo-radio>
                  </div>
                  <div class="crTy-orange2 mb25" v-show="isAmount">{{amountMessage}}</div>
                </ur-box-container>
                <ur-box-container alignV="start" componentid="" direction="column" class="ns-add-box">
                  <div class="info-title-wrap">
                    <span class="label-title" :class="{must: claimData.acdtAcpnObjScCd === '2' || claimData.acdtAcpnObjScCd === '3'}" ref="mdcrRcbfr">의료수급권자</span>
                  </div>
                  <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment mt10">
                    <mo-segment-wrapper solid primary v-model="claimData.mdcrRcbfr" class="chip-type-wrap chip-ty1">
                      <mo-segment-button value="Y">예</mo-segment-button>
                      <mo-segment-button value="N">아니오</mo-segment-button>
                    </mo-segment-wrapper>
                    <div class="crTy-orange2" v-show="isMdcrRcbfr">의료수급권자를 선택 바랍니다.</div>
                  </ur-box-container>
                </ur-box-container>
                <ur-box-container alignV="start" componentid="ur_box_container_010" direction="column" class="ns-chk-box mt20 mb20" >
                  <div class="info-title-wrap mb10">
                    <span class="label-title must mb10" ref="rsnMdhsp">청구사유</span>
                  </div>
                  <div class="ns-check msp-multi-check"> 
                    <mo-checkbox v-model="claimData.rsnMdhsp[0]" value="01" v-show="$props.customerType !== 'SELF'">사망</mo-checkbox>
                    <mo-checkbox v-model="claimData.rsnMdhsp[1]" value="08">장해</mo-checkbox>
                    <mo-checkbox v-model="claimData.rsnMdhsp[2]" value="02">진단</mo-checkbox>
                    <mo-checkbox v-model="claimData.rsnMdhsp[3]" value="06">수술</mo-checkbox>
                    <mo-checkbox v-model="claimData.rsnMdhsp[4]" value="03" @input="fn_InfoMessage">입원</mo-checkbox>
                    <mo-checkbox v-model="claimData.rsnMdhsp[5]" value="05">통원</mo-checkbox>
                    <mo-checkbox v-model="claimData.rsnMdhsp[6]" value="09" :disabled="claimData.acdtAcpnObjScCd==='1'">실손입원</mo-checkbox>
                    <mo-checkbox v-model="claimData.rsnMdhsp[7]" value="10" @input="(v) => {if(v === '') claimData.tiDsnm = ''}" :disabled="claimData.acdtAcpnObjScCd==='1'">실손통원</mo-checkbox>
                    <mo-checkbox v-model="claimData.rsnMdhsp[8]" value="00" @input="(v) => {if(v === '') claimData.tiRsnEtc = ''}">기타</mo-checkbox>
                  </div>
                  <div class="crTy-orange2" v-show="isRsnMdhsp">청구사유 항목을 1개 이상 선택 바랍니다.</div>
                </ur-box-container>
                <ur-box-container alignV="start" componentid="" direction="column" class="ns-add-box">
                  <!-- 기타 선택시 : 입력창 표출 -->
                  <div class="check-con w100 mb30" ref="tiRsnEtc">
                    <mo-text-field v-model="claimData.tiRsnEtc" class="form-input-name" :disabled="$bizUtil.isEmpty(claimData.rsnMdhsp[8])" underline placeholder="기타 선택시 입력해주세요"/>
                    <div class="crTy-orange2" v-show="isTiRsnEtc">청구사유 기타 선택 시 입력 바랍니다.</div>
                  </div>

                  <!-- 실손통원 선택시 : 병명입력창 표출 -->
                  <div class="check-con w100" v-show="isNotEmpty(claimData.rsnMdhsp[7])">
                    <div class="info-title-wrap">
                      <span class="label-title must" ref="tiDsnm">병명</span>
                    </div>
                    <mo-text-field v-model="claimData.tiDsnm" class="form-input-name" underline placeholder="병명을 입력하세요"/>
                    <div class="crTy-orange2" v-show="isTiDsnm">병명을 입력 바랍니다.</div>
                    <ur-box-container alignV="start" componentid="" direction="column" class="text-gray-box mt20">
                      <ul class="terms-list-area crTy-bk7 fs14rem w100">
                        <li class="">
                          통원(외래의료비/처방조제비) 청구 시 병명이 2개이상이면, 영수증 상단에 각각 병명을 기재해주시기 바랍니다.<br>
                          단, 추가적 심사필요 판단 시 병명 증빙서류 등을 요청할 수 있습니다.
                        </li>
                      </ul>
                    </ur-box-container>
                  </div>
                </ur-box-container> 
              </ur-box-container>                    
            </template>
          </msp-expand>
        </mo-list-item> 
        <!-- 청구세부내용 끝 -->
      </mo-validate-watcher>

      <mo-validate-watcher ref="contentWatcher">
        <!-- 사고 상세내용 시작  -->
        <mo-list-item class="agree-list accordion-type2 accordion-type3">
          <msp-expand ref="listItem3" btn-area-first title-first expanded expand-only-btn class="mo-list-expand" btn-area-class="fexTy3-1 mb20">
            <template #title class="w100">
              <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-contract-list">
                <div class="list-item pal0">
                  <div class="list-item__contents">
                    <div class="list-item__contents__title mb0 fexTy3-1"> 
                      <span class="name txtSkip maxW250 fexInt pr10">사고 상세내용</span>
                      <span class="certify-complete"><mo-icon icon="msp-is-checked" class="fs26"/></span>
                    </div>
                  </div>
                </div> 
              </ur-box-container>
            </template>
            <template #btn>
              <mo-button class="link-arrow down"></mo-button>
            </template>
            <template #content>
              <ur-box-container class="ns-add-area pa2024 pb35">
                <div class="w100">
                  <div class="info-title-wrap">
                    <span class="label-title must" ref="adrnDateTime">사고일시</span>
                  </div>
                  <ur-box-container alignV="start" componentid="" direction="column">
                    <div class="add-date w100">
                      <mo-date-picker v-model="contentData.adrnDate" :maxDate="currentDate" bottom placeholder="YYYY-MM-DD" class="ns-date-picker w70 flex-auto" />
                      <span class="ns-space-10"></span>
                      <!-- 2023-02-13 수정 : 시계 컴포넌트 -> 컴포넌트에서 시간만 지원이 안되어, 시간은 select박스로 표출되는거로 공통개발에서 협의되었습니다. -->
                      <div class="w40 row-text ty--2 fexTy3">
                        <msp-bottom-select v-model="contentData.adrnTime" :items="times" underline class="time-select mr0" placeholder="HH" bottom-title="시간을 선택해주세요." closeBtn/>
                      </div>
                    </div>
                    <div class="crTy-orange2" v-show="isAdrnDateTime">사고일을 선택 바랍니다.</div>
                  </ur-box-container>
                  <div class="info-title-wrap mt20">
                    <span class="label-title must" ref="cstiCntry">사고국가</span>
                  </div>
                  <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
                    <msp-bottom-select v-model="contentData.cstiCntry" @selectedItem="selectedItemCstiCntry" :items="cmcd.ZA_MOBSL_CNTRY_CD" :itemValue="'key'" :itemText="'label'" class="ns-dropdown-sheet" underline bottom-title="국가 선택"  placeholder="국가를 선택해 주세요" closeBtn scrolling/>
                    <div class="crTy-orange2" v-show="isCstiCntry">사고국가를 선택 바랍니다.</div>
                  </ur-box-container>
                  <div class="info-title-wrap mt20">
                    <span class="label-title" :class="{must: isRequired}" ref="cstiAcdt">사고경위</span>
                  </div>
                  <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
                    <msp-bottom-select v-model="contentData.cstiAcdt" :items="cmcd.ACDT_CLSF_CD" class="ns-dropdown-sheet" underline bottom-title="사고경위 선택"
                                      :placeholder="$bizUtil.isEmpty(claimData.diseDstrScCd) ? '청구세부내용의 발생원인을 먼저 선택하세요.' : '선택하세요.'" closeBtn scrolling/>
                    <div class="crTy-orange2" v-show="isCstiAcdt">사고경위를 선택 바랍니다.</div>
                  </ur-box-container>
                  <div class="info-title-wrap mt20">
                    <span class="label-title" :class="{must: isRequired}" ref="tiAcdtDtl">상세내용</span>
                  </div>
                  <mo-text-field v-model="contentData.tiAcdtDtl" class="form-input-name" underline placeholder="사고내용을 입력하세요" :maxlength="50"/>
                  <div class="crTy-orange2" v-show="isTiAcdtDtl">상세내용을 입력 바랍니다.</div>
                  <div class="info-title-wrap mt20">
                    <span class="label-title mb0" ref="rbgAtcarInsrYn">자동차보험/산재보험처리 여부</span>
                  </div>
                  <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment mt10">
                    <mo-segment-wrapper solid primary v-model="contentData.rbgAtcarInsrYn" class="chip-type-wrap chip-ty1">
                      <mo-segment-button value="Y">예</mo-segment-button>
                      <mo-segment-button value="N">아니오</mo-segment-button>
                    </mo-segment-wrapper>
                    <div class="crTy-orange2" v-show="isRbgAtcarInsrYn">자동차보험/산재보험처리를 선택 바랍니다.</div>
                  </ur-box-container>
                </div>

                <!-- 2024.03.19 타사가입 > 타사실손 가입여부로 변경 (사고P 정수연 프로) -->
                <div class="w100" v-show="isNotEmpty(claimData.rsnMdhsp[6]) || isNotEmpty(claimData.rsnMdhsp[7])">
                  
                  <div class="info-title-wrap mt20">
                    <span class="label-title must" ref="sinSonEntYn">타사실손 가입여부(단체실손포함)</span>
                  </div>
                  <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment mt10">
                    <mo-segment-wrapper @input="fn_sinSonEntYn" solid primary v-model="contentData.sinSonEntYn" class="chip-type-wrap chip-ty1">
                      <mo-segment-button value="Y">예</mo-segment-button>
                      <mo-segment-button value="N">아니오</mo-segment-button>
                    </mo-segment-wrapper>
                    <div class="crTy-orange2" v-show="isSinSonEntYn">타사실손 가입여부(단체실손포함)를 입력 바랍니다.</div>
                  </ur-box-container>

                  <!-- 2024.03.19 타사가입 '예' 선택시 표출 내용 삭제 (사고P 정수연 프로) -->
                  <!-- 타사가입 '예' 선택시 : 보험회사, 가입건수 입력창 표출 -->

                  <!-- 실손가입여부(단체실손포함) '예' 선택시 :  실손의료비 청구서류 접수대행신청 표출 -->
                  <ur-box-container ref="mdexpAcpnVicaYnContainer" alignV="start" componentid="" direction="column" class="check-con ns-add-box custom-container">
                    <div class="info-title-wrap mt20">
                      <span class="label-title mb0" ref="mdexpAcpnVicaYn">실손의료비 청구 대행 신청</span>
                    </div>
                    <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment mt10">
                      <mo-segment-wrapper @input="fn_mdexpAcpnVicaYn" solid primary v-model="contentData.mdexpAcpnVicaYn" class="chip-type-wrap chip-ty1">
                        <mo-segment-button value="Y">예</mo-segment-button>
                        <mo-segment-button value="N">아니오</mo-segment-button>
                      </mo-segment-wrapper>
                      <div class="crTy-orange2" v-show="isMdexpAcpnVicaYn">실손의료비 청구 대행 신청 여부를 선택 바랍니다.</div>
                    </ur-box-container>
                  </ur-box-container>
                  <!-- 실손가입여부(단체실손포함) '예' 선택시 :  실손의료비 청구서류 접수대행신청 표출 //-->
                  <!-- 실손의료비 청구서류 접수대행신청 '예' 선택시 :  중복 가입된 모든회사, 서류를 보내고자 하는 회사 표출 -->
                  <ur-box-container ref="mdexpAcpnVicaAllDuplicateCoYnContainer" alignV="start" componentid="" direction="column" class="check-con ns-add-box custom-container">
                    <div class="info-title-wrap mt20">
                      <span class="label-title mb0" ref="mdexpAcpnVicaAllDuplicateCoYn">중복 가입된 모든 회사</span>
                    </div>
                    <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment mt10">
                      <mo-segment-wrapper @input="fn_mdexpAcpnVicaAllDuplicateCoYn" solid primary v-model="contentData.mdexpAcpnVicaAllDuplicateCoYn" class="chip-type-wrap chip-ty1">
                        <mo-segment-button value="Y">예</mo-segment-button>
                        <mo-segment-button value="N">아니오</mo-segment-button>
                      </mo-segment-wrapper>
                      <div class="crTy-orange2" v-show="isMdexpAcpnVicaAllDuplicateCoYn">중복 가입된 모든 회사 여부를 선택 바랍니다.</div>
                    </ur-box-container>
                    <div class="info-title-wrap mt20 custom-container" ref="mdexpAcpnVicaInscCdContainer">
                      <div class="mt20">
                        <span class="label-title mb5" ref="mdexpAcpnVicaInscCd">서류를 보내고자하는 회사</span>
                      </div>
                      <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
                        <msp-bottom-select :items="cmcd.ISRNC_CD" v-model="contentData.mdexpAcpnVicaInscCd1" @selectedItem="selectedItemIsrncCd1" :itemValue="'key'" :itemText="'label'" class="ns-dropdown-sheet mb20" underline bottom-title="보험회사 선택" placeholder="선택하세요" closeBtn scrolling/>
                        <msp-bottom-select :items="cmcd.ISRNC_CD" v-model="contentData.mdexpAcpnVicaInscCd2" @selectedItem="selectedItemIsrncCd2" :itemValue="'key'" :itemText="'label'" class="ns-dropdown-sheet mb20" underline bottom-title="보험회사 선택" placeholder="선택하세요" closeBtn scrolling/>
                        <msp-bottom-select :items="cmcd.ISRNC_CD" v-model="contentData.mdexpAcpnVicaInscCd3" @selectedItem="selectedItemIsrncCd3" :itemValue="'key'" :itemText="'label'" class="ns-dropdown-sheet" underline bottom-title="보험회사 선택" placeholder="선택하세요" closeBtn scrolling/>
                      </ur-box-container>
                      <div class="crTy-orange2" v-show="isMdexpAcpnVicaInscCd">서류를 보내고자하는 회사를 하나 이상 선택 바랍니다.</div>
                    </div>
                  </ur-box-container>
                  <!-- 실손의료비 청구서류 접수대행신청 '예' 선택시 :  중복 가입된 모든회사, 서류를 보내고자 하는 회사 표출 //-->
                </div>

              </ur-box-container>                    
            </template>
          </msp-expand>
        </mo-list-item> 
        <!-- 사고 상세내용 끝  -->
      </mo-validate-watcher>

    </ur-box-container>
    <!--  리스트 E -->
  </ur-box-container>
  <!-- 컨텐츠영역  //-->
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import MSPCM013P from '@/ui/cm/MSPCM013P' // 보험직업조회

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPBU041D",
  screenId: "MSPBU041D",
  components: {
    MSPCM013P
  },
  props: {
    customerType: {type: String},
    step1Data: {type: Object}, //조회 된 고객 정보
    step3Data: {type: Object}, //조회 된 고객 정보
    backupData: {type: Object}, //다른 화면 갔다 온 경우 이전 입력 했던 값 재 셋팅용.
  },

  watch: {
    /**
     * @description 청구유형 변경
     * @param {String} 1:정액, 2:실손, 3:정액+실손
     */
    'claimData.acdtAcpnObjScCd': function(value){
      if( value === '1' ){
        //정액 선택 시에는 실손 입원과 통원을 초기화 하고 비활성 상태로 변경.
        this.claimData.rsnMdhsp[6] = '' //실손입원
        this.claimData.rsnMdhsp[7] = '' //실손통원
      }
      this.isAcdtAcpnObjScCd = this.$bizUtil.isEmpty(value)
    },

    'claimData.diseDstrScCd': function(value){
      this.isDiseDstrScCd = this.$bizUtil.isEmpty(value) //발생원인
    },
    'claimData.amount': function(value){
      this.isAmount = this.$bizUtil.isEmpty(value) //청구금액
    },
    'claimData.mdcrRcbfr': function(value){
      if( this.claimData.acdtAcpnObjScCd === '2' || this.claimData.acdtAcpnObjScCd === '3' ){
        this.isMdcrRcbfr = this.$bizUtil.isEmpty(value) //의료수급권자
      }
    },
    'claimData.rsnMdhsp': function(value){
      this.isRsnMdhsp = this.$bizUtil.isEmpty(value.join('')) //청구사유
      if( !this.isNotEmpty(this.claimData.rsnMdhsp[6]) && !this.isNotEmpty(this.claimData.rsnMdhsp[7]) ){
        this.contentData.sinSonEntYn = ''
        this.fn_sinSonEntYn('')
      }
    },
    'claimData.tiRsnEtc': function(value){
      this.isTiRsnEtc = this.$bizUtil.isEmpty(value) //청구사유 > 기타 > 기타내용
    },
    'claimData.tiDsnm': function(value){
      this.isTiDsnm = this.$bizUtil.isEmpty(value) //청구사유 > 실손통원 > 병명
    },

    'contentData.adrnDate': function(value){
      this.isAdrnDateTime = this.$bizUtil.isEmpty(value) || this.$bizUtil.isEmpty(this.contentData.adrnTime) //사고일자
      this.fn_SetArdnTime() // 현재 날짜와 시간에 따른 시간 목록 설정
    },
    'contentData.adrnTime': function(value){
      this.isAdrnDateTime = this.$bizUtil.isEmpty(this.contentData.adrnDate) || this.$bizUtil.isEmpty(value) //사고일자
    },
    'contentData.cstiCntry': function(value){
      this.isCstiCntry = this.$bizUtil.isEmpty(value) //사고국가
    },
    'contentData.cstiAcdt': function(value){
      if( this.isRequired ){
        this.isCstiAcdt = this.$bizUtil.isEmpty(value) //사고경위
      }
    },
    'contentData.tiAcdtDtl': function(value){
      if( this.isRequired ){
        this.isTiAcdtDtl = this.$bizUtil.isEmpty(value) //상세내용
      }
    },
    'contentData.rbgAtcarInsrYn': function(value){
      this.isRbgAtcarInsrYn = this.$bizUtil.isEmpty(value) //자동차보험/산재보험처리여부
    },
    'contentData.sinSonEntYn': function(value){
      if( this.isNotEmpty(this.claimData.rsnMdhsp[6]) || this.isNotEmpty(this.claimData.rsnMdhsp[7]) ){
        this.isSinSonEntYn = this.$bizUtil.isEmpty(value) //실손가입여부(단체실손포함)
      }
    },
    'contentData.mdexpAcpnVicaYn': function(value){
      if( this.contentData.sinSonEntYn === 'Y' && (this.claimData.acdtAcpnObjScCd === '2' || this.claimData.acdtAcpnObjScCd === '3') ){
        this.isMdexpAcpnVicaYn = this.$bizUtil.isEmpty(value) //실손의료비 청구서류 접수대행신청
      }
    },
    'contentData.mdexpAcpnVicaAllDuplicateCoYn': function(value){
      if( this.contentData.mdexpAcpnVicaYn === 'Y' ){
        this.isMdexpAcpnVicaAllDuplicateCoYn = this.$bizUtil.isEmpty(value) //중복 가입된 모든 회사
      }
    },
    'contentData.mdexpAcpnVicaInscCd1': function(value){
      if( this.contentData.mdexpAcpnVicaAllDuplicateCoYn === 'N' ){
        this.isMdexpAcpnVicaInscCd = this.$bizUtil.isEmpty(value)
      }
    },
    'contentData.mdexpAcpnVicaInscCd2': function(value){
      if( this.contentData.mdexpAcpnVicaAllDuplicateCoYn === 'N' ){
        this.isMdexpAcpnVicaInscCd = this.$bizUtil.isEmpty(value)
      }
    },
    'contentData.mdexpAcpnVicaInscCd3': function(value){
      if( this.contentData.mdexpAcpnVicaAllDuplicateCoYn === 'N' ){
        this.isMdexpAcpnVicaInscCd = this.$bizUtil.isEmpty(value)
      }
    },

    contentData: {
      handler(after, before) {
        this.$bcUtil.setDataBySrnIdInLocalStorage(this)
      },
      deep: true
    },

    claimData: {
      handler(after, before) {
        this.$bcUtil.setDataBySrnIdInLocalStorage(this)
      },
      deep: true
    },


  },


  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    try {
      this.custList.push(this.$props.step1Data.insured) //피보험자

      if( this.$props.customerType !== 'SELF' ){
        this.custList.push(this.$props.step1Data.beneficiary) //피보험자
      }

      this.fn_GetCommonCode() //공통코드
      this.currentDate = new Date().toISOString().substring(0, 10) //현재일        

      this.fn_SetArdnTime() // 현재 날짜와 시간에 따른 시간 목록 설정      

      //실손에 가입되어있지 않으면, 청구유형은 정액으로 고정 되고, 비활성화 된다.
      if( this.$props.step3Data.rldmCnt === 0 ){
        this.claimData.acdtAcpnObjScCd = '1'
      }
    } catch (e) {
      let isAppCrashAlert = this.getStore('bsStore').getters.getState.isAppCrashAlert // appCrash alert 여부        
      if (isAppCrashAlert) alert(`앱이 비정상 종료하였습니다. : ${e.name} : ${e.message}`)
    }
  },

  mounted() {
    try {
      //다른 스텝으로 이동 했다가 되돌아 오는 경우, 입력 했던 값 재 셋팅.
      if( this.isNotEmpty(this.$props.backupData.jobData) ){
        this.jobData = this.$props.backupData.jobData
        this.claimData = this.$props.backupData.claimData
        this.contentData = this.$props.backupData.contentData
        this.fn_resetOtcomIsrncCo(this.contentData.resetOtcomIsrncCo)
        this.fn_sinSonEntYn(this.contentData.sinSonEntYn)
        this.fn_mdexpAcpnVicaYn(this.contentData.mdexpAcpnVicaYn)
        this.fn_mdexpAcpnVicaAllDuplicateCoYn(this.contentData.mdexpAcpnVicaAllDuplicateCoYn)

        //발생원인 값이 있으면
        if( this.isNotEmpty(this.claimData.diseDstrScCd) ){
          //사고경위 셋팅
          let acdtClsfCd = this.getStore('code').getters.getState.get('ACDT_CLSF_CD')
          if( acdtClsfCd ){
            
            this.cmcd.ACDT_CLSF_CD.splice(0) //코드 초기화

            let lv_Vm = this
            acdtClsfCd.forEach((item) => {
              //상위코드 (item.uppCdVal) 가 발생원인 (claimData.diseDstrScCd) 같고, 익사 인 경우는 추가 하지 않음.
              if( item.uppCdVal === lv_Vm.claimData.diseDstrScCd && item.cdVal !== 'D0500' ){ // D0500 익사는 사망에 해당됨으로 제외
                lv_Vm.cmcd.ACDT_CLSF_CD.push({value: item.cdVal, text: item.cdValNm})
              }
            })
          }
        }
      }
      // if( this.$commonUtil.getIsIOS() ){ // IOS에서만 스크롤 고정 현상이 있었으나 Android에서도 나타남에 따라 조건 삭제
        this.$refs.step4Top.scrollIntoView()
      // }

      this.$bcUtil.getDataBySrnIdInLocalStorage(this)
    } catch (e) {
      let isAppCrashAlert = this.getStore('bsStore').getters.getState.isAppCrashAlert // appCrash alert 여부        
      if (isAppCrashAlert) alert(`앱이 비정상 종료하였습니다. : ${e.name} : ${e.message}`)
    }
  },
  destroyed() {
    this.$bcUtil.setDataBySrnIdInLocalStorage(this)
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      validRequired:[
        (v) => !!v || '필수입력항목입니다.'
      ],

      custList: [], //고객정보
      currentDate: '',

      isAcdtAcpnObjScCd: false, //청구유형 입력값 확인
      isDiseDstrScCd: false, //발생원인 입력값 확인
      isAmount: false, //청구금액
      isMdcrRcbfr: false, //의료수급권자
      isRsnMdhsp: false, //청구사유
      isTiRsnEtc: false, //청구사유 > 기타 > 기타내용
      isTiDsnm: false, //청구사유 > 실손통원 > 병명
      isAdrnDateTime: false, //사고일자
      isCstiCntry: false, //사고국가
      isCstiAcdt: false, //사고경위
      isTiAcdtDtl: false, //상세내용
      isRbgAtcarInsrYn: false, //자동차보험/산재보험처리여부
      isSinSonEntYn: false, //사고내용 > 타사실손가입여부(단체실손포함)
      isMdexpAcpnVicaYn: false, //사고내용 > 타사실손가입여부(단체실손포함) > 실손의료비 청구서류 접수대행신청
      isMdexpAcpnVicaAllDuplicateCoYn: false, //사고내용 > 타사실손가입여부(단체실손포함) > 실손의료비 청구서류 접수대행신청 > 중복 가입된 모든 회사
      isMdexpAcpnVicaInscCd: false, //사고내용 > 타사실손가입여부(단체실손포함) > 실손의료비 청구서류 접수대행신청 > 중복 가입된 모든 회사 > 서류를 보내고자하는 회사

      //직업
      jobData: {
        jobpNm: '', // 직장명
        work: '', // 하시는 일
        jobCd: '', // 보험직업코드
        jobCdNm: '', // 보험직업명
      },

      //청구세부내용
      claimData: {
        acdtAcpnObjScCd: '', //청구유형 1: 정액, 2: 실손, 3: 정액+실손
        diseDstrScCd: '', // 발생원인 1: 질병, 2: 재해
        amount: '', // 청구금액 1: 100만원이하, 2: 100~500만원 이하, 3: 500만원초과
        mdcrRcbfr: '', // 의료수급권자 Y: 예, N: 아니오
        rsnMdhsp: ['', '', '', '', '', '', '', '', ''], // 청구사유
        tiRsnEtc: '', // 청구기타
        tiDsnm: '', // 병명 
      },

      //사고 상세내용 시작
      contentData: {
        adrnDate: new Date().toISOString().substring(0, 10), // 사고일자
        adrnTime: '', // 사고일시
        cstiCntry: 'KR', // 사고국가
        cntryCdNmSign: '한국',// 사고국가명
        cstiAcdt: '', // 사고경위
        tiAcdtDtl: '', // 상세내용
        rbgAtcarInsrYn: '', // 자동차보험/산재보험처리여부
        resetOtcomIsrncCo: 'N', // 타사가입여부
        cstiIsrn: '', // 보험회사
        sinSonEntYn: '', // 실손가입여부(단체실손포함)
        mdexpAcpnVicaYn: '', // 실손의료비 청구서류 접수대행신청
        mdexpAcpnVicaAllDuplicateCoYn: '', // 중복 가입된 모든 회사
        mdexpAcpnVicaInscCd1: '', // 접수대행 보험회사1코드
        mdexpAcpnVicaInscCd2: '', // 접수대행 보험회사2코드
        mdexpAcpnVicaInscCd3: '', // 접수대행 보험회사3코드
        mdexpAcpnVicaInscNm1: '', // 접수대행 보험회사1명
        mdexpAcpnVicaInscNm2: '', // 접수대행 보험회사2명
        mdexpAcpnVicaInscNm3: '', // 접수대행 보험회사3명
      },

      //공통코드
      cmcd : {
        ZA_MOBSL_CNTRY_CD: [], //사고국가
        ISRNC_CD: [], //타보험회사
        ACDT_CLSF_CD: [], //사고경위
      },

      times: [],
    }
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {

    amountMessage(){
      let checkItem = this.claimData.rsnMdhsp.filter((item)=> item === '01' || item === '08').length > 0 // 청구사유 사망(01) or 장해(08)
      if( checkItem || (this.claimData.acdtAcpnObjScCd !== '2' && this.claimData.amount === '03')){ // 실손 제외 추가
        this.isAmount = true
        return '청구에 필요한 구비 서류는 원본 서류가 필요합니다. (구비서류 촬영 불가)'
      } else if( this.$bizUtil.isEmpty(this.claimData.amount) ) {
        return '청구금액을 선택 바랍니다.'
      }
    },

    /**
     * @description ((청구유형:실손 || 청구유형:정액+질병) && 발생원인:질병) || 발생원인:재해  일때 ==> 사고경위 및 상세내용은 필수 입력
     */
    isRequired(){
      return ((this.claimData.acdtAcpnObjScCd === '2' || this.claimData.acdtAcpnObjScCd === '3') && this.claimData.diseDstrScCd === '1') ||
             this.claimData.diseDstrScCd === '2'
    },
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    fn_test(value) {
      if( this.contentData.rbgAtcarInsrYn === value ){
        this.contentData.rbgAtcarInsrYn = ''
      }
    },

    /**
     * @description 현재 날짜와 시간에 따른 시간 목록 설정
     * - 현재 일자 전 일 경우, 23 시 까지 시간 설정 가능
     */
    fn_SetArdnTime() {
      try {
        this.times = []
        if(this.$bizUtil.isEmpty(this.currentDate) || this.$bizUtil.isEmpty(this.contentData.adrnDate))
          return

        let currentHour = this.currentDate === this.contentData.adrnDate ? new Date().getHours() : 23
        for( let hour=0; hour<=currentHour; hour++ ){
          this.times.push({value: String(hour).padStart(2, '0'), text: String(hour).padStart(2, '0')})
        }
      } catch (e) {
        let isAppCrashAlert = this.getStore('bsStore').getters.getState.isAppCrashAlert // appCrash alert 여부        
        if (isAppCrashAlert) alert(`앱이 비정상 종료하였습니다. : ${e.name} : ${e.message}`)
      }
    },

    /**
     * @description 고객이 FC 인 경우, 청구사유의 입원을 선택 하면, 안내되는 메시지
     */
    fn_InfoMessage(value){
      if( this.$props.step3Data.fcCnfYn === 'Y' && value === '03' ){
        this.claimData.rsnMdhsp[4] = ''
        this.getStore('confirm').dispatch('ADD', 'FC본인 입원 청구건은 본인소속 지점에서만 가능합니다')
      }

    },


    /**
     * @description 청구유형 변경시 실손가입여부 리셋 및 필수입력안내 컨트롤
     */
    fn_acdtAcpnObjScCd(){
      try {
        if( !this.isRequired ){
          this.isCstiAcdt = false //사고경위 필수입력 안내 제거
          this.isTiAcdtDtl = false //상세내용 필수입력 안내 제거
        }

        this.fn_sinSonEntYn(this.contentData.sinSonEntYn)
      } catch (e) {
        let isAppCrashAlert = this.getStore('bsStore').getters.getState.isAppCrashAlert // appCrash alert 여부        
        if (isAppCrashAlert) alert(`앱이 비정상 종료하였습니다. : ${e.name} : ${e.message}`)
      }
    },

    /**
     * @description 타사가입
     * @param {String} value Y: 예, N: 아니오
     */
    fn_resetOtcomIsrncCo(value){
      try {
        if( value === 'Y' ){
          this.$refs.cstiIsrnContainer.$el.style.display = 'block'
        } else {
          this.isSinSonEntYn = false
          this.$refs.cstiIsrnContainer.$el.style.display = 'none'
          this.contentData.cstiIsrn = ''
          this.contentData.sinSonEntYn = ''
          this.fn_sinSonEntYn('N')
        }
      } catch (e) {
        let isAppCrashAlert = this.getStore('bsStore').getters.getState.isAppCrashAlert // appCrash alert 여부        
        if (isAppCrashAlert) alert(`앱이 비정상 종료하였습니다. : ${e.name} : ${e.message}`)
      }
    },

    /**
     * @description 실손가입여부(단체실손포함)
     * @param {String} value Y: 예, N: 아니오
     */
    fn_sinSonEntYn(value){
      try {  
        //실손가입여부 예 선택 이고, 청구유형이 실손 또는 정액+실손인 경우
        if( value === 'Y' && (this.claimData.acdtAcpnObjScCd === '2' || this.claimData.acdtAcpnObjScCd === '3') ){
          //실손의료비 청구서류 접수대행신청 노출
          this.$refs.mdexpAcpnVicaYnContainer.$el.style.display = 'block'
        } else {
          this.$refs.mdexpAcpnVicaYnContainer.$el.style.display = 'none'
          this.isMdexpAcpnVicaYn = false
          this.contentData.mdexpAcpnVicaYn = ''
          this.fn_mdexpAcpnVicaYn('N')
        }
      } catch (e) {
        let isAppCrashAlert = this.getStore('bsStore').getters.getState.isAppCrashAlert // appCrash alert 여부        
        if (isAppCrashAlert) alert(`앱이 비정상 종료하였습니다. : ${e.name} : ${e.message}`)
      }
    },

    /**
     * @description 실손의료비 청구서류 접수대행신청
     * @param {String} value Y: 예, N: 아니오
     */
    fn_mdexpAcpnVicaYn(value){
      try {
        if( value === 'Y' ){
          this.$refs.mdexpAcpnVicaAllDuplicateCoYnContainer.$el.style.display = 'block'
        } else {
          this.$refs.mdexpAcpnVicaAllDuplicateCoYnContainer.$el.style.display = 'none'
          this.isMdexpAcpnVicaAllDuplicateCoYn = false
          this.contentData.mdexpAcpnVicaAllDuplicateCoYn = ''
          this.fn_mdexpAcpnVicaAllDuplicateCoYn('Y')
        }
      } catch (e) {
        let isAppCrashAlert = this.getStore('bsStore').getters.getState.isAppCrashAlert // appCrash alert 여부        
        if (isAppCrashAlert) alert(`앱이 비정상 종료하였습니다. : ${e.name} : ${e.message}`)
      }
    },


    /**
     * @description 중복 가입된 모든 회사
     * @param {String} value Y:예, N: 아니오
     */
    fn_mdexpAcpnVicaAllDuplicateCoYn(value){
      try {
        //서류를 보내고자하는 회사 초기화
        if( value === 'Y' ){
          this.$refs.mdexpAcpnVicaInscCdContainer.style.display = 'none'
          this.isMdexpAcpnVicaInscCd = false
          this.contentData.mdexpAcpnVicaInscCd1 = ''
          this.contentData.mdexpAcpnVicaInscCd2 = ''
          this.contentData.mdexpAcpnVicaInscCd3 = ''
          this.contentData.mdexpAcpnVicaInscNm1 = ''
          this.contentData.mdexpAcpnVicaInscNm2 = ''
          this.contentData.mdexpAcpnVicaInscNm3 = ''

        } else {
          this.$refs.mdexpAcpnVicaInscCdContainer.style.display = 'block'
        }
      } catch (e) {
        let isAppCrashAlert = this.getStore('bsStore').getters.getState.isAppCrashAlert // appCrash alert 여부        
        if (isAppCrashAlert) alert(`앱이 비정상 종료하였습니다. : ${e.name} : ${e.message}`)
      }
    },

    /**
     * @description 사고국가명
     */
    selectedItemCstiCntry(item){
      this.contentData.cntryCdNmSign = item.label
    },

    /**
     * @description 타보험회사 선택 시 회사명 셋팅.
     */
    selectedItemIsrncCd1(item){
      this.contentData.mdexpAcpnVicaInscNm1 = item.label
    },
    selectedItemIsrncCd2(item){
      this.contentData.mdexpAcpnVicaInscNm2 = item.label
    },
    selectedItemIsrncCd3(item){
      this.contentData.mdexpAcpnVicaInscNm3 = item.label
    },


    /**
     * @description check empty
     */
    isNotEmpty(value){
      return !this.$bizUtil.isEmpty(value)
    },

    /**
     * @description 발생원인 변경
     */
    fn_DiseDstrScCdChange(value){
      try {
        //사고경위 셋팅
        let acdtClsfCd = this.getStore('code').getters.getState.get('ACDT_CLSF_CD')
        if( acdtClsfCd ){
          
          this.cmcd.ACDT_CLSF_CD.splice(0) //코드 초기화
          this.contentData.cstiAcdt = '' //사고경위 초기화

          let lv_Vm = this
          acdtClsfCd.forEach((item) => {
            //상위코드 (item.uppCdVal) 가 발생원인 (claimData.diseDstrScCd) 같고, 익사 인 경우는 추가 하지 않음.
            if( item.uppCdVal === value && item.cdVal !== 'D0500' ){ // D0500 익사는 사망에 해당됨으로 제외
              
              if( item.cdVal === 'I9900' ){ //기타질환 기본셋팅
                lv_Vm.contentData.cstiAcdt = item.cdVal
              }

              lv_Vm.cmcd.ACDT_CLSF_CD.push({value: item.cdVal, text: item.cdValNm})
            }
          })
        }
      } catch (e) {
        let isAppCrashAlert = this.getStore('bsStore').getters.getState.isAppCrashAlert // appCrash alert 여부        
        if (isAppCrashAlert) alert(`앱이 비정상 종료하였습니다. : ${e.name} : ${e.message}`)
      }
    },


    /**
     * @description 직업 조회 팝업
     */
    fn_OpenMSPCM013P(){
      try {
        let lv_Vm = this
        lv_Vm.popup013 = this.$bottomModal.open(MSPCM013P, {
          properties: {
            pJobCdNm : lv_Vm.jobCdNm
          },
          listeners: {
            confirmPopup: (pData) => {
              lv_Vm.jobData.jobCd = pData.jobCd
              lv_Vm.jobData.jobCdNm = pData.jobCdNm
              lv_Vm.$bottomModal.close(lv_Vm.popup013)
            },
            cancelPopup: () => {
              lv_Vm.$bottomModal.close(lv_Vm.popup013)
            }
          }
        })
      } catch (e) {
        let isAppCrashAlert = this.getStore('bsStore').getters.getState.isAppCrashAlert // appCrash alert 여부        
        if (isAppCrashAlert) alert(`앱이 비정상 종료하였습니다. : ${e.name} : ${e.message}`)
      }
    },


    /**
     * @description 공통코드 조회
     */
    fn_GetCommonCode() {
      try {
        var lv_Vm = this

        //EA 계 공통코드 (단)건 조회
        this.$commonUtil.selListEACommCd('ZA_MOBSL_CNTRY_CD').then(function () {
          lv_Vm.cmcd.ZA_MOBSL_CNTRY_CD = lv_Vm.getStore('code').getters.getState.get('ZA_MOBSL_CNTRY_CD')

        })


        //Sli 계 공통코드 (다)건 조회
        this.$commonUtil.selListMassSliCommCd(['ACDT_CLSF_CD', 'ISRNC_CD']).then(function() {
          //타보험회사
          lv_Vm.cmcd.ISRNC_CD = lv_Vm.getStore('code').getters.getState.get('ISRNC_CD')

          //사고경위 : ACDT_CLSF_CD 는 발생원인이 변경 될 때 마다, 필터 되어 사고경위에 셋팅이 된다.
        })
      } catch (e) {
        let isAppCrashAlert = this.getStore('bsStore').getters.getState.isAppCrashAlert // appCrash alert 여부        
        if (isAppCrashAlert) alert(`앱이 비정상 종료하였습니다. : ${e.name} : ${e.message}`)
      }
    },


    /**
     * @description 청구서작성 step 입력값 체크, MSPBU001M 에서 다음 버튼 클릭시 fn_Next 에서 currentStep 가 4 일때 호출 됨.
     * @returns {Boolean} true 입력값 오류, false 입력값 정상
     */
    fn_validation(){
      try {
        //직업정보
        // let isJobInfo = !this.$refs.jobWatcher.isValid()
        //청구세부내용 모두 입력시 접기
        this.isAcdtAcpnObjScCd = this.$bizUtil.isEmpty(this.claimData.acdtAcpnObjScCd) //청구유형
        this.isDiseDstrScCd = this.$bizUtil.isEmpty(this.claimData.diseDstrScCd) //발생원인
        this.isAmount = this.$bizUtil.isEmpty(this.claimData.amount) //청구금액
        
        //청구유형이 실손을 포함 할 때 의료수급권자 필수체크
        if( this.claimData.acdtAcpnObjScCd === '2' || this.claimData.acdtAcpnObjScCd === '3' ){
          this.isMdcrRcbfr = this.$bizUtil.isEmpty(this.claimData.mdcrRcbfr) //의료수급권자
        }
        
        this.isRsnMdhsp = this.$bizUtil.isEmpty(this.claimData.rsnMdhsp.join('')) //청구사유
        this.isTiRsnEtc = this.isNotEmpty(this.claimData.rsnMdhsp[8]) && this.$bizUtil.isEmpty(this.claimData.tiRsnEtc) //청구사유 > 기타 > 기타내용
        this.isTiDsnm = this.isNotEmpty(this.claimData.rsnMdhsp[7]) && this.$bizUtil.isEmpty(this.claimData.tiDsnm) //청구사유 > 실손통원 > 병명

        //사고 상세내용 여부
        this.isAdrnDateTime = this.$bizUtil.isEmpty(this.contentData.adrnDate) //사고일자
        this.isCstiCntry = this.$bizUtil.isEmpty(this.contentData.cstiCntry) //사고국가

        //( 청구유형(실손||정액+실손) && 발생원인(질병) ) || 발생원인(재해) 인 경우에만, 사고내용 및 타사가입여부 체크함
        if( this.isRequired ){
          this.isCstiAcdt = this.$bizUtil.isEmpty(this.contentData.cstiAcdt) //사고경위
          this.isTiAcdtDtl = this.$bizUtil.isEmpty(this.contentData.tiAcdtDtl) //상세내용
        }

        //this.isRbgAtcarInsrYn = this.$bizUtil.isEmpty(this.contentData.rbgAtcarInsrYn) //자동차보험/산재보험처리여부

        // 청구사유가 실손입원 || 실손통원 인 경우에만, 타사실손가입여부 체크함
        if (this.claimData.acdtAcpnObjScCd === '2' || this.claimData.acdtAcpnObjScCd === '3') { // 청구유형 : (실손 || 정액+실손)
          if( this.isNotEmpty(this.claimData.rsnMdhsp[6]) || this.isNotEmpty(this.claimData.rsnMdhsp[7]) ){
            this.isSinSonEntYn = this.$bizUtil.isEmpty(this.contentData.sinSonEntYn) //실손가입여부(단체실손포함)
          }
        }



        //타사가입여부가 예 이면 하위 필드 체크
        if( this.contentData.resetOtcomIsrncCo === 'Y' ){
          this.isSinSonEntYn = this.$bizUtil.isEmpty(this.contentData.sinSonEntYn) //실손가입여부(단체실손포함)

          //실손가입여부(단체실손포함) : Y && 청구유형 : (실손 || 정액+실손)
          if( this.contentData.sinSonEntYn === 'Y' && (this.claimData.acdtAcpnObjScCd === '2' || this.claimData.acdtAcpnObjScCd === '3') ){

            this.isMdexpAcpnVicaYn = this.$bizUtil.isEmpty(this.contentData.mdexpAcpnVicaYn) //실손의료비 청구서류 접수대행신청

            //실손의료비 청구서류 접수대행신청이 예 이면 하위 필드 체크
            if( this.contentData.mdexpAcpnVicaYn === 'Y' ){
              this.isMdexpAcpnVicaAllDuplicateCoYn = this.$bizUtil.isEmpty(this.contentData.mdexpAcpnVicaAllDuplicateCoYn) //중복 가입된 모든 회사

              //중복 가입된 모든 회사가 아니오 이면 하위 필드 체크
              if( this.contentData.mdexpAcpnVicaAllDuplicateCoYn === 'N' ){
                //서류를 보내고자하는 회사
                this.isMdexpAcpnVicaInscCd =  this.$bizUtil.isEmpty(this.contentData.mdexpAcpnVicaInscCd1) &&
                                              this.$bizUtil.isEmpty(this.contentData.mdexpAcpnVicaInscCd2) &&
                                              this.$bizUtil.isEmpty(this.contentData.mdexpAcpnVicaInscCd3)
              }
            }
          }
        }


        if( this.isAcdtAcpnObjScCd || this.isDiseDstrScCd || this.isAmount || this.isMdcrRcbfr || this.isRsnMdhsp || this.isTiRsnEtc || this.isTiDsnm ) {
          this.$refs.listItem2.isExpand = true
        } else if( this.isAdrnDateTime || this.isCstiCntry || this.isCstiAcdt || this.isTiAcdtDtl || this.isRbgAtcarInsrYn ||
                  this.isSinSonEntYn || this.isMdexpAcpnVicaYn || this.isMdexpAcpnVicaAllDuplicateCoYn || this.isMdexpAcpnVicaInscCd ){
          this.$refs.listItem3.isExpand = true
        }

        if( this.isAcdtAcpnObjScCd ){
          this.$refs.acdtAcpnObjScCd.scrollIntoView()
        } else if( this.isDiseDstrScCd ){
          this.$refs.diseDstrScCd.scrollIntoView()
        } else if( this.isAmount ){
          this.$refs.amount.scrollIntoView()
        } else if( this.isMdcrRcbfr ){
          this.$refs.mdcrRcbfr.scrollIntoView()
        } else if( this.isRsnMdhsp ){
          this.$refs.rsnMdhsp.scrollIntoView()
        } else if( this.isTiRsnEtc ){
          this.$refs.tiRsnEtc.scrollIntoView()
        } else if( this.isTiDsnm ){
          this.$refs.tiDsnm.scrollIntoView()
        } else if( this.isAdrnDateTime ){
          this.$refs.adrnDateTime.scrollIntoView() //사고일자 사고일시
        } else if( this.isCstiCntry ){
          this.$refs.cstiCntry.scrollIntoView() //사고국가
        } else if( this.isCstiAcdt ){
          this.$refs.cstiAcdt.scrollIntoView() //사고경위
        } else if( this.isTiAcdtDtl ){
          this.$refs.tiAcdtDtl.scrollIntoView() //사고상세
        } else if( this.isRbgAtcarInsrYn ){
          this.$refs.rbgAtcarInsrYn.scrollIntoView() //자동차보험/산재보험처리여부
        } else if( this.isSinSonEntYn ){
          this.$refs.sinSonEntYn.scrollIntoView() //사고내용 > 타사실손가입여부(단체실손포함)
        } else if( this.isMdexpAcpnVicaYn ){
          this.$refs.mdexpAcpnVicaYn.scrollIntoView() //사고내용 > 타사실손가입여부(단체실손포함) > 실손의료비 청구서류 접수대행신청
        } else if( this.isMdexpAcpnVicaAllDuplicateCoYn ){
          this.$refs.mdexpAcpnVicaAllDuplicateCoYn.scrollIntoView() //사고내용 > 타사실손가입여부(단체실손포함) > 실손의료비 청구서류 접수대행신청 > 중복 가입된 모든 회사
        } else if( this.isMdexpAcpnVicaInscCd ){
          this.$refs.mdexpAcpnVicaInscCd.scrollIntoView() //사고내용 > 타사실손가입여부(단체실손포함) > 실손의료비 청구서류 접수대행신청 > 중복 가입된 모든 회사
        }

        return  this.isAcdtAcpnObjScCd ||  //청구유형 입력값 확인
                this.isDiseDstrScCd ||  //발생원인 입력값 확인
                this.isAmount ||  //청구금액
                this.isMdcrRcbfr ||  //의료수급권자
                this.isRsnMdhsp ||  //청구사유
                this.isTiRsnEtc ||  //청구사유 > 기타 > 기타내용
                this.isTiDsnm ||  //청구사유 > 실손통원 > 병명
                this.isAdrnDateTime ||  //사고일자
                this.isCstiCntry ||  //사고국가
                this.isCstiAcdt ||  //사고경위
                this.isTiAcdtDtl ||  //상세내용
                this.isRbgAtcarInsrYn ||  //자동차보험/산재보험처리여부
                this.isSinSonEntYn ||  //사고내용 > 타사실손가입여부(단체실손포함)
                this.isMdexpAcpnVicaYn ||  //사고내용 > 타사실손가입여부(단체실손포함) > 실손의료비 청구서류 접수대행신청
                this.isMdexpAcpnVicaAllDuplicateCoYn ||  //사고내용 > 타사실손가입여부(단체실손포함) > 실손의료비 청구서류 접수대행신청 > 중복 가입된 모든 회사
                this.isMdexpAcpnVicaInscCd //사고내용 > 타사실손가입여부(단체실손포함) > 실손의료비 청구서류 접수대행신청 > 중복 가입된 모든 회사 > 서류를 보내고자하는 회사
      } catch (e) {
        let isAppCrashAlert = this.getStore('bsStore').getters.getState.isAppCrashAlert // appCrash alert 여부        
        if (isAppCrashAlert) alert(`앱이 비정상 종료하였습니다. : ${e.name} : ${e.message}`)
      }
    },
  }
};
</script>
<style scoped>
.custom-container {
  display: none;
}
</style>