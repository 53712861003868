<template>
  <ur-page-container class="msp" title="새로운계좌추가" :show-title="true" type="popup"> <!-- type="popup" -->
    <!-- 컨텐츠 영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-pm-wrap">
      <ur-box-container alignV="start" componentid="" direction="column"  class="ns-add-area" ref="newAccountForm">
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box mt30">
          <div class="info-title-wrap">
            <span class="contain-title must mb0">금융기관</span>
          </div>
          <mo-text-field v-model="newAccountInfo.bnkCdNm" @blur="fn_blur('isShowBankNameMsg', newAccountInfo.bnkCdNm)" @click="open" class="form-input-name" underline placeholder="선택하세요"/>
          <div class="crTy-orange2" v-show="isShowBankNameMsg">금융기관을 선택 바랍니다.</div>
        </ur-box-container>
        
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box mt50">
          <div class="info-title-wrap">
            <span class="contain-title must mb0">계좌번호</span>
          </div>
          <mo-text-field v-model="newAccountInfo.accnNo" @blur="fn_blur('isShowAccontNoMsg', newAccountInfo.accnNo)" maxlength="30" type="number" class="form-input-name" underline placeholder="계좌번호를 입력하세요"/>
          <div class="crTy-orange2" v-show="isShowAccontNoMsg">계좌번호를 입력 바랍니다.</div>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box mt50">
          <div class="info-title-wrap">
            <span class="contain-title must mb0">예금주명</span>
          </div>
          <mo-text-field v-model="newAccountInfo.custNm" @blur="fn_blur('isShowCustNmMsg', newAccountInfo.custNm)" class="form-input-name" maxlength="30" underline placeholder="예금주명을 입력하세요"/>
          <div class="crTy-orange2" v-show="isShowCustNmMsg">예금주명을 입력 바랍니다.</div>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box mt50">
          <div class="info-title-wrap">
            <span class="contain-title must mb0">예금주 주민등록번호</span>
          </div>
          <div class="verify-birth">
            <mo-text-field v-model="newAccountInfo.knbFrno" mask="######" @blur="fn_blur('isShowRrnMsg', newAccountInfo.knbFrno)" type="number" maxlength="6" underline class="birth-input" placeholder="주민등록번호"/>
            <span class="hyphen ml10 mr10"></span>
            <m-trans-key-input v-if="$commonUtil.isMobile()" v-model="newAccountInfo.knbBknoCipher" @blur="fn_blur('isShowRrnMsg', newAccountInfo.knbBknoCipher)" :type="'numberMax7'" :start="'1'" :end="'-1'" @mask-type="fn_getMaskType" class="birth-input3"/>
            <mo-text-field v-else type="number" mask="#######" v-model="newAccountInfo.knbBkno" @blur="fn_blur('isShowRrnMsg', newAccountInfo.knbBkno)" password underline maxlength="7" class="birth-input no-ic"/>
          </div>
          <div class="crTy-orange2" v-show="isShowRrnMsg">주민등록번호를 입력 바랍니다.</div>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box mt50 mb80">
          <div class="info-title-wrap mb10">
            <span class="contain-title must mb0">예금주 체크</span>
          </div>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
            <mo-segment-wrapper solid primary class="chip-type-wrap" v-model="newAccountInfo.authType" @blur="fn_blur('isShowAuthTypeMsg', newAccountInfo.authType)" @input="isShowAuthTypeMsg = false">
              <mo-segment-button value="Y">체크함</mo-segment-button>
              <mo-segment-button value="N">체크안함</mo-segment-button>
            </mo-segment-wrapper>
            <div class="crTy-orange2" v-show="isShowAuthTypeMsg">예금주 체크를 선택 바랍니다.</div>
          </ur-box-container>
        </ur-box-container>
      </ur-box-container>
      <!-- 하단 버튼영역 -->
      <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <mo-button componentid="mo_button_005" color="primary" shape="solid" size="medium" class="ns-btn-round white" @click="fn_close">이전</mo-button>
          <mo-button componentid="mo_button_006" color="normal" shape="border" size="medium" class="ns-btn-round blue" @click="fn_checkInfo">등록</mo-button>
        </div>
      </ur-box-container>
      <!-- 하단 버튼영역 //--> 

      <!-- 바텀시트1 : 금융기관 선택시 금융기관선택 팝업호출 -->
      <mo-bottom-sheet ref="bottomSheet1"  class="ns-bottom-sheet msp-bottomSt-ty4 closebtn height-200">
        <template v-slot:title>금융기관선택</template>
       <div class="ns-btn-close" @click="fn_confirm"></div>  
        <div class="content-area">
           <div class="bottom-fixed">
            <mo-text-field searchable underline placeholder="은행/증권사명을 입력하세요" v-model="searchKey" class="full mt0 mb20"/>
            <!-- 탭메뉴 --> 
            <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="ns-segment">
              <mo-segment-wrapper solid primary v-model="listType">
                <mo-segment-button value="1">은행</mo-segment-button>
                <mo-segment-button value="2">증권사</mo-segment-button>
              </mo-segment-wrapper>
            </ur-box-container>
             <!-- 탭메뉴 -->
          </div>

          <ur-box-container alignV="start" componentid="" direction="column" class="bottom-scroll">
            <!-- 탭1 내용: 은행선택 S -->
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-radio-wrap mt20" v-if="listType === '1'">
              <div class="ns-radio-list">
                <mo-radio v-for="(bank, index) in bankList" :key="index" v-model="newAccountInfo.bnkCd" :value="bank.envCntnt" class="mb20">{{bank.smoUnitl}}</mo-radio>
              </div>
            </ur-box-container>
            <!-- 탭1 내용: 은행선택 E //-->

            <!-- 탭2 내용: 증권사선택 S -->
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-radio-wrap mt20" v-if="listType === '2'">
              <div class="ns-radio-list">
                <mo-radio v-for="(stock, index) in stockList" :key="index" v-model="newAccountInfo.bnkCd" :value="stock.envCntnt" class="mb20">{{stock.smoUnitl}}</mo-radio>
              </div>
            </ur-box-container>
            <!-- 탭2 내용: 증권사선택 E //-->
          </ur-box-container>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs noshadow">
            <div class="relative-div">
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_confirm" name="확인">확인</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>
      <!-- 바텀시트1 : 금융기관 선택시 금융기관선택 팝업호출 //-->    
    </ur-box-container>    
    <!-- 컨텐츠 영역 //-->
  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPBU055P",
  screenId: "MSPBU055P",
  components: {},
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  props:{
    customerType: {type: String},
    step2Data: {type: Array},
    custList: {type: Array},
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    this.fn_bnkCdGetService()
    console.log('step2Data : ', this.$props.step2Data, this.$props.custList)
  },
  mounted() {},
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      isShowBankNameMsg: false, //금융사
      isShowAccontNoMsg: false, //계좌번호
      isShowCustNmMsg: false, //예금주명
      isShowRrnMsg: false, //예금주 주민번호
      isShowAuthTypeMsg: false, //예금주 체크
      listType: '1', //은행 증권사 구분
      orgList: [], //은행코드리스트
      searchKey: '', //조회키
      osType: '', //iphone 인 경우 주민번호 뒷 자리 보안키패드에서 fn_getMaskType 함수에 IOS 값을 넘겨줌.
      newAccountInfo: {
        bnkCd: '',  //선택한 은행코드
        bnkCdNm: '', //선택한 은행명
        accnNo: '', //계좌번호
        knbFrno: '', //주민번호 앞
        knbBkno: '', //주민번호 뒤
        knbBknoCipher: '', //키패드 암호화 된 주민번호 뒷자리
        authType: '', //예금주 체크
        custNm: '', //asis dpstrNm
        custId: '', //asis bpid
        celno: '', //휴대폰
        accnId: '', // 계좌ID
      },
    }
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    /**
     * @description 증권사 리스트
     */
    stockList(){
      try {
        if( this.$bizUtil.isEmpty(this.searchKey) ){
          return this.orgList.filter((item) => item.envCntnt.substring(0, 1) === '2')
        } else {
          return this.orgList.filter((item) => item.envCntnt.substring(0, 1) === '2' && item.smoUnitl.toLowerCase().indexOf(this.searchKey.toLowerCase()) !== -1)
        }
      } catch (e) {
        console.log('app_crash :', e.name , e.message)
        let isAppCrashAlert = this.getStore('bsStore').getters.getState.isAppCrashAlert // appCrash alert 여부        
        if (isAppCrashAlert) alert(`앱이 비정상 종료하였습니다. : ${e.name} : ${e.message}`)
      }
    },

    /**
     * @description 은행 리스트
     */
    bankList(){
      try {
        if( this.$bizUtil.isEmpty(this.searchKey) ){
          return this.orgList.filter((item) => item.envCntnt.substring(0, 1) === '0')
        } else {
          return this.orgList.filter((item) => item.envCntnt.substring(0, 1) === '0' && item.smoUnitl.toLowerCase().indexOf(this.searchKey.toLowerCase()) !== -1)
        }
      } catch (e) {
        console.log('app_crash :', e.name , e.message)
        let isAppCrashAlert = this.getStore('bsStore').getters.getState.isAppCrashAlert // appCrash alert 여부        
        if (isAppCrashAlert) alert(`앱이 비정상 종료하였습니다. : ${e.name} : ${e.message}`)
      }
    },
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /**
     * @description OS가 IOS 인 경우에는 value 가 IOS 라는 string 값이 넘어옴. android 에서는 empty 값이 넘어옴.
     *              서비스에서 IOS 인 경우에는 복호화 모듈이 달라짐. 해당 값으로 판단하여 분기 처리함.
     * @param {String} value ios : IOS, android : empty string
     */
    fn_getMaskType(value) {
      this.osType = value
    },


    isNotEmpty(value){
      return !this.$bizUtil.isEmpty(value)
    },


    fn_blur(fieldId, value){
      console.log('fieldId : ', fieldId)
      this.$data[fieldId] = this.$bizUtil.isEmpty(value)
    },

    /**
     * @description 입력값 확인
     */
    fn_checkInfo(){
      try {

        const knbBkno = this.$commonUtil.isMobile() ? this.newAccountInfo.knbBknoCipher : this.newAccountInfo.knbBkno

        this.isShowBankNameMsg = this.$bizUtil.isEmpty(this.newAccountInfo.bnkCdNm)
        this.isShowAccontNoMsg = this.$bizUtil.isEmpty(this.newAccountInfo.accnNo)
        this.isShowCustNmMsg = this.$bizUtil.isEmpty(this.newAccountInfo.custNm)
        this.isShowRrnMsg = this.$bizUtil.isEmpty(this.newAccountInfo.knbFrno) || this.$bizUtil.isEmpty(knbBkno)
        this.isShowAuthTypeMsg = this.$bizUtil.isEmpty(this.newAccountInfo.authType)

        if( this.isShowBankNameMsg || this.isShowAccontNoMsg || this.isShowCustNmMsg || this.isShowRrnMsg || this.isShowAuthTypeMsg ){
          return
        }

        const authDatas = this.$props.step2Data
        const custDatas = this.$props.custList
        let custIndex = 0

        //for( let index in authDatas ){
        for( let index=0; index<authDatas.length; index++ ){
          //다음에 하기인 경우
          if( authDatas[index].isToLater ){
            continue
          }

          // 어자피 step2Data 와 custList 는 같은 분기문에서 탄생 되는 애들 이기 때문에 index 가 같음.
          // 태블릿 버전에서 모바일인 경우 주번 앞자리로만 비교 하는데, 모바일인 경우 말고는 pc 인 경우일텐데, pc 인 경우 굳이 뒷자리 까지 비교 할 필요가 없을 듯.
          custIndex = this.$props.customerType === 'CHILD' ? 1 : index //자녀인 경우, 본인인증은 계약자만 한다. 자녀의 경우 계약자의 고객정보 index 는 1이다.

          let newRrn = this.newAccountInfo.knbFrno
          let authRrn = custDatas[custIndex].knbFrno

          if( this.newAccountInfo.custNm === custDatas[custIndex].custNm && newRrn === authRrn ){ //이름과 주민번호가 같으면 등록가능
            this.newAccountInfo.custId = custDatas[custIndex].custId
            this.newAccountInfo.celno = custDatas[custIndex].celno
            break

          }

        }

        if( this.isNotEmpty(this.newAccountInfo.custId) ){
          this.fn_insListAccn()

        } else {
          this.getStore('confirm').dispatch('ADD', '본인인증 고객명의 계좌만 등록이 가능 합니다.')
        }
      } catch (e) {
        console.log('app_crash :', e.name , e.message)
        let isAppCrashAlert = this.getStore('bsStore').getters.getState.isAppCrashAlert // appCrash alert 여부        
        if (isAppCrashAlert) alert(`앱이 비정상 종료하였습니다. : ${e.name} : ${e.message}`)
      }

    },


    /**
     * @description 계좌등록
     */
    fn_insListAccn(){
      try {
        let params = {
          custScCd: '1',
          custId: this.newAccountInfo.custId, // 고객BPID
          KnbFrno: this.newAccountInfo.knbFrno,
          KnbBkno: this.newAccountInfo.knbBkno,
          KnbBknoCipher: this.newAccountInfo.knbBknoCipher,
          osType: this.osType,
          isMobile: this.$commonUtil.isMobile(),
          bnkCd: this.newAccountInfo.bnkCd,
          bnkbAccnNo: this.newAccountInfo.accnNo,
          dpstrCnfYn: this.newAccountInfo.authType,
          trtrEno: this.getStore('userInfo').getters.getUserInfo.userEno, // 컨설턴트 번호
          accnTrtPathCd: '01',
          dpstrChkCd: '1',
          trtSystmPathCd: '22', // 처리시스템경로코드
          busnTrtPathCd: '2210' // 업무처리경로코드
        }

        let lv_Vm = this

        this.post(this, params, 'txTSSBU30P2', 'S')
          .then(function (response) {
            // 서버 데이터 가져오기
            if (response.body !== null) {
              if (response.body.custAccnTrtRsltVal === 'S') {
                console.log('new account result : ', response.body)
                lv_Vm.newAccountInfo.accnId = response.body.accnId
                //lv_Vm.newAccountInfo.accnNo = response.body.bnkbAccnNo
                //lv_Vm.newAccountInfo.bnkCd = response.body.bnkCd

                lv_Vm.kakaosmsTrns()

              } else if (response.body.custAccnTrtRsltVal === 'E') {
                lv_Vm.getStore('confirm').dispatch('ADD', response.body.trtRsltCntnt)

              } else {
                lv_Vm.getStore('confirm').dispatch('ADD', '에러가 발생했습니다.')

              }
            } else {
              // 에러 메시지 발생
              if (!lv_Vm.$bizUtil.isEmpty(response.msgComm.msgDesc) && response.msgComm.msgDesc === '본인인증한 고객만 계좌등록이 가능합니다.') {
                lv_Vm.getStore('confirm').dispatch('ADD', lv_Vm.$t('bu')['EBUC025'] )

              } else {
                lv_Vm.getStore('confirm').dispatch('ADD', '에러가 발생했습니다.')

              }
            }
          })
          .catch(function (error) {
            window.vue.error(error)
          })
      } catch (e) {
        console.log('app_crash :', e.name , e.message)
        let isAppCrashAlert = this.getStore('bsStore').getters.getState.isAppCrashAlert // appCrash alert 여부        
        if (isAppCrashAlert) alert(`앱이 비정상 종료하였습니다. : ${e.name} : ${e.message}`)
      }
    },
    
    
    /**
     * @description 계좌 등록 결과 sms 발송
     */
    kakaosmsTrns () {
      try {
        let params = {
          CustNm: this.newAccountInfo.custNm,
          CustId: this.newAccountInfo.custId,
          ReciTelno: this.newAccountInfo.celno,
          KnbFrno: this.newAccountInfo.knbFrno,
          KnbBkno: this.newAccountInfo.knbBkno,
          //KnbBknoCipher: this.knbBknoCipher
        }

        let lv_Vm = this

        this.post(this, params, 'txTSSBU31P1', 'S')
          .then(function (response) {
            // 서버 데이터 가져오기
            if (response.body !== null) {

              if( !lv_Vm.$bizUtil.isEmpty(response.body.linkSucsYn) && response.body.linkSucsYn === 'N' ){
                lv_Vm.getStore('confirm').dispatch('ADD', '계좌는 정상 등록 되었으나 문자 발송시 에러가 발생했습니다.\n[' + response.body.svrTrtRsltCd + ']')
              }

              lv_Vm.$emit('onPopupClose', lv_Vm.newAccountInfo)

            } else {
              // 에러 메시지 발생
              lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)

            }
          })
          .catch(function (error) {
            window.vue.error(error)
          })
      } catch (e) {
        console.log('app_crash :', e.name , e.message)
        let isAppCrashAlert = this.getStore('bsStore').getters.getState.isAppCrashAlert // appCrash alert 여부        
        if (isAppCrashAlert) alert(`앱이 비정상 종료하였습니다. : ${e.name} : ${e.message}`)
      }
    },


    /**
     * @description 이전버튼 팝업닫기
     */
    fn_close(){
      this.$emit('onPopupClose', this.newAccountInfo);
    },


    /**
     * @description 은행코드 조회
     */
    fn_bnkCdGetService(){
      try {
        let lv_Vm = this
        this.post(lv_Vm, {envId: 'PS_BNK_CD'}, 'txTSSAD02S3', 'S')
          .then(function (response) {

            if( response.body !== null ){
              lv_Vm.orgList = response.body.returnDtlList

            } else { // 정상처리가 아닌경우
              lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            }
          })
          .catch(function (error) {
            window.vue.error(error)
          })
      } catch (e) {
        console.log('app_crash :', e.name , e.message)
        let isAppCrashAlert = this.getStore('bsStore').getters.getState.isAppCrashAlert // appCrash alert 여부        
        if (isAppCrashAlert) alert(`앱이 비정상 종료하였습니다. : ${e.name} : ${e.message}`)
      }
    },

    /**
     * @description 금융사 선택 오픈
     */
    open(){
      this.$refs.bottomSheet1.open();
    },

    /**
     * @description 금융기관선택 확인
     */
    fn_confirm(){
      try {
        this.searchKey = ''

        if( this.isNotEmpty(this.newAccountInfo.bnkCd) ){
          this.newAccountInfo.bnkCdNm = this.orgList.filter((item) => item.envCntnt === this.newAccountInfo.bnkCd)[0].smoUnitl
          this.isShowBankNameMsg = false
        } else {
          this.isShowBankNameMsg = true //금융사 선택 안했을 경우
        }

        this.$refs.bottomSheet1.close()
      } catch (e) {
        console.log('app_crash :', e.name , e.message)
        let isAppCrashAlert = this.getStore('bsStore').getters.getState.isAppCrashAlert // appCrash alert 여부        
        if (isAppCrashAlert) alert(`앱이 비정상 종료하였습니다. : ${e.name} : ${e.message}`)
      }
    },
  }
};
</script>
<style scoped>
</style>