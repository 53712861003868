/**
 * <PRE>
 * System Name : 신태블릿 영업지원 시스템 (TSS)
 * Business Name : 사고보험금신청
 * Class Name : BUPostProcessUtil.js
 * Description : 전자서명 후행 업무 처리 유틸
 *               TSS2-MSP-UI-PJT 의 파일은 local 에서 테스트 용도로 진행 될 때만 사용 되는 파일이다.
 *               TSS2-MWS-UI-PJT 의 파일이 실제 운영에서 사용되는 파일. 모청 > 서명 > BUPostProcessUtil.postProcess 호출 되고 callBack 하는 형태.
 * </PRE>
 * @version 1.0
 * @author Copyright (C) 2010 by SAMSUNG SDS co.,Ltd. All right reserved.
 */
import moment from 'moment'

const BUPostProcessUtil = {}

/**
 * @description postProcess 전자서명 후행 프로세스. 전자서명에 싸인을 완료 하고 나서 호출 된다.
 * @param {Object} lv_Vm component instance
 * @param {String} vuchId 전자서명 키 (접수일+피보험자ID)
 * @param {Object} jsonData 접수내용
 * @param {Function} callBack 전자서명 프로세스 callBack
 * @param {Boolean} isOtherSignFinish 수익자상이의 두명다 서명완료 여부. false : 한 명만 서명완료, true : 두 명다 서명완료.
 *                                    (다음과 같이 이해 해도 된다. - 본인, 자녀, 수익자상이의 첫번째 서명자 : false, 다음에하기 서명자 : true)
 */
BUPostProcessUtil.postProcess = function (lv_Vm, vuchId, jsonData, callBack, isOtherSignFinish) {
  // 다음에하기 서명완료
  if (isOtherSignFinish) {
    let params = {
      acdtInsrAplStrYmd: vuchId.substring(0, 8),
      acdtrId: vuchId.substring(8)
    }

    // 접수중단건 이미지 리스트 조회
    lv_Vm.post(lv_Vm, params, 'txTSSBU05S2', 'S')
      .then(function (response) {
        let imgList = response.body.bUAcdtInsmHpgClamSbmtDocuSVO // 첫번째 전자 서명에서 이미지 첨부 하지 않았으면, 이미지는 없을 수도 있음.
        BUPostProcessUtil.fn_ImageMerge(lv_Vm, vuchId, jsonData, callBack, isOtherSignFinish, imgList)
      })
      .catch(function (error) {
        callBack(false, error)
      })
  } else {
    BUPostProcessUtil.fn_ImageMerge(lv_Vm, vuchId, jsonData, callBack, isOtherSignFinish, null)
  }
}

/**
 * @description 이미지 병합
 * @param {Object} lv_Vm component instance
 * @param {String} vuchId 전자서명 키 (접수일+피보험자ID)
 * @param {Object} jsonData 접수내용
 * @param {Function} callBack 전자서명 프로세스 callBack
 * @param {Boolean} isOtherSignFinish 수익자상이의 두명다 서명완료 여부. false : 한 명만 서명완료, true : 두 명다 서명완료.
 *                                    (다음과 같이 이해 해도 된다. - 본인, 자녀, 수익자상이의 첫번째 서명자 : false, 다음에하기 서명자 : true)
 * @param {Array}} imgList 다음에하기로 들어온 경우, 첫번째 서명자가 추가한 구비서류정보를 조회하여, 두번째 서명자의 구비서류정보와 병합하기 위한 데이터.
 */
BUPostProcessUtil.fn_ImageMerge = function (lv_Vm, vuchId, jsonData, callBack, isOtherSignFinish, imgList) {
  let insured = jsonData.PRC_INFO.step1Data.insured
  let isApp = navigator.userAgent && (navigator.userAgent.indexOf('MINI_APP') !== -1) // native 에서 webview 생성시 셋팅 하고 있음. android, ios 동일.
  let photoLength = 0

  console.log('fn_ImageMerge : ', 'jsonData : ', jsonData, 'isOtherSignFinish : ', isOtherSignFinish, 'imgList : ', imgList, 'isApp : ', isApp)

  // 수익자상이의 두번째 서명시도 시 접수중단목록 > 이어하기로 진입하면, FC폰 APP BASE...
  // 접수중단목록 > 알림톡발송 > 고객 폰일 경우에는 WEB BASE...

  // isOtherSignFinish === false 일 때는, 본인|자녀|수익자상이(첫번째) 일 것이기 때문에 이경우는 APP BASE 이고, photoList 에서 꺼냄.
  // isOtherSignFinish === true 일 때는, 수익자상이(두번쨰) 인 경우이고, APP BASE(FC 폰에서 접수중단건 > 이어하기로 진행하는 경우) 일 수도 있고, WEB BASE(고객이 알림톡 메시지로 진행 하는 경우) 일 수도 있음.

  // if( isApp ){ // 앱인경우에만 구비서류추가가 가능 하기때문에 tss-msp-ui 프로젝트의 BUPostProcessUtil.js 는 로컬 pc 테스트 용이기 때문에 isApp 이 무조건 true 를 가정으로 흘러가야 함.
  // 똑같은 파일이 tss_mws-ui (모청) 프로젝트에도 있는데, 거기에서는 isApp 이 실제 native 에서 셋팅된 값이 넘어 오고 있음. 알림톡을 받은 고객폰에서 진행되면 isApp 이 false가 됨.
  if (isOtherSignFinish) { // 수익자상이인 경우 둘다 서명을 완료 한 경우에만 true 가 됨.
    photoLength = jsonData.PRC_INFO.step2PhotoLength
  } else { // 그 외에는 본인 또는 자녀 또는 수익자상이인 경우 1명만 서명이 된 상태가 false 임.
    photoLength = jsonData.PRC_INFO.step5PhotoLength
  }
  //}

  let num = 0 // 이어갈 파일 인덱스
  let bUAcdtInsmHpgClamSbmtDocuSVO = []

  // 수익자상이 둘다 서명 완료 하고, 이전 이미지 리스트가 있는 경우
  if (isOtherSignFinish && imgList && imgList.length > 0) {
    num = Number(imgList[imgList.length - 1].sbmtFileId.replace(/^\d+T(\d+).?pdf$/, '$1')) // 819720201T0004.pdf 에서 0004 만 추출해서 number type으로 변경
  } else {
    bUAcdtInsmHpgClamSbmtDocuSVO.push(
      {
        sbmtFileNm: '전자서명문서.pdf', // 첨부문서파일명
        sbmtFileId: jsonData.PRC_INFO.electroData.createDocId + '.pdf' // 첨부문서파일ID
      }
    )
    num = Number(jsonData.PRC_INFO.electroData.createDocId.match(/\d*$/)[0]) // 819720201T0004 에서 문자열의 끝 부터 숫자 0004 만 추출해서 number type으로 변경
  }

  console.log('>>> num : ', num)

  for (let j = 0; j < photoLength; j++) {
    num++
    let fileName = BUPostProcessUtil.createFileIDNameBU(num, insured.custId)

    console.log('>>> fileName : ', fileName)

    bUAcdtInsmHpgClamSbmtDocuSVO.push({
      sbmtFileNm: num + '_기타촬영문서-' + (j + 1) + '.pdf', // 첨부문서파일명
      sbmtFileId: fileName + '.pdf' // 첨부문서파일ID
    })
  }

  let params = {
    sbmtFileStoreYmd: moment(new Date().toISOString()).format('YYYYMMDD').toString(),
    bUAcdtInsmHpgClamSbmtDocuSVO: bUAcdtInsmHpgClamSbmtDocuSVO
  }
  let outsbmtFileId = ''
  let outsbmtFileNm = ''

  console.log('>>>> fn_ImageMerge params : ', params)

  lv_Vm.post(lv_Vm, params, 'txTSSBU05I4', 'S')
    .then(function (response) {
      console.log('>>>> fn_ImageMerge response : ', response)
      if (response.body !== null && response.body !== '') {
        if (response.body.respCd !== null && response.body.respCd !== '' && response.body.respCd === '00') {
          if (response.body.outsbmtFileId !== null && response.body.outsbmtFileId !== '' && response.body.outsbmtFileNm !== null && response.body.outsbmtFileNm !== '') {
            outsbmtFileId = response.body.outsbmtFileId
            outsbmtFileNm = response.body.outsbmtFileNm
          }

          if (insured.customerType === 'OTHER') {
            if (isOtherSignFinish) {
              BUPostProcessUtil.fn_sendDataSaveService(lv_Vm, vuchId, jsonData, callBack, isOtherSignFinish, outsbmtFileId, outsbmtFileNm, imgList)
            } else {
              callBack(true, {
                outsbmtFileNm: outsbmtFileNm, // num + '_기타촬영문서-' + tt + '.pdf' // 첨부문서파일명
                outsbmtFileId: outsbmtFileId // fileName + '.pdf' // 첨부문서파일ID
              })
            }
          } else {
            // 본인 이거나 자녀인경우에는 전자서명을 1번만 하면 되기 때문에, 신청서 저장 하고 끝, 이 경우 앱에서 진행 되기 때문에, native callBack 하고 mini 에서 완료 페이지 출력.
            BUPostProcessUtil.fn_sendDataSaveService(lv_Vm, vuchId, jsonData, callBack, isOtherSignFinish, outsbmtFileId, outsbmtFileNm, imgList)
          }
        } else {
          callBack(false, {
            message: '이미지 병합에 실패 하였습니다.',
            retry: true
          })

          // lv_Vm.getStore('confirm').dispatch('ADD', '이미지 병합에 실패 하였습니다.')
        }
      } else {
        callBack(false, {
          message: response.msgComm.msgDesc,
          retry: false
        })
      }
    })
    .catch(function (error) {
      callBack(false, error)
    })
}

/************************************************************************************************
* Function명  : fn_sendDataSaveService
* 설명        : 신청서 저장
************************************************************************************************/
BUPostProcessUtil.fn_sendDataSaveService = function (lv_Vm, vuchId, jsonData, callBack, isOtherSignFinish, outsbmtFileId, outsbmtFileNm, imgList) {
  const insured = jsonData.PRC_INFO.step1Data.insured
  const beneficiary = jsonData.PRC_INFO.step1Data.beneficiary
  const step2Data = jsonData.PRC_INFO.step2Data
  const step4Data = jsonData.PRC_INFO.step4Data
  const step5Data = jsonData.PRC_INFO.step5Data
  const electroData = jsonData.PRC_INFO.electroData
  const miniLoginUserInfo = jsonData.USER_INFO

  let params = {
    inqrScCd: 'I',
    acdtrNm: insured.custNm,
    acdtrRrn: insured.knbFrno + insured.knbBkno, // 사고자주민등록번호암호화
    jobpNm: step4Data.jobData.jobpNm, // 사고자직장명
    smsNtcTelno: '', // SMS통보전화번호암호화
    smsReqYn: step5Data.smsReqYn, // SMS요청여부
    dlyGudnMthCd: '', // 사고보험지연안내방법코드
    gudnlPblObjYn: step5Data.gudnlPblObjYn, // 사고보험지연안내방법코드
    bnkCd: step5Data.bnkCd, // 모바일영업은행코드
    dpstrNm: step5Data.dpstrNm, // 고객계좌예금주명
    rmtnAccnNo: step5Data.rmtnAccnNo,
    acdtAcpnObjScCd: step4Data.claimData.acdtAcpnObjScCd, // 사고접수대상구분코드
    diseDstrScCd: step4Data.claimData.diseDstrScCd, // 사고보험질병재해구분코드(발생원인)
    dthYn: !lv_Vm.$bizUtil.isEmpty(step4Data.claimData.rsnMdhsp[0]) ? 'Y' : 'N', // 01 사망여부
    dsabYn: !lv_Vm.$bizUtil.isEmpty(step4Data.claimData.rsnMdhsp[1]) ? 'Y' : 'N', // 08 장해여부
    dignYn: !lv_Vm.$bizUtil.isEmpty(step4Data.claimData.rsnMdhsp[2]) ? 'Y' : 'N', // 02 진단
    surgYn: !lv_Vm.$bizUtil.isEmpty(step4Data.claimData.rsnMdhsp[3]) ? 'Y' : 'N', // 06 수술여부
    hspzYn: !lv_Vm.$bizUtil.isEmpty(step4Data.claimData.rsnMdhsp[4]) ? 'Y' : 'N', // 03 입원여부
    mdhspYn: !lv_Vm.$bizUtil.isEmpty(step4Data.claimData.rsnMdhsp[5]) ? 'Y' : 'N', // 05 통원여부
    etcAcdtYn: !lv_Vm.$bizUtil.isEmpty(step4Data.claimData.rsnMdhsp[8]) ? 'Y' : 'N', // 00 기타사고여부
    acdtYmd: !lv_Vm.$bizUtil.isEmpty(step4Data.contentData.adrnDate) ? lv_Vm.$bizUtil.replaceAll(step4Data.contentData.adrnDate, '-', '') : '', // 사고일자
    dstrDtlCntnt: step4Data.contentData.tiAcdtDtl, // 재해상세내용(사고상세)
    otcomEntRldmInsrCoCd: step4Data.contentData.cstiIsrn, // 실손보험가입타보험회사코드
    othCoEntRldmInsrCnt: lv_Vm.$bizUtil.isEmpty(step4Data.contentData.enterCnt) ? 0 : Number(step4Data.contentData.enterCnt), // 실손보험타사가입건수
    mdexpAcpnVicaYn: step4Data.contentData.mdexpAcpnVicaYn, // 의료비접수대행여부
    sbmtFileStoreYmd: moment(new Date().toISOString()).format('YYYYMMDD').toString()
  }

  params.bUAcdtInsmHpgClamSbmtDocuSVO = []
  // 수익자상이 인 경우 둘다 서명완료 하면, 첫번째 서명자 서명서류 와 기타서류를 추가 한다.
  // 본인이나 자녀의 경우 첫 서명시에 AML&실소유&EDD 가 이뤄 지지만, 수익자상이는 수익자가 서명할 때에만 정보가 들어온다.
  if (isOtherSignFinish && imgList && imgList.length > 0) {
    params.bUAcdtInsmHpgClamSbmtDocuSVO = imgList
  } else {
    params.bUAcdtInsmHpgClamSbmtDocuSVO.push(
      {sbmtFileNm: '전자서명문서.pdf', sbmtFileId: electroData.createDocId + '.pdf'}
    )
  }

  if (outsbmtFileNm !== '' && outsbmtFileId !== '' ) {
    params.bUAcdtInsmHpgClamSbmtDocuSVO.push(
      {
        sbmtFileNm: outsbmtFileNm, // num + '_기타촬영문서-' + tt + '.pdf' // 첨부문서파일명
        sbmtFileId: outsbmtFileId // fileName + '.pdf' // 첨부문서파일ID
      }
    )
  }

  params.jobCd = step4Data.jobData.jobCd // 피보험자직업코드
  params.clamAmtClsfCd = step4Data.claimData.amount // 청구금액분류코드
  params.clamrId = beneficiary.custId // 청구자ID
  params.aplrEno = miniLoginUserInfo.userEno
  params.acpndPblMthCd = step5Data.acpndPblMthCd // 접수증발행방법코드
  params.dpstrId = step5Data.dpstrId // 예금주ID
  params.pymBnkAccoInfoId = step5Data.pymBnkAccoInfoId // 지급은행계정정보ID
  if (insured.customerType === 'CHILD') {
    params.bnfrId = insured.custId
  } else {
    params.bnfrId = beneficiary.custId
  }

  params.idcTofYn = 'N' // 신분증진위여부

  const anthData = jsonData.PRC_INFO.anthData
  if (typeof anthData !== 'undefined') {
    if (typeof anthData.anth !== 'undefined') {
      if (anthData.anth.result === 'Y') {
        params.idcTofYn = 'Y' // 신분증진위여부
      }
    }
  }

  const eddAddInputVO = jsonData.PRC_INFO.eddAddInputVO
  if (eddAddInputVO) {
    if (eddAddInputVO.zaContEntRsnCd) {
      params.contEntRsnCd = eddAddInputVO.zaContEntRsnCd // 계약가입사유코드(EDD 거래목적코드)
    } else {
      params.contEntRsnCd = ''
    }
    if (eddAddInputVO.zaFdSrcScCd) {
      params.fdSrcScCd = eddAddInputVO.zaFdSrcScCd // 자금원천구분코드(EDD 자금원천코드)
    } else {
      params.fdSrcScCd = ''
    }
  }
  if (insured.customerType === 'SELF') {
    params.acdtInsrAcpnObjTypCd = '01' // 사고보험접수대상유형코드 01:본인신청, 02: 수익자상이, 03:자녀신청
  } else if (insured.customerType === 'OTHER') {
    params.acdtInsrAcpnObjTypCd = '02' // 사고보험접수대상유형코드 01:본인신청, 02: 수익자상이, 03:자녀신청
  } else {
    params.acdtInsrAcpnObjTypCd = '03' // 사고보험접수대상유형코드 01:본인신청, 02: 수익자상이, 03:자녀신청
  }

  const ralOwner = jsonData.PRC_INFO.ralOwner
  if (ralOwner) {
    params.ralOwnerYn = ralOwner.select // 실제소유자여부
    if (ralOwner.select === 'N') {
      params.ralOwnerNm = ralOwner.Nm // 실제소유자명
      params.ralOwnerKnb = ralOwner.KnbEncr // 실제소유주민등록번호
      params.ralOwnerNatyCd = ralOwner.NatyCd.key // 모바일영업국가코드
    }
  }

  const rskEval = jsonData.PRC_INFO.rskEval
  if (rskEval) {
    params.wlNvtnRsltCd = rskEval.wlNvtnRsltCd // WL대사결과코드
    params.pepYn = BUPostProcessUtil.fn_getPepYn(rskEval) // PEP여부
    params.trtRsltCd = rskEval.rsltCd // AML처리결과코드
    params.rskEvalGrdCd = rskEval.rskEvalGrd // 위험평가등급코드
  }
  params.clamWcnstScCd = lv_Vm.customerType === 'OTHER' ? '2' : step2Data[0].agreeDocType // 동의서유형(상세, 요약)은 수익자상이인 경우에 상세형으로 강제 셋팅 됨.
  params.userId = miniLoginUserInfo.userId
  params.userNm = miniLoginUserInfo.userNm
  params.userScCd = miniLoginUserInfo.userScCd
  params.userRrn = miniLoginUserInfo.userRrn
  params.userEno = miniLoginUserInfo.userEno
  params.userDeptCd = miniLoginUserInfo.userDeptCd
  params.teamCd = miniLoginUserInfo.teamCd
  params.dutyCd = miniLoginUserInfo.dutyCd
  params.userGrd = miniLoginUserInfo.userGrd
  params.onpstOrgTypCd = miniLoginUserInfo.onpstOrgTypCd
  params.onpstOrgKndCd = miniLoginUserInfo.onpstOrgKndCd
  params.onpstDofNo = miniLoginUserInfo.onpstOrgKndCd
  params.onpstFofOrgNo = miniLoginUserInfo.onpstFofOrgNo
  params.shopAttrCd = miniLoginUserInfo.shopAttrCd
  params.trtOrgTypCd = miniLoginUserInfo.trtOrgTypCd
  params.trtOrgKndCd = miniLoginUserInfo.trtOrgKndCd
  params.trtHofOrgNo = miniLoginUserInfo.trtHofOrgNo
  params.trtDofOrgNo = miniLoginUserInfo.trtDofOrgNo
  params.trtFofOrgNo = miniLoginUserInfo.trtFofOrgNo
  params.trtrEno = miniLoginUserInfo.trtrEno
  params.trtOrgNo = miniLoginUserInfo.trtOrgNo
  params.srvcMngScCd = miniLoginUserInfo.srvcMngScCd
  params.opcCd = miniLoginUserInfo.opcCd

  console.log('result : ', params)

  lv_Vm.post(lv_Vm, params, 'txTSSBU05I2', 'S')
    .then(function (response) {
      console.log('>>>>> final response : ', response)
      if (response.body !== null && response.body !== '') {
        // 수익자상이에서 둘다 서명 완료 되면, 접수중단건 삭제 후 callback
        if (isOtherSignFinish) {
          let otherDelParams = {
            acdtInsrAplStrYmd: vuchId.substring(0, 8),
            acdtrId: vuchId.substring(8)
          }
          lv_Vm.post(lv_Vm, otherDelParams, 'txTSSBU05D1', 'S')
            .then(function (delResponse) {
              if (delResponse.body !== null && delResponse.body !== '') {
                console.log('접수중단건 삭제 완료.')
              }
              callBack(true, response)
            }).catch(function (error) {
              callBack(false, error)
              // window.vue.error(error)
            })
        } else {
          callBack(true, response)
        }
      } else {
        callBack(false, {
          message: response.msgComm.msgDesc,
          retry: false
        })
        // lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
      }
    })
    .catch(function (error) {
      callBack(false, error)
      // window.vue.error(error)
    })
}

BUPostProcessUtil.createFileIDNameBU = function (num, custId) {
  let returnValue = ''
  let numStr = '' + num
  if (numStr.length === 1) {
    numStr = '000' + numStr
  } else if (numStr.length === 2) {
    numStr = '00' + numStr
  } else if (numStr.length === 3) {
    numStr = '0' + numStr
  }
  if (custId.length === 10) {
    custId = custId.substr(1, custId.length)
  }
  returnValue = custId + 'T' + numStr

  return returnValue
}

/**
 * @description pep 여부 체크 및 데이터 셋팅.
 */
BUPostProcessUtil.fn_getPepYn = function (rskEval) {
  let pepyn = ''
  if (rskEval.wlNvtnRsltCd === '1' && rskEval.rsltCd === '1') {
    // this.lv_rskEval = '신분증 진위여부 및 실제소유자 확인을 진행해 주세요.' // 금융거래제한대상자
  } else if (rskEval.wlNvtnRsltCd === '1' && rskEval.rsltCd === 'D') {
    // this.lv_rskEval = '신분증 진위여부 및 실제소유자 확인을 진행해 주세요.' // 금융거래제한대상자
  } else if (rskEval.wlNvtnRsltCd === '2' && rskEval.rsltCd === '1') {
    // this.lv_rskEval = '신분증 진위여부 및 실제소유자 확인을 진행해 주세요.' // PEP
    pepyn = 'Y'
  } else if (rskEval.wlNvtnRsltCd === '2' && rskEval.rsltCd === 'A') {
    // this.lv_rskEval = '신분증 및 실제소유자 확인 생략 가능합니다.' // EDD-재이행주기미도래
  } else if (rskEval.wlNvtnRsltCd === '3' && rskEval.rsltCd === '1') {
    // this.lv_rskEval = '추가정보 입력 및 신분증 진위여부, 실제소유자 확인을 진행해 주세요.' // EDD
    pepyn = 'N'
  } else if (rskEval.wlNvtnRsltCd === '3' && rskEval.rsltCd === 'A') {
    // this.lv_rskEval = '신분증 및 실제소유자 확인 생략 가능합니다.' // EDD-재이행주기미도래
  } else if (rskEval.wlNvtnRsltCd === '4' && rskEval.rsltCd === '1') {
    // this.lv_rskEval = '추가정보 입력 및 신분증 진위여부, 실제소유자 확인을 진행해 주세요.' // EDD
    pepyn = 'N'
  } else if (rskEval.wlNvtnRsltCd === '4' && rskEval.rsltCd === 'A') {
    // this.lv_rskEval = '신분증 및 실제소유자 확인 생략 가능합니다.' // EDD-재이행주기미도래
  } else if (rskEval.wlNvtnRsltCd === '5' && rskEval.rsltCd === '1') {
    // this.lv_rskEval = '신분증 진위여부 및 실제소유자 확인을 진행해 주세요.' // CDD
    pepyn = 'X'
  } else if (rskEval.wlNvtnRsltCd === '5' && rskEval.rsltCd === 'C') {
    // this.lv_rskEval = '신분증 및 실제소유자 확인 생략 가능합니다.' // CDD-재이행주기미도래
  } else if (rskEval.wlNvtnRsltCd === '6' && rskEval.rsltCd === '1') {
    // this.lv_rskEval = '대상아님'
  } else if (rskEval.wlNvtnRsltCd === '6' && rskEval.rsltCd === 'C') {
    // this.lv_rskEval = '신분증 및 실제소유자 확인 생략 가능합니다.' // CDD-재이행주기미도래
  } else if (rskEval.wlNvtnRsltCd === '9' && rskEval.rsltCd === '0') {
    // this.lv_rskEval = '정상종료'
  } else if (rskEval.wlNvtnRsltCd === '9' && rskEval.rsltCd === '1' && rskEval.rskEvalGrd === 'H') {
    // this.lv_rskEval = '추가정보 입력 및 신분증 진위여부, 실제소유자 확인을 진행해 주세요.' // EDD
    pepyn = 'N'
  } else if (rskEval.wlNvtnRsltCd === '9' && rskEval.rsltCd === '1' && rskEval.rskEvalGrd === 'L') {
    // this.lv_rskEval = '신분증 진위여부 및 실제소유자 확인을 진행해 주세요.' // CDD
    pepyn = 'X'
  } else if (rskEval.wlNvtnRsltCd === '9' && rskEval.rsltCd === 'A') {
    // this.lv_rskEval = '신분증 및 실제소유자 확인 생략 가능합니다.' // EDD-재이행주기미도래
  } else if (rskEval.wlNvtnRsltCd === '9' && rskEval.rsltCd === 'C') {
    // this.lv_rskEval = '신분증 및 실제소유자 확인 생략 가능합니다.' // CDD-재이행주기미도래
  } else if (rskEval.wlNvtnRsltCd === '9' && rskEval.rsltCd === 'N') {
    // this.lv_rskEval = '신분증 진위여부 및 실제소유자 확인을 진행해 주세요.' // 정상종료
  } else if (rskEval.wlNvtnRsltCd === '9' && rskEval.rsltCd === 'W') {
    // this.lv_rskEval = '신분증 진위여부 및 실제소유자 확인을 진행해 주세요.' // W/L대상확인
  } else if (rskEval.rsltCd === '') {
    // this.lv_rskEval = '신분증 진위여부 및 실제소유자 확인을 진행해 주세요.' // 오류발생
  }
  return pepyn
}

export default BUPostProcessUtil
