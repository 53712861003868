/*
 * 업무구분: 사고보험금 신청
 * 화 면 명: MSPBU005D
 * 화면설명: 본인인증 - 카카오
 * 접근권한: 모든사용자
 * 작 성 일: 2023.02.17
 * 작 성 자: 김진원
 */
<template>
  <mo-validate-watcher ref="vWatcher">
    <!-- 카카오 인증 -->
    <ur-box-container alignV="start" componentid="" direction="column" class="ns-certify-wrap">
      <div class="ns-check arrow fex-clumn fs17rem mt20 mb10" >
        <mo-checkbox v-model="isCheckAgree" @input="fn_OpenKakaoAgreeDoc" :disabled="isNotEmpty(authTime)">개인정보 이용 활용동의</mo-checkbox>
        <!-- 개인정보 미동의 오류메시지 표출 -->
        <div class="ns-certify-sed" :class="kakaoAgreeDocErrorMsg">
          <div class="ns-certify-sed-txt">
            <div class="txt-error crTy-orange2">개인정보 이용 활용동의 해주세요.</div>
          </div>
        </div>
        <!-- 개인정보 미동의 오류메시지 표출 //-->
      </div>


      <ur-box-container alignV="start" componentid="" direction="column" class="ns-certify-area">
              
        <ur-box-container alignV="start" componentid="" direction="column" class="info-box">
          <div class="verify-phone msp-verify-phone fexTy5">
            <msp-bottom-select ref="bottomSelect" :items="telecomTypes" v-model="telecomType" underline class="w100px mr10" placeholder="선택" bottom-title="통신사" :disabled="isNotEmpty(authTime)" closeBtn scrolling/>
            <span class="fs17rem pl10">{{$props.custInfo.celno}}</span>
          </div>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="ur_box_container_006"  direction="column" class="ns-btn-relative-area mb0 pl0 pr0">
          <div class="ns-btn-relative-s w100 mb0">
            <div class="relative-div mb5">
              <mo-button color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_ReqAuthNumberKakao" :disabled="isNotEmpty(authTime)">카카오 인증요청</mo-button>
            </div>
          </div>
        </ur-box-container>
        <!-- 인증요청 클릭시 표출됨 => 인증확인 완료시 : 클래스값 "success"표시, 인증 오류시: 클래스값 "error"표시 -->
        <div class="ns-certify-sed pt10" :class="authResult">
          <div class="ns-certify-sed-txt">
            <div class="txt-success"><span class="ico"></span>카카오 인증이 완료되었습니다.</div>
            <div class="txt-error crTy-orange2" v-html="authResultMessage"></div>
          </div>
        </div>
      </ur-box-container>


      <!-- 바텀시트1 : 카카오: 개인정보 이용 활용동의  -->
      <mo-bottom-sheet ref="bottomSheet1" class="ns-bottom-sheet" @before-close="onBeforeClose">
        <template v-slot:title>개인정보 이용 활용 동의</template>
        <div class="content-area verify-agree">
          <p class="crTy-bk1 line-h-Ty1">
            (주)카카오는 본인인증서비스 제공을 위해 본인으로부터 아래의 개인정보를 입력받으며, 입력 받은 개인정보의 수집 및 이용을 위해 다음의 사항에
            대하여 본인으로부터 동의를 받습니다.
          </p>
          <div class="text-gray-box mt10">
            <span class="fs14rem pb5">개인(신용)정보 제 3자 제공동의</span>
            <ul class="terms-list-area fs14rem">
              <li class="pb5 crTy-bk7">제공목적:본인인증</li>
              <li class="pb5 crTy-bk7">제공받는자:(주)카카오</li>
              <li class="pb5 crTy-bk7">제공받는자의 개인정보 보유 이용기간 5년</li>
              <li class="pb5 crTy-bk7">제공항목:이름, 생년월일, 휴대폰번호</li>
            </ul>
          </div>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs noshadow">
            <div class="relative-div">
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_ConfirmKakaoAgreeDoc('btnAgree')" name="확인">확인</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>
      <!-- 바텀시트1 : 카카오: 개인정보 이용 활용동의  -->


      <!-- 바텀시트5 : 카카오 최대 횟수 초과안내 팝업 표출 -->
      <!-- <mo-bottom-sheet ref="bottomSheet4"  class="ns-bottom-sheet closebtn">
        <template v-slot:title>카카오 최대 오류 횟수를 초과안내</template>
        <div class="ns-btn-close" @click="close4"></div>  
        <div class="content-area txt-center mh50px">
          <p class="ment">
            카카오 최대 오류횟수를 초과하였습니다.<br>
            휴대폰 인증을 통해 본인인증을 하시겠습니까?
          </p>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs noshadow">
            <div class="relative-div">
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="btnLink">취소</mo-button>
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="btnLink2" name="확인">확인</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet> -->
      <!-- 바텀시트5 : 카카오 최대 횟수 초과안내 팝업 표출 //-->   

    </ur-box-container>
    <!-- 카카오 인증 //-->
  </mo-validate-watcher>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import moment from 'moment'

//const COUNTER = '00:05'
const COUNTER = '05:00'

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPBU007D",
  screenId: "MSPBU007D",
  components: {},
  props: {
    custInfo: {type: Object},
    index: {type: Number},
    data: {type: Object},
  },

  watch: {
    //다음에하기
    'data.isToLater': function(isChecked){
      this.authTime = '' //인증 시간 초기화
      this.isDisabled = isChecked

    },
    authTime(value){
      this.$emit('setAuthSuccessTime', this.$props.index, value)

    }
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {},
  mounted() {
    this.$bcUtil.getDataBySrnIdInLocalStorage(this)
  },
  destroyed(){
    window.clearInterval(this.timer)
    window.clearInterval(this.interval) // 타이머 종료
    window.vue.getStore('progress').dispatch('HIDE')
    this.authResultMessage = ''
    this.$bcUtil.setDataBySrnIdInLocalStorage(this)
  },
  
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      isContinue: false,
      payload: '', // 카카오알림톡 페이로드
      authTime: '', //인증시간
      countDown: '',
      isCheckAgree : false, //이용 동의 체크
      kakaoAgreeDocErrorMsg : '', //오류시 error '개인정보 이용 및 활용동의 해주세요.'  메시지 표시
      authResult : 'error', //인증확인 결과 success, error
      authResultMessage : '',
      interval: null, // 인증번호 타이머 인터벌
      timer: null,
      //isAgreeDisabled : false, //이용 동의 활성 여부
      //isReqAuthNumberBtnDisabled : false, // 인증번호 전송버튼 활성 여부
      isAuthConfirmBtnDisabled : true, // 인증번호 확인 버튼 활성 여부
      //isTelecomTypeDisabled: false,

      //카카오인증 결과값
      athntTrnstId: '', //인증트랜잭션ID
      athntHstrId: '', //인증이력ID

      telecomType: 'SKT', //통신사 선택값
      telecomTypes: [
                      {value: 'SKT',text: 'SKT'},
                      {value: 'KT',text: 'KT'},
                      {value: 'LGT',text: 'LG U+'},
                      {value: 'SKTM',text: 'SKT(알뜰폰)'},
                      {value: 'KTM',text: 'KT(알뜰폰)'},
                      {value: 'LGTM',text: 'LG U+(알뜰폰)'},
                    ], //통신사 select box 목록
    }
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {},
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {

    /**
     * @name isNotEmpty
     * @description 개발 편의
     * @param {Object|String} value
     */
    isNotEmpty(value){
      return !this.$bizUtil.isEmpty(value)
    },


    /**
     * @description 개인정보 이용 및 활용 동의 확인버튼을 제외하곤 닫지 못하도록 설정
     *              확인 버튼 누를 시 item 값 : btnAgree
     *              다른 영역 누를 시 item 값 : swipe
     */
    onBeforeClose(fn, item) {
      if (item === 'swipe') return
      fn() // 닫기 실행
    },


    /**
     * @description 이전화면 이동 confirm창
     */
    fn_PreConfirmOpen() {
      try {
        let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
            properties: {
              title: '※ 알림 : 본인 인증 초기화',
              content: '이전 화면으로 이동 시, 본인 인증이 초기화 됩니다.<br>이동 하시겠습니까?',
              title_pos_btn: '예',
              title_neg_btn: '아니오'
            },
            listeners: {
              onPopupConfirm: () => {
                this.$bottomModal.close(lv_AlertPop)
                this.$emit('pre')
              },
              onPopupClose: () => {
                this.$bottomModal.close(lv_AlertPop)
              }
            }
          })
      } catch (e) {
        console.log('app_crash :', e.name , e.message)
        let isAppCrashAlert = this.getStore('bsStore').getters.getState.isAppCrashAlert // appCrash alert 여부        
        if (isAppCrashAlert) alert(`앱이 비정상 종료하였습니다. : ${e.name} : ${e.message}`)
      }
    },


    fn_setError(){
      try {
        window.clearInterval(this.timer)
        window.clearInterval(this.interval) // 타이머 종료
        window.vue.getStore('progress').dispatch('HIDE')

        this.authResultMessage = '인증 오류'
      } catch (e) {
        console.log('app_crash :', e.name , e.message)
        let isAppCrashAlert = this.getStore('bsStore').getters.getState.isAppCrashAlert // appCrash alert 여부        
        if (isAppCrashAlert) alert(`앱이 비정상 종료하였습니다. : ${e.name} : ${e.message}`)
      }
    },


    /**
     * @description 인증 타이머 카운트다운
     */
    fn_StartCounter(){
      try {
        this.countDown = COUNTER

        let min = Number(COUNTER.split(':')[0])
        let sec = Number(COUNTER.split(':')[1])

        let lv_Vm = this

        // 인증 count down 시작
        this.interval = window.setInterval(function(count){
          count.setTime(count-1000) //interval clear 시 count 초기화
          
          let m = count.getMinutes() //type int
          let s = count.getSeconds() //type int

          lv_Vm.isContinue = m+s > 0

          if( lv_Vm.isContinue  ){
            let min = String(m).padLeft(2, '0') //type string
            let sec = String(s).padLeft(2, '0') //type string

            lv_Vm.countDown =  min.concat(':', sec) // 07:00
            lv_Vm.authResultMessage = '인증번호 만료까지 ' + lv_Vm.countDown

          }
          // 시간 초과
          else {

            // init
            lv_Vm.countDown = COUNTER
            lv_Vm.authResultMessage = ''
            window.clearInterval(lv_Vm.interval) // 타이머 종료
          }
        }, 1000, new Date(0, 0, 0, 0, min, sec))
      } catch (e) {
        console.log('app_crash :', e.name , e.message)
        let isAppCrashAlert = this.getStore('bsStore').getters.getState.isAppCrashAlert // appCrash alert 여부        
        if (isAppCrashAlert) alert(`앱이 비정상 종료하였습니다. : ${e.name} : ${e.message}`)
      }

    },


    /**
     * @description 카카오 인증 전송
     */
    fn_ReqAuthNumberKakao() {
      try {
        // 동의 체크 확인
        if( !this.isCheckAgree ){
          this.kakaoAgreeDocErrorMsg = 'error'
          return

        }

        const custInfo = this.$props.custInfo
        this.kakaoAgreeDocErrorMsg = '' // error 메시지 초기화
        this.authResult = 'error' // 인증 요청 및 결과

        let params ={
                      name: custInfo.custNm,
                      birthday: this.$bizUtil.addBirthFrontYear(custInfo.rrn.substring(0, 6)),
                      phoneNo: custInfo.celno, // 전화번호
                    }

        window.vue.getStore('progress').dispatch('FULL')
        window.vue.getStore('progress').dispatch('SHOW')
        
        let lv_Vm = this
        this.post(this, params, 'txTSSBU02S2', 'S', undefined, true)
          .then(function (response) {
            if (response.body !== null && response.body !== '') {
              let rtnData = response.body
              if (rtnData.result === '00') {
                lv_Vm.payload = rtnData.payload
                lv_Vm.fn_StartCounter()
                lv_Vm.fn_kakaoStep2()
                lv_Vm.getStore('toast').dispatch('ADD', '[1/4] 카카오 인증요청 하였습니다.')
              }
            } else {
              lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
              window.vue.getStore('progress').dispatch('HIDE')
            }

          })
          .catch(function (error) {
            window.vue.error(error)
            lv_Vm.fn_setError()
          })
      } catch (e) {
        console.log('app_crash :', e.name , e.message)
        let isAppCrashAlert = this.getStore('bsStore').getters.getState.isAppCrashAlert // appCrash alert 여부        
        if (isAppCrashAlert) alert(`앱이 비정상 종료하였습니다. : ${e.name} : ${e.message}`)
      }
    },


    /******************************************************************************
    * Function명 : 카카오 2단계 수행
    * 설명       : 카카오 2단계 수행 / 전자서명 상태 조회
    ******************************************************************************/
    fn_kakaoStep2 () {
      try {
        let lv_Vm = this
        let pollingCnt = 0 //5초마다 polling 용도

        this.timer = setInterval(() => {
          if( lv_Vm.isContinue === false ){
            lv_Vm.getStore('confirm').dispatch('ADD', '본인인증시간이 종료되었습니다.')
            lv_Vm.fn_setError()
            return
          }

          if (++pollingCnt % 5 === 0) {
            let param = {
              payload: lv_Vm.payload
            }

            lv_Vm.post(lv_Vm, param, 'txTSSBC35P3', 'S', undefined, true)
              .then(function (response) {
                // 진행바 표시
                if (response.body !== null && response.body !== '') {
                  let rtnData = response.body
                  console.log('#### 2단계 : ' + rtnData.status )
                  if (rtnData.status !== null && rtnData.status === 'COMPLETE') {
                    lv_Vm.getStore('toast').dispatch('ADD', '[2/4] 서명 완료 되었습니다.')
                    // COMPLETE : 서명완료
                    console.log('####### 3단계 이동')
                    lv_Vm.fn_kakaoStep3()

                  } else if (rtnData.status !== null && rtnData.status === 'EXPIRED') {
                    // EXPIRED : 타임아웃
                    lv_Vm.getStore('confirm').dispatch('ADD', '본인인증시간이 종료되었습니다.')
                    lv_Vm.fn_setError()
                  }
                } else {

                  // 정상처리가 아닌경우
                  let lv_errorMsg = ''
                  let lv_errorCd = response.msgComm.msgCd
                  if (lv_errorCd === 'E400') {
                    lv_errorMsg = '(카카오톡 미사용/해지 고객)'
                  } else if ( lv_errorCd === 'E401' ) {
                    lv_errorMsg = '(휴대폰 번호 누락/불일치)'
                  } else if ( lv_errorCd === 'E402' ) {
                    lv_errorMsg = '(고객명 누락/불일치)'
                  } else if ( lv_errorCd === 'E403' ) {
                    lv_errorMsg = '(생년월일 누락/불일치)'
                  } else if ( lv_errorCd === 'E421' ) {
                    lv_errorMsg = '(생년월일 불일치)'
                  } else if ( lv_errorCd === 'E422' ) {
                    lv_errorMsg = '(고객명 불일치)'
                  } else {
                    lv_errorMsg = '\n'
                  }
                  let reqMsg = '카카오 인증이 발송되지 않았습니다.\n' + lv_errorMsg + '\n자세한 사항에 대한 문의는 카카오\n고객센터로 문의 바랍니다.'
                  lv_Vm.getStore('confirm').dispatch('ADD', reqMsg)
                  lv_Vm.fn_setError()
                }
              })
              .catch(function (error) {
                window.vue.error(error)
                lv_Vm.fn_setError()
              })
          }
        }, 1000)
      } catch (e) {
        console.log('app_crash :', e.name , e.message)
        let isAppCrashAlert = this.getStore('bsStore').getters.getState.isAppCrashAlert // appCrash alert 여부        
        if (isAppCrashAlert) alert(`앱이 비정상 종료하였습니다. : ${e.name} : ${e.message}`)
      }
    },
    /******************************************************************************
    * Function명 : 카카오 3단계 수행
    * 설명       : 카카오 3단계 수행 / 전자서명 검증
    ******************************************************************************/
    fn_kakaoStep3 () {
      try {
        window.clearInterval(this.timer)

        let lv_Vm = this
        let param = {
          payload : this.payload
        }

        this.post(this, param, 'txTSSBC35P5', 'S', undefined, true)
          .then(function (response) {

            if (response.body !== null && response.body !== '') {

              let rtnData = response.body
              console.log('#### 3단계 : ' + rtnData.status )
              if (rtnData.status !== null && rtnData.status === 'COMPLETE') {
                lv_Vm.getStore('toast').dispatch('ADD', '[3/4] 카카오 검증 발신 되었습니다.')
                // COMPLETE : 서명완료
                console.log('#### 4단계 이동' )
                lv_Vm.fn_kakaoStep4()

              } else {
                lv_Vm.getStore('confirm').dispatch('ADD', '본인인증시 오류가 발생하였습니다.')
                lv_Vm.fn_setError()
              }

            } else {
              lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
              lv_Vm.fn_setError()

            }
          })
          .catch(function (error) {
            window.vue.error(error)
            lv_Vm.fn_setError()
          })
      } catch (e) {
        console.log('app_crash :', e.name , e.message)
        let isAppCrashAlert = this.getStore('bsStore').getters.getState.isAppCrashAlert // appCrash alert 여부        
        if (isAppCrashAlert) alert(`앱이 비정상 종료하였습니다. : ${e.name} : ${e.message}`)
      }
    },
    /******************************************************************************
    * Function명 : 카카오 4단계 수행
    * 설명       : 카카오 4단계 수행 / 전자서명 상태조회 (카카오톡인증서명검증일시 적용)
    ******************************************************************************/
    fn_kakaoStep4 () {
      try {
        window.clearInterval(this.timer)

        let pollingCnt = 0 //5초마다 polling 용도
        let lv_Vm = this

        this.timer = setInterval(() => {
          if( lv_Vm.isContinue === false ){
            lv_Vm.getStore('confirm').dispatch('ADD', '본인인증시간이 종료되었습니다.')
            lv_Vm.fn_setError()
            return
          }

          if (++pollingCnt % 5 === 0) {
            let param = {
              payload : lv_Vm.payload,
              isSign : 'N' // 서명여부
            }

            lv_Vm.post(lv_Vm, param, 'txTSSBC35S7', 'S', undefined, true)
              .then(function (response) {
                // 진행바 표시
                if (response.body !== null && response.body !== '') {
                  let rtnData = response.body
                  console.log('#### 4단계 : ' + rtnData.status + ' / ' + rtnData.isSignedData)
                  if (rtnData.status !== null && rtnData.status === 'COMPLETE' && rtnData.isSignedData) {
                    lv_Vm.getStore('toast').dispatch('ADD', '[4/4] 카카오 검증 되었습니다.')
                    
                    window.clearInterval(lv_Vm.timer)
                    window.clearInterval(lv_Vm.interval) // 타이머 종료
                    
                    // 인증 성공 시간 조회(서버기준)
                    lv_Vm.$commonUtil.getServerTime().then(function (response) {
                      lv_Vm.authTime = moment(response.toString()).format('YYYY-MM-DD HH:mm')
                      lv_Vm.authResult = 'success' // 인증 완료 메시지 출력
                      window.vue.getStore('progress').dispatch('HIDE')
                    })

                  }
                } else {
                  lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
                  lv_Vm.fn_setError()
                }
              })
              .catch(function (error) {
                window.vue.error(error)
                lv_Vm.fn_setError()
              })
          }
        }, 1000)
      } catch (e) {
        console.log('app_crash :', e.name , e.message)
        let isAppCrashAlert = this.getStore('bsStore').getters.getState.isAppCrashAlert // appCrash alert 여부        
        if (isAppCrashAlert) alert(`앱이 비정상 종료하였습니다. : ${e.name} : ${e.message}`)
      }
    },

    /**
     * @description 인증결과 확인 전송
     *//*
    fn_AuthConfirmKakao() {
      let params = {
                     athntTrnstId: this.athntTrnstId, // 트랜잭션id
                     athntHstrId: this.athntHstrId
                   }

      let lv_Vm = this
      this.post(lv_Vm, params, 'txTSSCM12S3', 'S')
        .then(function (response) {

          //local 환경에서는 그냥 패스
          if( process.env.NODE_ENV === 'local' ){
            response.body = {}
            response.body.athntStatRespCd = 'P000'
            response.body.athntTrtStatCd = 'COMPLETE'
          }

          if (response.body !== null && response.body !== '' && response.body.athntStatRespCd === 'P000') {

            if ( response.body.athntTrtStatCd === 'COMPLETE' ) { // 서명완료

            } else if ( response.body.athntTrtStatCd === 'PREPARE' ) {
              lv_Vm.authResultMessage = '카카오Pay 인증이 완료되지 않았습니다.<br>인증 완료후 ‘인증결과 확인’을 다시 한 번 클릭하시기 바랍니다.'
            } else if ( response.body.athntTrtStatCd === 'EXPIRED' ) { // 타임아웃
              lv_Vm.authResultMessage = '인증시간이 초과되었습니다.<br>상단의 ‘카카오 인증요청’버튼을 눌러 재인증 하시기 바랍니다.'
            }

          } else { // 정상처리가 아닌경우
            if (response.body.athntReqRespCd === 'E400') {
              response.msgComm.msgDesc = '중복 호출. 잘못된 요청입니다.'
            } else if ( response.body.athntReqRespCd === 'E401' ) {
              response.msgComm.msgDesc = '조회 식별자가 존재하지 않습니다.'
            } else if ( response.body.athntReqRespCd === 'E402' ) {
              response.msgComm.msgDesc = '서명 결과가 존재하지 않습니다.'
            } else if ( response.body.athntReqRespCd === 'E403' ) {
              response.msgComm.msgDesc = '서명이 완료되지 않았습니다.'
            } else if ( response.body.athntReqRespCd === 'E404' ) {
              response.msgComm.msgDesc = 'Transaction ID가 존재하지 않습니다.'
            } else if ( response.body.athntReqRespCd === 'E405' ) {
              response.msgComm.msgDesc = '이용기관 정보를 찾을 수 없습니다.'
            } else {
              response.msgComm.msgDesc = ''
            }
            lv_Vm.authResultMessage = '카카오Pay 인증이 실패되었습니다.<br>' + response.msgComm.msgDesc
          }

          // 인증 성공
          if( lv_Vm.$bizUtil.isEmpty(lv_Vm.authResultMessage)) {
            // 인증 성공 시간 조회(서버기준)
            lv_Vm.$commonUtil.getServerTime().then(function (response) {
              lv_Vm.authTime = moment(response.toString()).format('YYYY-MM-DD HH:mm')
              lv_Vm.authResult = 'success' // 인증 완료 메시지 출력
              lv_Vm.isAuthConfirmBtnDisabled = true // 인증결과 확인 버튼 비활성화
            })
          }
          // 인증 실패
          else {
            lv_Vm.authResult = 'error' // 인증 실패 메시지 출력
          }
          
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },*/



    /**
     * @description 카카오 인증 안내
     */
    fn_OpenKakaoAgreeDoc(isChecked){
      if( isChecked ){
        this.$refs.bottomSheet1.open()
      }

      //isCheckAgree 변수가 checkbox 에 bind 된 변수이긴 한데, 체크 했을 때 개인정보 이용 활용 동의 팝업에 있는 확인 버튼을 눌렀을 때 체크 되도록 하기 위해 false 셋팅.
      this.isCheckAgree = false

    },


    
    /**
     * @description 개인정보 이용 및 활용 동의 확인 버튼
     */
    fn_ConfirmKakaoAgreeDoc(v) {
      this.isCheckAgree = true
      this.kakaoAgreeDocErrorMsg = ''
      this.$refs.bottomSheet1.close(v)
    },


    /**
     * @description 이전 버튼 클릭
     */
    fn_Pre(){
      if( this.authResult === 'success' ){
        this.fn_PreConfirmOpen()
      } else {
        this.$emit('pre')
      }
    },


    open4() {this.$refs.bottomSheet4.open();},
    close4() {this.$refs.bottomSheet4.close(); },

  }
};
</script>
<style scoped>
</style>