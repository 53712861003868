/*
 * 업무구분: 고객
 * 화 면 명: MSPCM013P
 * 화면설명: 보험직업조회 팝업
 * 접근권한: 일반사용자, 지점장, 지역단스텝 (U000, F110, F102)
 * 작 성 일: 2022.06.27
 * 작 성 자: 이태훈
 */
<template>
  <ur-page-container :show-title="false" type="popup" title="보험직업조회">
    <ur-box-container direction="column" alignV="start">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area">
        <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="ns-tab-box">
          <mo-tab-box default-idx="1">
              <!-- tab id loop -->
              <mo-tab-label idx="1" @click="fn_ChangeTab(0)">직접검색</mo-tab-label>
              <mo-tab-label idx="2" @click="fn_ChangeTab(1)">분류별보기</mo-tab-label>
              <!-- tab id loop //-->
          </mo-tab-box>
        </ur-box-container>

        <ur-box-container v-if="!isCategoryVisible" alignV="start" componentid="ur_box_container_003" direction="column" class="ns-input-box mt15">
          <mo-text-field ref="refJobNm" v-model="jobNm" searchable underline placeholder="검색"  @keyup.13="fn_SearchJobNm" @click-icon="fn_SearchJobNm" :rules="validateRule" clearable lazy/>
        </ur-box-container>

        <ur-box-container  v-else alignV="start" componentid="ur_box_container_003" direction="column" class="ns-input-box mt15">
          <msp-bottom-select ref="bottomSelect" :items="jobComboLclsfList" v-model="jobComboLclsf" underline class="ns-dropdown-sheet" @input="fn_SelectJobComboLclsf" placeholder="대분류" bottom-title="대분류" closeBtn/>
          <msp-bottom-select ref="bottomSelect" :items="jobComboMclsfList" v-model="jobComboMclsf" underline class="ns-dropdown-sheet" @input="fn_SelectJobComboMclsf" placeholder="중분류" bottom-title="중분류" closeBtn/>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="ns-info-sum">
          <mo-list-item>
            <div class="list-item__contents">
              <div class="list-item__contents__title">
                <span class="sum">총 {{searchResult.length}}건</span>
              </div>
            </div>
          </mo-list-item>
        </ur-box-container>
        <ur-box-container v-if="searchResult.length !== 0" alignV="start" componentid="ur_box_container_004" direction="column" class="ns-active-list job-sch">
          <mo-list :list-data="searchResult">
            <template #list-item="{item}">
              <mo-list-item expandable prevent-expand ref="expItem">
                <div class="list-item__contents">
                  <div class="list-item__contents__info">
                    <div class="ns-radio-list">
                      <mo-radio v-model="radioSelected" :value="item.insrJobCd" @input="fn_CheckSelectedItem(item)">{{item.insrJobNm}} [{{item.insrJobCd}}]</mo-radio>
                    </div>
                  </div>
                </div>
                <template v-slot:expand class="ns-gray-box">
                  <div class="list-item__contents">
                    <div class="job-sch-box">
                      <div class="job-sch-txt">
                        <span>일반 : {{item.rskGrdCdNm}}</span><em>|</em><span>상해 : {{item.injryRskGrdCdNm}}</span><em>|</em><span>입원 : {{item.hspzRskGrdCdNm}}</span><em>|</em><span>재해 : {{item.dstrInjryGrdCdNm}}</span>
                      </div>
                      <div class="job-sch-row">
                        <div class="title">
                          <span>합산기준</span>
                        </div>
                        <div class="value">
                          <span>{{item.addupStnd}}</span>
                        </div>
                      </div>
                      <div class="job-sch-row">
                        <div class="title">
                          <span>작업설명</span>
                        </div>
                        <div class="value">
                          <span>{{item.insrJobExpln}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </mo-list-item>
            </template>
          </mo-list>
        </ur-box-container>
        <ur-box-container v-else alignV="start" componentid="ur_box_container_002" direction="column" class="ns-contract-list no-data">
          <mo-list-item>
            <div class="list-item__contents">
              <div class="list-item__contents__info">
                <span v-if="isEmptySearchKeyword === 1">직업 입력 후 검색해 주세요.</span>
                <span v-else-if="isEmptySearchKeyword === 2">분류를 선택해 주세요.</span>
                <span v-else>입력된 직업은 등록되어 있지 않습니다.</span>
              </div>
            </div>
          </mo-list-item>
        </ur-box-container>
        <div class="ns-height50"></div>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_BtnK2OnClick">취소</mo-button>
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" 
              :disabled="radioSelected === null" @click="fn_BtnK1OnClick">확인</mo-button>
          </div>
        </ur-box-container>
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>

<script>
/************************************************************************************************
* 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import MspBottomSelect from '@/ui/_cmm/components/MspBottomSelect.vue' /* dropdown bottom-sheet */

export default {
  /************************************************************************************************
  * Vue 파일 속성 정의 영역
  ************************************************************************************************/
  // 현재 화면명
  name: 'MSPCM013P',
  // 현재 화면 ID
  screenId: 'MSPCM013P',
  // 컴포넌트 선언
  components: {
    MspBottomSelect
  },
  props: {
    'pJobCdNm': String // 직업소분류 코드명
  },
   // bottom-sheet Full Popup
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },

  /************************************************************************************************
  * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data () {
    return {
      title: '보험직업조회', // 화면명
      isCategoryVisible: false,
      jobNm: '',
      jobComboLclsfList: [], // 직업 대분류 리스트
      jobComboMclsfTotalList: [], // 직업 중분류 전체 리스트
      jobComboMclsfList: [{value: '', text: '전체'}], // 대분류 선택에 따른 직업 중분류 리스트
      jobComboLclsf: '', // {value: '', text: '전체'}, // 선택된 직업 대분류
      jobComboMclsf: '', // {value: '', text: '전체'}, // 선택된 직업 중분류
      searchResult: [
        { insrJobCd: '012212',
          insrJobNm: '회사 사무직 종사자',
          rskGrdCdNm: 'A',
          injryRskGrdCdNm: '비',
          hspzRskGrdCdNm: 'A',
          dstrInjryGrdCdNm: 'A',
          addupStnd: '사망합산 200,000만원 / 입원합산 6만원 / 재상합산 20,000만원',
          insrJobExpln: '사업체 내에서 각종 사무적 업무를 수행한다',
          isOpen: false
        },
        { insrJobCd: '113200',
          insrJobNm: '전업주부',
          rskGrdCdNm: 'A',
          injryRskGrdCdNm: '비',
          hspzRskGrdCdNm: 'B',
          dstrInjryGrdCdNm: 'A',
          addupStnd: '사망합산 200,000만원 / 입원합산 4만원 / 재상합산 20,000만원',
          insrJobExpln: '직업 활동을 하지 않으며 집안 청소, 육아, 음식조리, 세탁, 가사일 관리 등을 하는 자를 말한다. 가구의 소득 유무로 판단. 비정기적인 부업 참여시 해당 직무 확인하여 적용',
          isOpen: false
        },
        { insrJobCd: '112201',
          insrJobNm: '대학(원)생',
          rskGrdCdNm: 'A',
          injryRskGrdCdNm: '비',
          hspzRskGrdCdNm: 'A',
          dstrInjryGrdCdNm: 'A',
          addupStnd: '사망합산 200,000만원 / 입원합산 6만원 / 재상합산 20,000만원',
          insrJobExpln: '대학, 대학교, 대학원 등 학부생 전체를 포함함. 대학의 체육특기자(야구, 농구, 축구, 럭비, 아이스하키, 체조 등 대학 소속 선수)는 운동선수 코드를 적용해야 하며 해당코드 사용 불가함.',
          isOpen: false
        },
        { insrJobCd: '112200',
          insrJobNm: '초,중,고등학생',
          rskGrdCdNm: 'A',
          injryRskGrdCdNm: '비',
          hspzRskGrdCdNm: 'A',
          dstrInjryGrdCdNm: 'A',
          addupStnd: '사망합산 200,000만원 / 입원합산 6만원 / 재상합산 20,000만원',
          insrJobExpln: '초,중,고등학생',
          isOpen: false
        },
        { insrJobCd: '116100',
          insrJobNm: '미취학아동',
          rskGrdCdNm: 'A',
          injryRskGrdCdNm: '비',
          hspzRskGrdCdNm: 'A',
          dstrInjryGrdCdNm: 'A',
          addupStnd: '사망합산 200,000만원 / 입원합산 6만원 / 재상합산 20,000만원',
          insrJobExpln: '미취학아동',
          isOpen: false
        },
        { insrJobCd: '113302',
          insrJobNm: '61세이상 남자 및 여자(직업종사자 제외)',
          rskGrdCdNm: 'C',
          injryRskGrdCdNm: '중',
          hspzRskGrdCdNm: 'C',
          dstrInjryGrdCdNm: 'C',
          addupStnd: '사망합산 150,000만원 / 입원합산 4만원 / 재상합산 5,000만원',
          insrJobExpln: '직업종사자 제외',
          isOpen: false
        },
        { insrJobCd: '042302',
          insrJobNm: '보험설계사(당사)',
          rskGrdCdNm: 'A',
          injryRskGrdCdNm: '비',
          hspzRskGrdCdNm: 'A',
          dstrInjryGrdCdNm: 'A',
          addupStnd: '사망합산 200,000만원 / 입원합산 6만원 / 재상합산 20,000만원',
          insrJobExpln: '보험가입대상자를 방문하여 보함가입을 권유하고 계약서를 작성하여 영업점에 제출하는 자를 말하며, (당사)보험회사에 고용되어 해당 회사의 보험 상품을 고객에게 권유하고 판매한다.',
          isOpen: false
        },
        { insrJobCd: '053130',
          insrJobNm: '식당 종사원',
          rskGrdCdNm: 'B',
          injryRskGrdCdNm: '중',
          hspzRskGrdCdNm: 'C',
          dstrInjryGrdCdNm: 'B',
          addupStnd: '사망합산 200,000만원 / 입원합산 4만원 / 재상합산 10,000만원',
          insrJobExpln: '음식업소에서 고객에게 메뉴를 제시하고 음식을 주문받아 제공하는 자를 말한다.',
          isOpen: false
        },
        { insrJobCd: '000210',
          insrJobNm: '기업 대표이사 및 고위임원(자영업 제외, 작업 미참여)',
          rskGrdCdNm: 'A',
          injryRskGrdCdNm: '비',
          hspzRskGrdCdNm: 'A',
          dstrInjryGrdCdNm: 'A',
          addupStnd: '사망합산 200,000만원 / 입원합산 6만원 / 재상합산 20,000만원',
          insrJobExpln: '최고 경영진으로서 이사회나 이와 유사한 운영기관 또는 법령에 의해 위임된 권한 하에 사업체의 전반적인 정책을 수립하고 사업계획을 결정,기관이나 기업을 대표하며 기업 내 중간 관리자를 관리, 주로 사업본부 또는 부문 형태의 조직을 담당하는 자',
          isOpen: false
        },
        { insrJobCd: '052101',
          insrJobNm: '주방장 및 조리사(선박 조리사 제외)',
          rskGrdCdNm: 'B',
          injryRskGrdCdNm: '중',
          hspzRskGrdCdNm: 'C',
          dstrInjryGrdCdNm: 'B',
          addupStnd: '사망합산 200,000만원 / 입원합산 4만원 / 재상합산 10,000만원',
          insrJobExpln: '다양한 음식을 조리하거나 관련 감독 및 교육 훈련 하는 자를 말한다.',
          isOpen: false
        }
      ], // 리스트 데이터
      searchResultOrigin: [], // 리스트 원본 데이터
      searchResultFirstTab: [], // 직접검색 데이터
      searchResultSecondTab: [], // 분류별보기 데이터
      searchNmFirstTab: '', // 직접검색 검색어 백업
      radioSelected: null,
      selectedItem: null,
      isEmptySearchKeyword: 1,

      validateRule: [ // Rule 체크
        () => this.chkResult || this.chkErrorMsg
      ],
      chkResult: true,
      chkErrorMsg: ''
    }
  },

  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  /** computed 함수 정의 영역 */
  computed: {    
  },
  
  /** watch 정의 영역 */
  watch: {
  },

  /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
  created () {
    this.fn_SelListInsrJobCdClby('') // 대분류 목록 조회

    // 직업명이 들어온 경우 바로 해당 직업명으로 조회하도록 처리
    if (this.pJobCdNm !== undefined && this.pJobCdNm !== null && this.pJobCdNm !== '') {
      this.jobNm = this.pJobCdNm
      this.fn_SearchJobNm()
    } else {
      this.searchResultFirstTab = this.$bizUtil.cloneDeep(this.searchResult) // 화면에 기본으로 보여줄 보험직업 리스트를 백업함.
    }
  },
  /** 설명 : Step-4 마운트 된 후 호출 */
  mounted () {
    this.$bizUtil.insSrnLog('MSPCM013P')
  },
  /************************************************************************************************
  * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    /******************************************************************************
    * Function명 : fn_ChangeTab
    * 설명       : 탭 선택 시 호출
    ******************************************************************************/
    fn_ChangeTab (idx) {
      this.jobNm = ''
      if (idx === 0) { // '직접검색' 선택 시 '대분류, 중분류' 카테고리 invisible
        this.isCategoryVisible = false

        this.isEmptySearchKeyword = 1

        // 이전에 저장되어 있는 데이터 불러오기
        this.searchResult = this.$bizUtil.cloneDeep(this.searchResultFirstTab)
        this.searchResultOrigin = this.$bizUtil.cloneDeep(this.searchResult)
        this.jobNm = this.searchNmFirstTab

      } else { // '분류별 보기' 선택 시 '대분류, 중분류' 카테고리 visible
        this.isCategoryVisible = true

       this.isEmptySearchKeyword = 2

        // 이전에 저장되어 있는 데이터 불러오기
        this.searchResult = this.$bizUtil.cloneDeep(this.searchResultSecondTab)
        this.searchResultOrigin = this.$bizUtil.cloneDeep(this.searchResult)

      }
    },
    /******************************************************************************
    * Function명 : selListInsrJobCdClby
    * 설명       : 분류 목록 가져오기. 파라미터가 없을 경우 '대분류'전체, 파라미터가 있는 경우, 파라미터(대분류코드)에 해당하는 중분류 목록 조회
    ******************************************************************************/
    fn_SelListInsrJobCdClby (srchLclsfCd) {
      // this.isLoading = true
      let lv_Vm = this
      let pParams = {srchLclsfCd: srchLclsfCd}
      let trnstId = 'txTSSCM81S2'

      this.post(lv_Vm, pParams, trnstId, 'S')
        .then(function (response) {
          if (response.body !== null) {
            let lv_LclsfCD = response.body.iCInsrJobVO

            if (srchLclsfCd === '') { // 대분류 전체 목록 가져오기
              lv_Vm.jobComboLclsfList.push({value: '', text: '전체'})
              if (lv_LclsfCD.length > 0) { // 대분류 콤보 리스트 생성
                lv_LclsfCD.forEach(
                  (item) => {
                    if (Number(item.insrJobCd.substring(0, 2)) > 20) { // 대분류의 key가 20 이상인것만 조회함.
                      let lv_Item = {}
                      lv_Item.value = item.insrJobCd
                      lv_Item.text = item.insrJobNm
                      lv_Vm.jobComboLclsfList.push(lv_Item)
                    }
                  }
                )
              }

              // 중분류는 전체로 세팅
              lv_Vm.jobComboMclsfList = null
              lv_Vm.jobComboMclsfList = []
              lv_Vm.jobComboMclsfList.push({key: '', label: '전체'})
            } else { // 중분류 목록 가져오기
              lv_Vm.jobComboMclsfList = null
              lv_Vm.jobComboMclsfList = []
              lv_Vm.jobComboMclsfList.push({key: '', label: '전체'})
              if (lv_LclsfCD.length > 0) { // 중분류 콤보 리스트 생성
                lv_LclsfCD.forEach(
                  (item) => {
                    let lv_Item = {}
                    lv_Item.key = item.insrJobCd
                    lv_Item.label = item.insrJobNm
                    lv_Vm.jobComboMclsfList.push(lv_Item)
                  }
                )
              }
              lv_Vm.jobComboMclsf = lv_Vm.jobComboMclsfList[0]
            }
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },
    // 대분류 선택 시
    fn_SelectJobComboLclsf(value) {
      if (value === '') {
        this.jobComboMclsfList = null
        this.jobComboMclsfList = []
        this.searchResult = []
        this.searchResultOrigin = []
        this.searchResultSecondTab = []
        this.jobComboMclsf = ''
      } else {
        let lv_LclsfKey = value
        this.fn_SelListInsrJobCdClby(lv_LclsfKey)
      }
    },
    // 중분류 선택 시
    fn_SelectJobComboMclsf(value){
      if (value !== '') { // 전체가 아닌 경우, I/F 호출
        this.fn_SelListInsrJob('S')
      } else { // 중분류 전체 선택 시 초기화
        this.searchResult = []
        this.searchResultOrigin = []
        this.searchResultSecondTab = []
      }
    },
    /******************************************************************************
    * Function명 : selListInsrJobCdClby
    * 설명       : 분류 목록 가져오기. 파라미터가 없을 경우 '대분류'전체, 파라미터가 있는 경우, 파라미터(대분류코드)에 해당하는 중분류 목록 조회
    ******************************************************************************/
    fn_SelListInsrJobCdClby (srchLclsfCd) {
      // this.isLoading = true
      let lv_Vm = this
      let pParams = {srchLclsfCd: srchLclsfCd}
      let trnstId = 'txTSSCM81S2'

      this.post(lv_Vm, pParams, trnstId, 'S')
        .then(function (response) {
          if (response.body !== null) {
            let lv_LclsfCD = response.body.iCInsrJobVO

            if (srchLclsfCd === '') { // 대분류 전체 목록 가져오기
              lv_Vm.jobComboLclsfList.push({value: '', text: '전체'})
              if (lv_LclsfCD.length > 0) { // 대분류 콤보 리스트 생성
                lv_LclsfCD.forEach(
                  (item) => {
                    if (Number(item.insrJobCd.substring(0, 2)) > 20) { // 대분류의 key가 20 이상인것만 조회함.
                      let lv_Item = {}
                      lv_Item.value = item.insrJobCd
                      lv_Item.text = item.insrJobNm
                      lv_Vm.jobComboLclsfList.push(lv_Item)
                    }
                  }
                )
              }

              // 중분류는 전체로 세팅
              lv_Vm.jobComboMclsfList = null
              lv_Vm.jobComboMclsfList = []
              lv_Vm.jobComboMclsfList.push({value: '', text: '전체'})
            } else { // 중분류 목록 가져오기
              lv_Vm.jobComboMclsfList = null
              lv_Vm.jobComboMclsfList = []
              lv_Vm.jobComboMclsfList.push({value: '', text: '전체'})
              if (lv_LclsfCD.length > 0) { // 중분류 콤보 리스트 생성
                lv_LclsfCD.forEach(
                  (item) => {
                    let lv_Item = {}
                    lv_Item.value = item.insrJobCd
                    lv_Item.text = item.insrJobNm
                    lv_Vm.jobComboMclsfList.push(lv_Item)
                  }
                )
              }
              lv_Vm.jobComboMclsf = lv_Vm.jobComboMclsfList[0].value
            }
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },
    /******************************************************************************
    * Function명 : fn_SelListInsrJobCd
    * 설명       : 보험직업코드 목록별 or 보험직업명 or 보험 직업 코드에 따른 IF 호출
    ******************************************************************************/
    fn_SelListInsrJob (fnctScCd) {
      // this.isLoading = true
      let lv_Vm = this
      let pParams = {}
      let trnstId = 'txTSSCM81S1'

      if (fnctScCd === 'S') {
        pParams =
                {srchLclsfCd: lv_Vm.jobComboLclsf, // 대분류 코드
                  srchMclsfCd: lv_Vm.jobComboMclsf // 중분류 코드
                }
      } else if (fnctScCd === 'S1') {
        pParams = {srchNm: this.jobNm}
      }

      this.post(lv_Vm, pParams, trnstId, 'S')
        .then(function (response) {
          if (response.body !== null) {
            let lv_SearchResult = response.body.iCInsrJobVO
            lv_SearchResult.forEach(value => {
              value.isOpen = false
            })

            lv_Vm.searchResult = lv_Vm.$bizUtil.cloneDeep(lv_SearchResult)
            lv_Vm.searchResultOrigin = lv_Vm.$bizUtil.cloneDeep(lv_Vm.searchResult)

            if (lv_Vm.isCategoryVisible) { // '분류별 보기' 탭일 경우
              lv_Vm.searchResultSecondTab = lv_Vm.$bizUtil.cloneDeep(lv_Vm.searchResult) // 데이터 백업
            } else { // '직접검색' 탭일 경우
              lv_Vm.searchNmFirstTab = lv_Vm.jobNm // 검색어 백업
              lv_Vm.searchResultFirstTab = lv_Vm.$bizUtil.cloneDeep(lv_Vm.searchResult) // 데이터 백업
            }

            // lv_Vm.isLoading = false

            if (lv_Vm.searchResult.length === 0) {
              lv_Vm.isEmptySearchKeyword = 3
            } else {
              if (lv_Vm.isCategoryVisible) { // '분류별 보기' 탭일 경우
                lv_Vm.isEmptySearchKeyword = 2
              } else {
                lv_Vm.isEmptySearchKeyword = 1
              }
            }

          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },
    /******************************************************************************
    * Function명 : fn_SearchJobNm
    * 설명       : 검색명 입력시 호출, 분류별 보기일 경우, 화면내 filter, 직접 검색일 경우 IF호출
    ******************************************************************************/
    fn_SearchJobNm () {
      if (this.jobNm.trim().length < 2) {
        let lv_Msg = '2자 이상의 검색어를 입력하세요'

        this.fn_OpenAlert(lv_Msg)
        // this.getStore('confirm').dispatch('ADD', lv_Msg)
      } else {
        this.fn_SelListInsrJob('S1')
      }
    },
    fn_OpenAlert (alertMsg) {
      const lv_Vm = this

      let alertObj = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: true,
          content: alertMsg
        },
        listeners: {
          onPopupConfirm: () => {
            this.$bottomModal.close(alertObj)

            this.chkErrorMsg = '직업명을 입력해주세요.'
            this.chkResult = false
            this.$refs['refJobNm'].validate()
            
            this.$refs['refJobNm'].focus()
          },
        }
      })
    },
    /******************************************************************************
    * Function명 : fn_BtnK1OnClick
    * 설명       : 예 버튼 선택 시 호출 - Main 창 호출 , 직업코드, 직업명을 callback function에 같이 넘겨줌
    ******************************************************************************/
    fn_BtnK1OnClick () {
      this.$emit('confirmPopup', {jobCd: this.selectedItem.insrJobCd, jobCdNm: this.selectedItem.insrJobNm})
    },
    /******************************************************************************
    * Function명 : fn_BtnK2OnClick
    * 설명       : 아니오 버튼 선택 시 호출  - 팝업창 닫음
    ******************************************************************************/
    fn_BtnK2OnClick () {
      this.$emit('cancelPopup')
    },
    fn_CheckSelectedItem (param) {
      this.selectedItem = param
    }
  }
}
</script>
