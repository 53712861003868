/*
 * 업무구분: 사고보험금 신청
 * 화 면 명: MSPBU032D
 * 화면설명: 고객정보확인 및 수익계약정보
 * 접근권한: 모든사용자
 * 작 성 일: 2023.02.17
 * 작 성 자: 김진원
 */
<template>
    <!-- 컨텐츠 영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-pm-wrap"> 
      <!-- 계약자 정보 -->
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area msp-list-area pb0">  
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-check-list">
         <span ref="step3Top"></span>
         <mo-list :list-data="custList">
            <template #list-item="{item}">
              <mo-list-item>
                <div class="list-item__contents pt5 pb10">
                  <div class="list-item__contents__title"><!--fexTy3-->
                    <span class="name txtSkip maxW100 fexInt pr10">{{item.custNm}}</span>
                    
                    <!-- 본인, 수익자상이, 자녀 인 경우 -->
                    <mo-badge class="badge-sample-badge sm"
                              :class="item.custType==='피보험자'?'lightyellow2':'lightgreen'"
                              text="" shape="status">{{item.custType}}</mo-badge>

                    <!-- 본인 인 경우 피보험자 & 수익자 badge 를 둘다 표시 -->
                    <mo-badge class="badge-sample-badge sm lightgreen"
                              text="수익자" shape="status"
                              v-if="$props.customerType === 'SELF'">수익자</mo-badge>
                  </div>
                  <div class="list-item__contents__info">
                    <div class="txt_box">
                      <span class="crTy-bk1">{{item.age}}세</span><em>|</em><span class="crTy-bk1">{{item.rrn.replace(/(\d{6})(.*)/, '$1-$2')}}</span>
                    </div>
                  </div>
                </div>
              </mo-list-item>
            </template>
          </mo-list>
        </ur-box-container>
      </ur-box-container>
      <!-- 계약자 정보 //-->  

      <!-- 고객정보확인 -->   
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp-detail-lst type-2 pt20 pb80 bd-T-Ty2">  
        <strong class="fs22rem pb20">고객정보확인</strong>
        <!-- 피보험자 정보 -->
        <ul class="lst" v-if="$props.customerType === 'OTHER'">
          <li>
            <span class="tit wauto min60 mr10">피보험자</span>
            <span class="txt fexTy5">
              <span class="mr10">{{$props.step1Data.insured.custNm}}</span>
            </span>
          </li>
          <li>
            <span class="tit wauto min60 mr10">휴대폰</span>
            <span class="txt fexTy3">
              <span class="mr10">{{$props.step1Data.insured.celno}}</span>
              <mo-badge v-if="isNotEmpty($props.step1Data.insured.celno)" class="badge-sample-badge"
                        :class="$props.step1Data.insured.smsReciCnsntYn==='1'?'lightblue sm min40':'lightred sm min50'"
                        text="수신" shape="status">{{$props.step1Data.insured.smsReciCnsntYn==='1'?'수신':'미수신'}}</mo-badge>
            </span>
          </li>
          <li class="fexTy5">
            <span class="tit wauto min60 mr10">이메일</span>
            <span class="txt fexTy3">
              <span class="mr10">{{$props.step1Data.insured.emailAddr}}</span>
              <mo-badge v-if="isNotEmpty($props.step1Data.insured.emailAddr)" class="badge-sample-badge"
                        :class="$props.step1Data.insured.emailReciYn==='1'?'lightblue sm min40':'lightred sm min50'"
                        text="수신" shape="status">{{$props.step1Data.insured.emailReciYn==='1'?'수신':'미수신'}}</mo-badge>
            </span>
          </li>
          <li class="fex-clumn">
            <span class="tit mr0">우편물수령지</span>
            <ul class="lst-box">
              <li>
                <span>{{insrMailRcppliScNm}}</span> 
                <p>{{insrAddr}}</p>
              </li>
              <li>
                <span>전화번호</span> 
                <p>{{insrTelNo}}</p>
              </li>
            </ul>
          </li>
        </ul>
        <!-- 피보험자 정보 //-->
        <!-- 수익자 정보 본인|수익자상이|자녀 other 일 때만 피보자, 수익자 모두 보여주고, 아닐 때는 수익자(청구자, 계약자) 정보만 표시 -->
        <ul class="lst" :class="{mt50: $props.customerType === 'OTHER'}">
          <li>
            <span class="tit wauto min60 mr10">수익자</span>
            <span class="txt fexTy5">
              <span class="mr10">{{$props.step1Data.beneficiary.custNm}}</span>
            </span>
          </li>
          <li>
            <span class="tit wauto min60 mr10">휴대폰</span>
            <span class="txt fexTy3">
              <span class="mr10">{{$props.step1Data.beneficiary.celno}}</span>
              <mo-badge class="badge-sample-badge"
                        :class="$props.step1Data.beneficiary.smsReciCnsntYn==='1'?'lightblue sm min40':'lightred sm min50'"
                        text="수신" shape="status">{{$props.step1Data.beneficiary.smsReciCnsntYn==='1'?'수신':'미수신'}}</mo-badge>
            </span>
          </li>
          <li class="fexTy5">
            <span class="tit wauto min60 mr10">이메일</span>
            <span class="txt fexTy3">
              <span class="mr10">{{$props.step1Data.beneficiary.emailAddr}}</span>
              <mo-badge class="badge-sample-badge"
                        :class="$props.step1Data.beneficiary.emailReciYn==='1'?'lightblue sm min40':'lightred sm min50'"
                        text="수신" shape="status">{{$props.step1Data.beneficiary.emailReciYn==='1'?'수신':'미수신'}}</mo-badge>
            </span>
          </li>
          <li class="fex-clumn">
            <span class="tit mr0">우편물수령지</span>
            <ul class="lst-box">
              <li>
                <span>{{contMailRcppliScNm}}</span> 
                <p>{{contAddr}}</p>
              </li>
              <li>
                <span>전화번호</span> 
                <p>{{contTelNo}}</p>
              </li>
            </ul>
          </li>
          <li class="fex-clumn">
            <span class="tit mr0 mb10">수금계약조회</span>
            <div class="grid-area grid-ty4 grid-size-ty4">
              <!-- el-table 관련해서는 googling -->
              <el-table :data="bUClmnyContListSVO" empty-text="수금계약이 없습니다.">
                <el-table-column fixed label="계약번호" width="80">
                  <template #default="scope">
                    <div @click="fn_DetailBottomSheet(scope.row)">{{scope.row.contNo}}</div>
                  </template>
                </el-table-column>
                <el-table-column label="구분" width="100">
                  <template #default="scope">
                    <div @click="fn_DetailBottomSheet(scope.row)">{{scope.row.acdtrRltnNm}}</div>
                  </template>
                </el-table-column>
                <el-table-column label="상품명 가입특약" width="200">
                  <template #default="scope">
                    <div @click="fn_DetailBottomSheet(scope.row)">{{scope.row.prdtType}}</div>
                  </template>
                </el-table-column>
                <el-table-column label="계약일" width="100">
                  <template #default="scope">
                    <div @click="fn_DetailBottomSheet(scope.row)">{{scope.row.contYmd}}</div>
                  </template>
                </el-table-column>
                <el-table-column label="종납사항" width="100">
                  <template #default="scope">
                    <div @click="fn_DetailBottomSheet(scope.row)">{{scope.row.ltpadDtl}}</div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </li>
        </ul>
        <!-- 수익자 정보 //-->
      </ur-box-container>
      <!-- 고객정보확인 //-->

      <!-- 바텀시트1 : 수급계약상세 팝업 - 수급계약상세 리스트 클릭시 상세 팝업 표출 --> <!--2023.02.20 추가 //-->
      <mo-bottom-sheet ref="bottomSheet1"  class="ns-bottom-sheet msp-bottomSt-ty3 closebtn">
        <template v-slot:title class="pb0">
          {{detailInfo.prdtType}}
          <span class="dsD fs16rem fwn crTy-bk2 pt5">{{detailInfo.contNo}}</span>
        </template>
       <div class="ns-btn-close" @click="close"></div>  
        <div class="content-area">
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-list-area msp-list-area pt10 pb10">
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-contract-list type-2">
              <div class="list-item w100 pb0">
                <div class="list-item__contents">
                  <div class="list-item__contents__info">
                    <div class="txt_box">
                      <span class="fs14rem fwn pr10">계약일</span><span class="fwn">{{detailInfo.contYmd}}</span><em>|</em>
                      <span class="fs14rem fwn pr10">종납사항</span><span class="fwn">{{detailInfo.ltpadDtl}}</span>
                    </div>
                  </div>
                </div>  
              </div>
            </ur-box-container>
          </ur-box-container>
          <div class="table-area txt-center">
            <table class="table row-type">
              <colgroup>
               <col width="25%" />
               <col width="25%" />
               <col width="25%" />
               <col width="25%" />
              </colgroup>
              <tbody>
                <tr>
                  <th>중도</th>
                  <td>{{detailInfo.midadYn}}</td>
                  <th>채권</th>
                  <td>{{detailInfo.bndAttmContExnex}}</td>
                </tr>
                <tr>
                  <th>융자</th>
                  <td>{{detailInfo.fnLnRlateContCd}}</td>
                  <th>부활</th>
                  <td>{{detailInfo.rnstYn}}</td>
                </tr>
                <tr>
                  <th>변경</th>
                  <td>{{detailInfo.contChgYn}}</td>
                  <th>보류</th>
                  <td>{{detailInfo.pymRsvtYn}}</td>
                </tr>
                <tr>
                  <th>특정</th>
                  <td>{{detailInfo.myList1AplcClsfYn}}</td>
                  <th>삭감</th>
                  <td>{{detailInfo.aplcClsfCd03}}</td>
                </tr>
                <tr>
                  <th>금융보호</th>
                  <td>{{detailInfo.fncInfoPrtcYn}}</td>
                  <th>계약자</th>
                  <td>{{detailInfo.contrNm}}</td>
                </tr>
                <tr>
                  <th>상해</th>
                  <td>{{detailInfo.injryBnfrNm}}</td>
                  <th>사망수익자</th>
                  <td>{{detailInfo.dthBnfrNm}}</td>
                </tr>
                <tr>
                  <th>지정대리</th>
                  <td>{{detailInfo.agntNm}}</td>
                  <th>FATCA</th>
                  <td>{{detailInfo.fatcaClctnObjYnDtl}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs noshadow">
            <div class="relative-div">
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="close" name="확인">확인</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>
      <!-- 바텀시트1 : 수급계약상세 팝업 - 수급계약상세 리스트 클릭시 상세 팝업 표출  //-->    

    </ur-box-container>
    <!-- 컨텐츠영역  //-->
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import BUCommonUtil from '@/ui/bu/BUCommonUtil'

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPBU032D",
  screenId: "MSPBU032D",
  components: {
  },
  props: {
    customerType: {type: String},
    step1Data: {type: Object},
  },


  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    //출력 할 고객 순서 및 본인인증 대상 셋팅
    if( this.$props.customerType == 'SELF' ){
      this.custList.push(this.$props.step1Data.insured) //피보험자&계약자(본인)

    } else if( this.$props.customerType == 'OTHER' ){
      this.custList.push(this.$props.step1Data.insured) //피보험자
      this.custList.push(this.$props.step1Data.beneficiary) //수익자

    } else if( this.$props.customerType == 'CHILD' ){
      this.custList.push(this.$props.step1Data.beneficiary) //피보험자 (자녀)
      this.custList.push(this.$props.step1Data.insured) //계약자 (부모)

    }

    this.detailInfo = this.fn_GetDetailField() //수금계약상세 필드 셋팅
    this.fn_SetAddress() //우편물수령처 셋팅
    //this.fn_blocking() //블럭킹(진행가능 여부) step3 에서 4로 갈때 blocking 체크를 꼭 해야 하나?
    this.fn_SelClmnyContList() //수금계약리스트 조회
    
  },
  mounted() {
    this.$refs.step3Top.scrollIntoView()
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      custList: [], //고객정보

      insrAddr: '', //피보험자 우편물 수령처 주소
      insrTelNo: '', //피보험자 우편물 수령처 연락처
      insrMailRcppliScNm: '', //피보험자 우편물 수령처

      contAddr: '', //수익자 주소
      contTelNo: '', //수익자 전화번호
      contMailRcppliScNm: '', //수익자 수령처

      pageRowCnt: 200,
      stndKeyList: [{stndKeyId: 'next_key', stndKeyVal: null}],
      bUClmnyContListSVO: [], //수금계약리스트
      detailInfo: {}, //수금계약상세정보
      step3Data: {
        rldmCnt: '', //실손건수
        fcCnfYn: '', //Y:입원금지 N: 무관 (사고보험금 신청자 본인이 FC 이면, STEP4 의 청구세부내용 중 청구사유에 입원을 선택 하는 경우 제재됨.)
      },
    };
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {},
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /**
     * @description 수금계약 상세항목
     */
    fn_GetDetailField(){
      return {
        contNo: '', //계약번호
        acdtrRltnNm: '',//구분
        prdtType: '', //상품명가입특약
        contYmd: '', //계약일
        ltpadDtl: '', //종납사항
        midadYn: '',
        bndAttmContExnex: '',
        fnLnRlateContCd: '',
        rnstYn: '',
        contChgYn: '',
        pymRsvtYn: '',
        myList1AplcClsfYn: '',
        aplcClsfCd03: '',
        fncInfoPrtcYn: '',
        contrNm: '',
        injryBnfrNm: '',
        dthBnfrNm: '',
        agntNm: '',
        fatcaClctnObjYnDtl: '',
      }
    },

    /**
     * @description check empty
     */
    isNotEmpty(value){
      return !this.$bizUtil.isEmpty(value)
    },

    /**
     * @description 수금계약조회 상세 바텀시트
     * @param {Object} row 클릭 된 row 정보
     */
    fn_DetailBottomSheet(row){
      this.detailInfo.contNo = row.contNo
      this.detailInfo.acdtrRltnNm = row.acdtrRltnNm
      this.detailInfo.prdtType = row.prdtType
      this.detailInfo.contYmd = row.contYmd
      this.detailInfo.ltpadDtl = row.ltpadDtl
      this.detailInfo.midadYn = row.midadYn
      this.detailInfo.bndAttmContExnex = row.bndAttmContExnex
      this.detailInfo.fnLnRlateContCd = row.fnLnRlateContCd
      this.detailInfo.rnstYn = row.rnstYn
      this.detailInfo.contChgYn = row.contChgYn
      this.detailInfo.pymRsvtYn = row.pymRsvtYn
      this.detailInfo.myList1AplcClsfYn = row.myList1AplcClsfYn
      this.detailInfo.aplcClsfCd03 = row.aplcClsfCd03
      this.detailInfo.fncInfoPrtcYn = row.fncInfoPrtcYn
      this.detailInfo.contrNm = row.contrNm
      this.detailInfo.injryBnfrNm = row.injryBnfrNm
      this.detailInfo.dthBnfrNm = row.dthBnfrNm
      this.detailInfo.agntNm = row.agntNm
      this.detailInfo.fatcaClctnObjYnDtl = row.fatcaClctnObjYnDtl

      this.$refs.bottomSheet1.open();
    },

    /**
     * @description 수금계약조회 상세 바텀시트 닫기
     */
    close(){
      this.$refs.bottomSheet1.close();
    },

    

    /**
     * @description 수금계약 조회
     */
    fn_SelClmnyContList(){

      let params = {
        acdtrNm: this.$props.step1Data.insured.custNm,
        knbFrno: this.$props.step1Data.insured.knbFrno, // 주민번호 앞번호
        knbBkno: this.$props.step1Data.insured.knbBkno, // 주민번호 뒷번호 평문
        knbBknoCipher: '', //this.$props.step1Data.insured.knbBknoCipher,
        clamrId: this.$props.step1Data.beneficiary.custId, // 고객ID
        miniYn: 'Y',
      }

      if( this.$props.customerType === 'OTHER' ){
        params.inqrScCd = '05'
      } else {
        params.inqrScCd = '04'
      }

      let lv_Vm = this

      this.post(this, params, 'txTSSBU16S1', 'S')
        .then(function (response) {
          // 서버 데이터 가져오기
          if (response.body !== null) {
            
            lv_Vm.step3Data.rldmCnt = response.body.rldmCnt // 실손건수
            lv_Vm.step3Data.fcCnfYn = response.body.fcCnfYn // 고객이 FC이고 입원 청구 금지 Flag Y:입원금지 N: 무관

            // 사고접수사고자계약정보VO
            if( lv_Vm.isNotEmpty(response.body.bUClmnyContListSVO) ){

              lv_Vm.bUClmnyContListSVO = response.body.bUClmnyContListSVO

              for( let i=0; i<lv_Vm.bUClmnyContListSVO.length; i++ ){
                
                let item = lv_Vm.bUClmnyContListSVO[i]

                if( item.fncInfoPrtcYn !== 'Y' ){
                  item.contNo = item.contNo.substr(-4)
                  item.prdtType = item.prdtNm + '_' + item.prcd
                  item.contYmd = lv_Vm.$bizUtil.dateYyMmDdFormat(item.contYmd) // 계약일
                  item.ltpadDtl = lv_Vm.$bizUtil.dateYyMmFormat(item.ltpadYm) + '/' + item.ltpadNts
                  item.myList1AplcClsfYn = (item.aplcClsfCd01 === 'Y' || item.aplcClsfCd02 === 'Y') ? 'Y' : 'N' // 특정
                  item.fatcaClctnObjYnDtl = item.fatcaClctnObjYn === 'Y' ? '징구' : '해당무' // FATCA여부
                }
              }
            } else if( process.env.NODE_ENV === 'local' ){
              // lv_Vm.bUClmnyContListSVO = lv_Vm.testData()
            }

          } else {
            // 에러 메시지 발생
            lv_Vm.getStore('confirm').dispatch('ADD', '에러가 발생했습니다.')
          }

        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },



    /**
     * @description 우편물 수령처 셋팅
     */
    fn_SetAddress() {

      const insured = this.$props.step1Data.insured
      const beneficiary = this.$props.step1Data.beneficiary

      //피보험자(사고자) 정보는 수익자상이 일 때만 출력
      if( this.$props.customerType === 'OTHER' ){

        if( insured.mailRcppliScCd === '0002' ){ // 우편물수령지
          this.insrMailRcppliScNm = '자택주소'

          if( insured.homeAddrScCd === '2' ){ // 자택 신주소인 경우
            this.insrAddr = insured.homPstcd + ' ' + insured.homAddr + ' ' + insured.homebuilding + ' ' + insured.homDtlad + ' ' + insured.homestrSuppl1
          } else if( insured.homeAddrScCd === '1' ){ // 자택 구 주소인 경우
            this.insrAddr = insured.homPstcd + ' ' + insured.homAddr + ' ' + insured.homDtlad
          }
          this.insrTelNo = insured.homTelno

        } else if( insured.mailRcppliScCd === '0003' ){

          this.insrMailRcppliScNm = '직장주소'
          if( insured.jobAddrScCd === '2' ){ // 직장이 신주소인 경우
            this.insrAddr = insured.jobpPstcd + ' ' + insured.jobpAddr + ' ' + insured.jobpbuilding + ' ' + insured.jobpDtlad + ' ' + insured.jobpstrSuppl1
          } else if( insured.jobAddrScCd === '1' ){ // 직장 구 주소인 경우
            this.insrAddr = insured.jobpPstcd + ' ' + insured.jobpAddr + ' ' + insured.jobpDtlad
          }
          this.insrTelNo = insured.jobpTelno
        }

      }


      //계약자(수익자) 정보는 본인, 수익자상이, 자녀 인 모든 경우에 출력
      if( beneficiary.mailRcppliScCd === '0002' ){ // 우편물수령지
        this.contMailRcppliScNm = '자택주소'

        if (beneficiary.homeAddrScCd === '2') { // 자택 신주소인 경우
          this.contAddr = beneficiary.homPstcd + ' ' + beneficiary.homAddr + ' ' + beneficiary.homebuilding + ' ' + beneficiary.homDtlad + ' ' + beneficiary.homestrSuppl1
        } else if (beneficiary.homeAddrScCd === '1') { // 자택 구 주소인 경우
          this.contAddr = beneficiary.homPstcd + ' ' + beneficiary.homAddr + ' ' + beneficiary.homDtlad
        }
        this.contTelNo = beneficiary.homTelno

      } else if (beneficiary.mailRcppliScCd === '0003') {
        this.contMailRcppliScNm = '직장주소'

        if (beneficiary.jobAddrScCd === '2') { // 직장이 신주소인 경우
          this.contAddr = beneficiary.jobpPstcd + ' ' + beneficiary.jobpAddr + ' ' + beneficiary.jobpbuilding + ' ' + beneficiary.jobpDtlad + ' ' + beneficiary.jobpstrSuppl1
        } else if (beneficiary.jobAddrScCd === '1') { // 직장 구 주소인 경우
          this.contAddr = beneficiary.jobpPstcd + ' ' + beneficiary.jobpAddr + ' ' + beneficiary.jobpDtlad
        }
        this.contTelNo = beneficiary.jobpTelno

      }
    },

    testData(){
      return [{
          contNo: '*********1234', //계약번호
          acdtrRltnNm: 'A',//구분
          prdtType: '두손', //상품명가입특약
          contYmd: '2022-01-01', //계약일
          ltpadDtl: '무', //종납사항
          midadYn: '1',
          bndAttmContExnex: '2',
          fnLnRlateContCd: '3',
          rnstYn: '4',
          contChgYn: '5',
          pymRsvtYn: '6',
          myList1AplcClsfYn: '7',
          aplcClsfCd03: '8',
          fncInfoPrtcYn: '9',
          contrNm: '10',
          injryBnfrNm: '11',
          dthBnfrNm: '12',
          agntNm: '13',
          fatcaClctnObjYnDtl: '14',
        },
        {
          contNo: '*********1235', //계약번호
          acdtrRltnNm: 'B',//구분
          prdtType: '양손', //상품명가입특약
          contYmd: '2022-01-01', //계약일
          ltpadDtl: '무', //종납사항
          midadYn: 'A1',
          bndAttmContExnex: 'A2',
          fnLnRlateContCd: 'A3',
          rnstYn: 'A4',
          contChgYn: 'A5',
          pymRsvtYn: 'A6',
          myList1AplcClsfYn: 'A7',
          aplcClsfCd03: 'A8',
          fncInfoPrtcYn: 'A9',
          contrNm: 'A10',
          injryBnfrNm: 'A11',
          dthBnfrNm: 'A12',
          agntNm: 'A13',
          fatcaClctnObjYnDtl: 'A14',
        },
        {
          contNo: '*********1236', //계약번호
          acdtrRltnNm: 'C',//구분
          prdtType: '양발', //상품명가입특약
          contYmd: '2022-01-01', //계약일
          ltpadDtl: '무', //종납사항
          midadYn: 'B1',
          bndAttmContExnex: 'B2',
          fnLnRlateContCd: 'B3',
          rnstYn: 'B4',
          contChgYn: 'B5',
          pymRsvtYn: 'B6',
          myList1AplcClsfYn: 'B7',
          aplcClsfCd03: 'B8',
          fncInfoPrtcYn: 'B9',
          contrNm: 'B10',
          injryBnfrNm: 'B11',
          dthBnfrNm: 'B12',
          agntNm: 'B13',
          fatcaClctnObjYnDtl: 'B14',
        }]
    }
  }
};
</script>
<style scoped>
</style>