/**
 * <PRE>
 * System Name : 신태블릿 영업지원 시스템 (TSS)
 * Business Name : 사고보험금
 * Class Name : BUCommonUtil.js
 * Description :
 *              전자서명 앱 호출 전 Data 조회 및 구성
 * </PRE>
 * @version 1.0
 * @author Copyright (C) 2010 by SAMSUNG SDS co.,Ltd. All right reserved.
 */

import moment from 'moment'
const BUCommonUtil = {}

/******************************************************************************************************************************
 * Function명 : fn_stopListNoDeleteLocal
 * 설명 : 임시저장삭제목록 데이터를 신태블릿 앱 캐시 영역과 전자서명 앱에 저장되어있는 파일리스트와 비교후 삭제목록이 아닌 목록리턴
 ******************************************************************************************************************************/
BUCommonUtil.fn_stopListNoDeleteLocal = function (lv_vm, resultData, stopDeleteListVuchId, fnCallBack, type) {
  if (lv_vm.$commonUtil.isMobile()) {
    BUCommonUtil.setDynaTraceLog('BUCommonUtil.fn_stopListNoDeleteLocal', 'VuchId :' + stopDeleteListVuchId)
    window.fdpbridge.exec('delTmpStorePlugin', {list: stopDeleteListVuchId},
      (result) => {
        try {
          let pObj = {}
          pObj.acdtInsrAplEndYmd = moment(new Date().toISOString()).format('YYYYMMDD').toString()
          if (type !== 'FAST') {
            return callService('txTSSBU05D2', pObj, lv_vm, resultData, fnCallBack)
          } else {
            fnCallBack(lv_vm, resultData)
          }
        } catch (error) {
          BUCommonUtil.setDynaTraceLog('BUCommonUtil.fn_stopListNoDeleteLocal Error', 'delTmpStore error : ' + JSON.stringify(error))
          alert('delTmpStorePluginResult!! error' + error)
        }
      }, (result) => {
        BUCommonUtil.setDynaTraceLog('BUCommonUtil.fn_stopListNoDeleteLocal Error', 'delTmpStore Falut: ' + JSON.stringify(result))
        alert('delTmpStorePluginFalut!! error' + JSON.stringify(result))
      })
  } else {
    let pObj = {}
    pObj.acdtInsrAplEndYmd = moment(new Date().toISOString()).format('YYYYMMDD').toString()
    console.log('cash file delete = ' + stopDeleteListVuchId)
    if (type !== 'FAST') {
      return callService('txTSSBU05D2', pObj, lv_vm, resultData, fnCallBack)
    } else {
      fnCallBack(lv_vm, resultData)
    }
  }
}

BUCommonUtil.setDynaTraceLog = function ( event, param, screenId) {
  let vLog = ''
  let userInfo = window.vue.getStore('userInfo').getters.getUserInfo
  if ( userInfo ) {
    vLog = userInfo.userId + ',' + param
  } else {
    vLog = 'userId_n/a,' + param
  }

  if (screenId) {
    event = screenId + '.' + event
  }
  console.log('■■■setDynaTraceLog>>' + event + '  :' + vLog)
  try {
    if ( typeof dT_ !== 'undefined' && typeof dtrum !== 'undefined' ) {
      // eslint-disable-next-line
      var action = dtrum.enterAction(vLog)
      if ( event === 'BUCommonUtil.fn_stopListNoDeleteLocal Error') {
        // eslint-disable-next-line
        dtrum.reportError('Error:' + vLog, action) 
      }
      // eslint-disable-next-line
      dtrum.leaveAction(action)
    }
  } catch (e) {
    window.vue.error(e)
  }
}
/******************************************************************************
 * Function명 : fn_BlockingService
 * 설명       : 신청시 블록킹 조건 사전 조회
 ******************************************************************************/
BUCommonUtil.fn_BlockingService = function (lv_vm, pParam, resultData, fnCallBack) {
  callService('txTSSBU01S1', pParam, lv_vm, resultData, fnCallBack)
}
/************************************************************************************************
 * Function명  : excuteUploadImage
 * 설명        :  파일 전송 시작
************************************************************************************************/
BUCommonUtil.excuteUploadImage = function (lv_Vm, acdtAcpnYmd, custId, custNm, custRrn, num, image, insmJdgImgId, from, fnCallBack) {
  let pParams = {}
  pParams.imgJobScCd = 'GJA' // 이미지중분류코드(3자리) CLA , GJA

  pParams.acdtAcpnYmd = acdtAcpnYmd
  pParams.acdtrRrnVal = custRrn // 사고자 주민
  pParams.acdtrNm = custNm // 사고자 이름
  pParams.dCInsrFnDocuImgFileVO = []
  pParams.acdtrId = BUCommonUtil.custIdConvert(lv_Vm, custId, 9)
  pParams.from = from // 화면ID
  for (let i = 0; i < image.length; i++) {
    let dCInsrFnDocuImgFileVO = {}
    num++ // 파일명 증가
    let fileName = BUCommonUtil.createFileIDNameBU(num, custId)

    dCInsrFnDocuImgFileVO.imgFileNm = fileName + '.jpg'
    dCInsrFnDocuImgFileVO.apndFileCntnt = image[i].img.substr(23, image[i].img.length)
    dCInsrFnDocuImgFileVO.imgSclsfCd = 'GJA999'
    dCInsrFnDocuImgFileVO.imgFilePathNm = null
    pParams.dCInsrFnDocuImgFileVO.push(dCInsrFnDocuImgFileVO)
  }
  pParams.imgFileCnt = image.length
  pParams.insmJdgImgId = insmJdgImgId
  let resultData = {}
  callService('txTSSBU05M3', pParams, lv_Vm, resultData, fnCallBack)
}
/************************************************************************************************
 * Function명  : excuteUploadImageItem
 * 설명        :  파일 전송 시작
************************************************************************************************/
BUCommonUtil.excuteUploadImageItem = function (lv_Vm, acdtAcpnYmd, custId, custNm, custRrn, num, image, insmJdgImgId, from, fnCallBack, item_p) {
  let pParams = {}
  pParams.imgJobScCd = 'GJA' // 이미지중분류코드(3자리) CLA , GJA

  pParams.acdtAcpnYmd = acdtAcpnYmd
  pParams.acdtrRrnVal = custRrn // 사고자 주민
  pParams.acdtrNm = custNm // 사고자 이름
  pParams.dCInsrFnDocuImgFileVO = []
  pParams.acdtrId = BUCommonUtil.custIdConvert(lv_Vm, custId, 9)
  pParams.from = from // 화면ID

  let dCInsrFnDocuImgFileVO = {}
  // num++ // 파일명 증가
  let fileName = BUCommonUtil.createFileIDNameBU(num, custId)
  dCInsrFnDocuImgFileVO.imgFileNm = fileName + '.jpg'
  dCInsrFnDocuImgFileVO.apndFileCntnt = image[item_p].img.substr(23, image[item_p].img.length)
  dCInsrFnDocuImgFileVO.imgSclsfCd = 'GJA999'
  dCInsrFnDocuImgFileVO.imgFilePathNm = null
  pParams.dCInsrFnDocuImgFileVO.push(dCInsrFnDocuImgFileVO)

  // pParams.imgFileCnt = image.length
  pParams.imgFileCnt = dCInsrFnDocuImgFileVO.length
  pParams.insmJdgImgId = insmJdgImgId
  let resultData = {}
  callService('txTSSBU05M3', pParams, lv_Vm, resultData, fnCallBack)
}

/******************************************************************************************************************************
 * Function명 : custIdConvert
 * 설명 : 고객ID를 자릿수에 맞게 return
 ******************************************************************************************************************************/
BUCommonUtil.custIdConvert = function (lv_vm, custId, custLength) {
  let voLength

  custId = custId.trim()
  voLength = custId.length
  if ( custId === '' ) return custId
  if ( voLength !== custLength) {
    if ( voLength < custLength ) {
      custId = lv_vm.$bizUtil.lpad(custId, custLength, '0')
    } else {
      custId = custId.substring(voLength - custLength, custId.length)
    }
  }
  return custId
}
/******************************************************************************************************************************
 * Function명 : fn_selListDeltObj
 * 설명 : 접수중단건 삭제 대상 목록구하기
 ******************************************************************************************************************************/
BUCommonUtil.fn_selListDeltObj = function (lv_vm) {
  let pObj = {}
  pObj.acdtInsrAplEndYmd = moment(new Date().toISOString()).format('YYYYMMDD').toString()
  return callService('txTSSBU05M5', pObj, lv_vm, {}, BUCommonUtil.fn_stopListNoDeleteLocal)
}
/******************************************************************************
 * Function명 : createFileIDNameBU
 * 설명       : 사고보험 파일이름 리턴 (T+고객아이디+4자리번호)
******************************************************************************/
BUCommonUtil.createFileIDNameBU = function (num, custId) {
  let returnValue = ''
  let numStr = '' + num
  if (numStr.length === 1) {
    numStr = '000' + numStr
  } else if (numStr.length === 2) {
    numStr = '00' + numStr
  } else if (numStr.length === 3) {
    numStr = '0' + numStr
  }
  if (custId.length === 10) {
    custId = custId.substr(1, custId.length)
  }
  returnValue = custId + 'T' + numStr

  return returnValue
}
/******************************************************************************************************************************
 * Function명 : fn_selListDeltObj
 * 설명 : 접수중단건 삭제 대상를 삭제하기
 ******************************************************************************************************************************/
BUCommonUtil.fn_DeltObj = function (lv_vm) {
}

BUCommonUtil.fnCallBack = function () {
  window.vue.getStore('confirm').dispatch('ADD', '불필요한 캐쉬 화일을 삭제했습니다.')
}

async function callService (trnstId, pObj, lv_vm, resultData, fnCallBack) {
  if (pObj) {
    try {
      await window.vue.getInstance('http').post({}, pObj, trnstId)
        .then(function (response) {
          if (response && response.body) {
            let returnData = {}
            if ( trnstId === 'txTSSBU05D2') {
              // fnCallBack(lv_vm, resultData)
            } else if ( trnstId === 'txTSSBU05M5') {
              let stopDeleteListVuchId = ''
              /// 캐쉬화일명을 xxxxx.pdf.tmp,2222222.pdf.tmp 형태로 만듬
              for (let i = 0; i < response.body.bUAcdtClamdDVO.length; i++) {
                stopDeleteListVuchId += response.body.bUAcdtClamdDVO[i].apndDoctFileId + '.pdf.tmp'
                if (i < response.body.bUAcdtClamdDVO.length - 1) {
                  stopDeleteListVuchId += ','
                }
              }
              console.log('txTSSBU05M5 cash file delete = ' + stopDeleteListVuchId)
              if (stopDeleteListVuchId !== '') {
                fnCallBack(lv_vm, resultData, stopDeleteListVuchId, fnCallBack, 'LAST')
              }
            } else if ( trnstId === 'txTSSBU01S1') {
              let returnValue = ''
              if (response.body.message !== '*') {
                returnValue = response.body.message
              } else {
                switch (response.body.hpgAcpnPsbScCd) {
                  case '3':
                    if (resultData.CustomerType === 'CHILD') {
                      returnValue = '입력된 계약자(부모)와 피보험자(자녀)가 동일한 계약정보가 존재하지않습니다.'
                    } else if (resultData.CustomerType === 'SELF') {
                      returnValue = '청구된 피보험자의 계약정보 중\n수익자/피보험자가 동일한 건이\n존재하지 않습니다.'
                    } else {
                      returnValue = '청구된 피보험자의 계약정보 중\n피보험자/수익자 계약 건이\n존재하지 않습니다.'
                    }
                    break
                  case '4':
                    returnValue = '현재일자로 사고접수건이 존재합니다.'
                    break
                  case '5':
                    returnValue = '태블릿 가접수건이 존재합니다.'
                    break
                  case '6':
                    returnValue = '태블릿 신청을 할 수 없는 소속지점 입니다.'
                    break
                  case '7':
                    returnValue = '태블릿 가접수건 상태를 다시 확인해주세요.'
                    break
                  case '9':
                    returnValue = '현재 심사 진행중인 사고접수건이 존재합니다.'
                    break
                }
              }
              returnData.returnValue = returnValue
              returnData.stndSno = response.body.stndSno
              fnCallBack(returnData)
            } else if ( trnstId === 'txTSSBU05M3') {
              let upLoadMesg = ''
              switch (response.body.respCd) {
                case '0':
                  upLoadMesg = '성공\n'
                  break
                case '-1':
                  upLoadMesg = '이미지시스템 등록 실패' + '[' + response.body.respCd + ']\n'
                  break
                case '-2':
                  upLoadMesg = '이미지 ID 정보 조회 실패' + '[' + response.body.respCd + ']\n'
                  break
                case '-3':
                  upLoadMesg = '스캔일자 전달 오류. (이미지 등록은 성공)' + '[' + response.body.respCd + ']\n'
                  break
                case '-4':
                  upLoadMesg = '입력값 오류' + '[' + response.body.respCd + ']\n'
                  break
                case '-5':
                  upLoadMesg = '레파지토리 파일 전송 실패' + '[' + response.body.respCd + ']\n'
                  break
                default:
                  upLoadMesg = '알수 없는 에러 실패' + '[' + response.body.respCd + ']\n'
                  break
              }
              returnData.upLoadMesg = upLoadMesg
              fnCallBack(returnData)
            }
          } else {
            if (trnstId === 'txTSSBU01S1') {
              lv_vm.getStore('confirm').dispatch('ADD', '접수가능여부 조회 실패\n' + response.msgComm.msgDesc)
            } else if (trnstId === 'txTSSBU05M3') {
              lv_vm.getStore('confirm').dispatch('ADD', '이미지 등록 요청 실패\n' + response.msgComm.msgDesc)
            }
          }
        }).catch(function (error) {
          window.vue.getStore('progress').dispatch('SUB')
          window.vue.error(error)
        })
    } catch (error) {
      window.vue.getStore('progress').dispatch('SUB')
      window.vue.error(error)
    }
  }
}

export default BUCommonUtil
